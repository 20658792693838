<template>
  <div>
    <Row type="flex">
      <Col :span="18">
      <table class="params-table">
        <tr v-for="item in huanchongTableLight[pos]" :key="item.key">
          <td class="label">{{item.key}}：</td>
          <td>{{item.value}}</td>
        </tr>
      </table>
      </Col>
      <Col :span="6" class="text-right flex " style="justify-content: flex-end;">
      <e-image fit="contain" style="height: 80px;" :src="`static/huanchong/${pos}_${huanchongInfo[pos+'ProtectionMethod']}.png`"
               :preview-src-list="[`static/huanchong/${pos}_${huanchongInfo[pos+'ProtectionMethod']}.png`]" />
      </Col>
    </Row>
    <!-- <Table :data="huanchongTableLight[pos]" :columns="[
        { title: 'key', key: 'key', width: 200 },
        {
          title: 'value',
          key: 'value',
          render: (h, params) => {
            return tableFmt(h, params, 'value');
          }
        }
      ]" style="width: 100%" :show-header="false">
    </Table> -->
  </div>
</template>

<script>
import { PackageInfo } from '@/utils/mixins'
export default {
  mixins: [PackageInfo],
  props: {
    pos: {
      type: String,
      default: 'dingbu'
    }
  },
  data () {
    return {
      huanchongTableLight: {}
    }
  },
  computed: {
    huanchongInfo () {
      return this.packageInfo.packageinfo.auxiliary
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const pos = this.pos
      this.huanchongTableLight[pos] = [
        {
          key: '保护方式',
          value: this.getHCMethodLabel(
            this.huanchongInfo[pos + 'ProtectionMethod'],
            pos
          )
        },
        {
          key: '结构名称',
          value: this.getHCStructureLabel(
            this.huanchongInfo[pos + 'ProtectionMethod'],
            pos
          )
        },
        { key: '规格', value: this.huanchongInfo[pos + 'OuterSize'] },
        { key: '数量', value: this.huanchongInfo[pos + 'Amount'] },
        {
          key: '材料',
          value: this.getProtectionMaterial(
            this.huanchongInfo[pos + 'Material'])
        }
      ]
      if (pos !== 'jubu') {
        this.huanchongTableLight[pos].push({ key: '厚度', value: this.huanchongInfo[pos + 'Thickness'] })
      }
    },
    getHCMethodLabel (value, position) {
      if (value == null || !this.attributeMap) {
        return null
      }
      let label = null

      this.attributeMap[`${position}_protection_method`].forEach((v) => {
        if (v.attributeValue === value + '') {
          label = v.attributeLabel
        }
      })

      return label
    },
    getHCStructureLabel (value, position) {
      if (value == null || !this.attributeMap) {
        return null
      }
      let label = null

      this.attributeMap[`${position}_protection_structure`].forEach((v) => {
        if (v.attributeValue === value + '') {
          label = v.attributeLabel
        }
      })

      return label
    },
    getBagMaterialLabel (value) {
      return this.getLabelByValue(value, 'bag_material')
    },
    getBagTypeLabel (value) {
      return this.getLabelByValue(value, 'bag_type')
    },
    getProtectionMaterial (value) {
      return this.getLabelByValue(value, 'protection_material')
    },
    getLabelByValue (value, type) {
      let label = null

      this.attributeMap[type].forEach((v) => {
        if (v.attributeValue === value + '') {
          label = v.attributeLabel
        }
      })

      return label
    },
    tableFmt (h, params, key) {
      if (typeof params.row[key] === 'number') {
        return h('span', params.row[key])
      }
      return h('span', params.row[key] || '---')
    },
    updateData () {
      this.initData()
    }
  }

}
</script>

<style lang="scss" scoped></style>
