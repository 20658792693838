<template>
  <Modal v-model="visible" @on-cancel="cancel" width="600px" :mask-closable="false">
    <div slot="header" class="">查看更多箱型</div>
    <Form :model="form" ref="Form" inline label-position="left">
      <FormItem label="" prop="productName" style="margin-bottom: 20px;">
        <Input v-model="form.productName" placeholder="输入箱型进行搜索" style="width: 300px;">
        <Icon slot="suffix" type="ios-search" style="cursor: pointer;" @click="search" />
        </Input>
      </FormItem>
    </Form>
    <RadioGroup v-model="typeId" style="display:block;width:100%;">
      <Table ref="selection" :columns="columns" :data="tableData"></Table>
      <div style="position:absolute;top:-10000px;">
        <e-image ref="image" style="width: 100px; height: 100px;" :src="boxImageurl" :preview-src-list="[boxImageurl]">
        </e-image>
      </div>
    </RadioGroup>
    <div class="text-center" style="margin-top: 20px;">
      <Page :total="total" size="small" :page-size="10" :current.sync="form.pageNum" show-total />
    </div>
    <span slot="footer">
      <Button ghost type="primary" @click="cancel">取消</Button>
      <Button type="primary" style="margin-left:20px;" @click="ok">确定</Button>
    </span>
  </Modal>
</template>

<script>
import { getBoxTypeList } from '@/api/packing'
export default {
  components: {
  },
  data () {
    return {
      visible: false,
      form: {
        pageNum: 1
      },
      boxImageurl: '',
      typeId: '',
      columns: [
        {
          title: ' ',
          width: 80,
          align: 'center',
          render: (h, params) => {
            return <Radio label={params.row.id} ><span></span></Radio>
          }
        },
        {
          title: '代码',
          key: 'boxCode',
          width: 120
        },
        {
          title: '名称',
          key: 'boxName'
        },
        {
          title: '示意图',
          key: 'address',
          align: 'center',
          width: 80,
          render: (h, params) => {
            return <div style="position:relative;"><a href="javascript:;" on-click={() => this.preivewImg(params.row.boxImageurl)}>查看</a></div >
          }
        }
      ],
      total: 0,
      tableData: [
      ]
    }
  },
  computed: {

  },
  watch: {
    'form.pageNum': function () {
      this.fetchData()
    }
  },
  methods: {
    show () {
      if (this.tableData.length === 0) {
        this.fetchData()
      }
      this.visible = true
    },
    ok () {
      console.log(this.form)
      if (this.typeId === '') {
        this.$Message.error('请选择箱型')
        return
      }
      this.$emit('select', this.tableData.find(e => e.id === this.typeId))
      this.cancel()
    },
    search () {
      this.form.pageNum = 1
      this.fetchData()
    },
    cancel () {
      this.visible = false
      this.$emit('cancel')
    },
    fetchData () {
      getBoxTypeList({ pageSize: 10, pageNum: this.form.pageNum }).then(res => {
        if (res.isSuccess) {
          this.tableData = res.rows
          this.total = res.total
        }
      })
    },
    preivewImg (url) {
      this.boxImageurl = url
      this.$refs.image.clickHandler()
    }

  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-radio-wrapper {
    margin-right: 0 !important;
}
</style>
