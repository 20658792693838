<template>
  <div>
    <Row>
      <Col :span="18">
      <table class="params-table">
        <tr v-for="item in neidaiDataLight" :key="item.key">
          <td class="label">{{item.key}}：</td>
          <td>{{item.value}}</td>
        </tr>
      </table>
      </Col>
      <Col :span="6" class="text-right">
      <e-image fit="contain" style="width: 200px; height: 200px; padding-left: 10px" :src="getZhanbanImgLight()"
               :preview-src-list="[getZhanbanImgLight()]">
        <div class="el-image__error" slot="error">暂无图片{{getZhanbanImgLight()}}</div>
      </e-image>
      </Col>
    </Row>
    <!-- <Table :data="neidaiDataLight" :columns="[
        { title: 'key', key: 'key', width: 200 },
        {
          title: 'value',
          key: 'value',
          render: (h, params) => {
            return tableFmt(h, params, 'value');
          }
        }
      ]" style="width: 100%" :show-header="false">
    </Table> -->
  </div>
</template>

<script>
import { PackageInfo } from '@/utils/mixins'
export default {
  mixins: [PackageInfo],
  data () {
    return {
      neidaiDataLight: [
        {
          key: '品名',
          value: ''
        },
        {
          key: '规格',
          value: ''
        },
        {
          key: '材料',
          value: ''
        },
        {
          key: '数量',
          value: '1'
        }
      ]
    }
  },
  computed: {
    huanchongInfo () {
      return this.packageInfo.packageinfo.auxiliary || {}
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const neidaiInfo = this.huanchongInfo
      // 品名
      this.neidaiDataLight[0].value = this.getBagTypeLabel(neidaiInfo.bagType)
      // 规格
      this.neidaiDataLight[1].value = `${neidaiInfo.bagLong ||
        '---'} * ${neidaiInfo.bagWidth || '---'} * ${neidaiInfo.bagHeight ||
        '---'}`
      // 数量
      // 材料
      this.neidaiDataLight[2].value = neidaiInfo.bagMaterial
    },
    getZhanbanImgLight () {
      try {
        const neidaiInfo = this.huanchongInfo
        const bagType = this.getBagTypeLabel(neidaiInfo.bagType)
        let img = ''
        switch (bagType) {
          case '平口袋':
            img = 'static/bag/flat_pocket.png'
            break
          case '方口袋':
            img = 'static/bag/side_pocket.png'
            break
          case '自封袋':
            img = 'static/bag/valve_bag.png'
            break
          default:
            break
        }
        return img
      } catch (error) {
        return ''
      }
    },
    getBagMaterialLabel (value) {
      return this.getLabelByValue(value, 'bag_material')
    },
    getBagTypeLabel (value) {
      return this.getLabelByValue(value, 'bag_type')
    },
    getLabelByValue (value, type) {
      let label = null

      this.attributeMap[type].forEach((v) => {
        if (v.attributeValue === value + '') {
          label = v.attributeLabel
        }
      })

      return label
    },
    tableFmt (h, params, key) {
      if (typeof params.row[key] === 'number') {
        return h('span', params.row[key])
      }
      return h('span', params.row[key] || '---')
    },
    updateData () {
      this.initData()
    }
  }

}
</script>

<style lang="scss" scoped></style>
