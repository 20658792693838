<template>
  <div>
    <table class="scheme-table">
      <thead>
        <tr>
          <th></th>
          <th>序号</th>
          <th>尺寸</th>
          <th>纸箱箱型</th>
          <th>纸箱材料</th>
          <th>纸箱抗压<br>Kg</th>
          <th>包装总价<br>(元)</th>
          <th>产品包装单价<br>(元)</th>
          <th>安全性评价</th>
          <th>备注</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in recommendSolutionList" :key="item.id"
            :style="{background:productName === '推荐方案' + (index+1)?'#eaeaea':''}">
          <td v-if="index === 0" :rowspan="recommendSolutionList.length" style="background: rgba(236,151,103,0.50);">推荐方案</td>
          <td>{{index+1}}</td>
          <td>{{item.productSize}}</td>
          <td>{{item.boxName}}</td>
          <td>{{item.boxMaterial}}</td>
          <td>{{item.resistance}}</td>
          <td>{{item.totalPrice}}</td>
          <td>{{item.unitPrice}}</td>
          <td>安全</td>
          <td>{{item.remark || '-'}}</td>
          <td><a href="javascript:;" @click="refreshSolution(item.packageInfoId,'推荐方案' + (index+1))">查看详情</a></td>
        </tr>
        <tr v-for="(item, index) in customSolutionList" :key="item.id"
            :style="{background:productName === '自定义方案' + (index+1)?'#eaeaea':''}">
          <td v-if="index === 0" :rowspan="customSolutionList.length" style="background: rgba(103,197,236,0.50);">自定义方案</td>
          <td>{{index+1}}</td>
          <td>{{item.productSize}}</td>
          <td>{{item.boxName}}</td>
          <td>{{item.boxMaterial}}</td>
          <td>{{item.resistance}}</td>
          <td>{{item.totalPrice}}</td>
          <td>{{item.unitPrice}}</td>
          <td>{{item.safetyEvaluation}}</td>
          <td>{{item.remark || '-'}}</td>
          <td><a href="javascript:;" @click="refreshSolution(item.packageInfoId,'自定义方案' + (index+1))">查看详情</a></td>
        </tr>
      </tbody>
    </table>
    <Divider class="primary" style="margin: 20px auto;">以下信息为{{productName}}详情</Divider>
  </div>
</template>

<script>
import { getUserPackageHistoryRecordsAndPage } from '@/api/packing'
export default {
  inject: {
    packageInfo: {
      default: () => ({})
    }
  },
  data () {
    return {
      productName: '推荐方案1'
    }
  },
  computed: {
    solutionList () {
      return this.$get(this.packageInfo, 'packageinfo.customSolutionList', []) || []
    },
    packageType () {
      return this.$get(this.packageInfo, 'packageinfo.packageType', '') || 2
    },
    recommendSolutionList () {
      return this.solutionList.filter(e => e.solutionType === 1)
    },
    customSolutionList () {
      return this.solutionList.filter(e => e.solutionType === 2)
    }
  },
  methods: {

    refreshSolution (packageInfoId, productName) {
      getUserPackageHistoryRecordsAndPage({ packageInfoId, pageIndex: 1, pageSize: 10, packageType: this.packageType, status: this.$route.query.status }).then(res => {
        if (res.isSuccess) {
          // this.listData = res.data[0]
          if (res.data.length) {
            this.$store.commit('SetPackageInfo', res.data[0])
            this.productName = productName
            // this.$router.push({
            //   name: 'PackageInfo',
            //   query: { id: res.data[0].packageinfoId }
            // })
          }
        } else {
          this.$Message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ivu-divider.primary {
    color: #67c5ec;
    font-size: 14px;
    &:after,
    &:before {
        border-top: 1px solid #67c5ec;
    }
}
</style>
