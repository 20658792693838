<template>
  <Form :model="form" ref="Form" :rules="rules" label-position="top">
    <Row :gutter="70">
      <Col :span="8">
      <FormItem label="产品名称" prop="productName">
        <Input ref="productName" v-model="form.productName" placeholder="输入产品名称" maxlength="100"></Input>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="产品所属分类" prop="productType">
        <select-dict v-model="form.productType" :default-value="form.productType" type="product_type_light"
                     placeholder="产品所属分类">
        </select-dict>
      </FormItem>
      </Col>
      <!-- <Col :span="8">
      <FormItem label="产品类型" prop="isliquid">
        <select-dict v-model="form.isliquid" :default-value="form.isliquid" type="isliquid" placeholder="请选择产品类型">
        </select-dict>
      </FormItem>
      </Col> -->
      <Col :span="8">
      <FormItem label="产品年产量 (个/年) " prop="orderAmout">
        <Input type="number" v-model="form.orderAmout" placeholder="请输入产品年产量"></Input>
        <span class="input-help">请填写真实数据</span>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="堆码时产品是否能受压" prop="addAuxiliaryWeight" ref="addAuxiliaryWeight">
        <Select v-model="form.addAuxiliaryWeight" placeholder="请选择" clearable>
          <Option :value="1">是</Option>
          <Option :value="0">否</Option>
        </Select>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="产品货值 (元/个)" prop="productValue" ref="productValue">
        <Input v-model="form.productValue" placeholder="请输入货值" ref="productValue"></Input>
        <span class="input-help">请正确填写产品货值，可能影响材料选择</span>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="产品尺寸（mm）" prop="sizeC" ref="sizeC">
        <div class="flex">
          <int-input v-model.number="form.sizeC" :default-value="form.sizeC" placeholder="长度">
          </int-input>
          <int-input v-model.number="form.sizeK" :default-value="form.sizeK" placeholder="宽度" style="margin-left:12px">
          </int-input>
          <int-input v-model.number="form.sizeG" :default-value="form.sizeG" placeholder="高度" style="margin-left:12px">
          </int-input>
        </div>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="单个产品质量（千克）" prop="productWeight">
        <Input type="number" v-model.number="form.productWeight" placeholder="请输入产品重量" @input="computeWeightLight"
               ref="productWeight"></Input>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="每箱包装产品数量（个/箱）" prop="packingAmout">
        <Input type="number" v-model.number="form.packingAmout" placeholder="每箱包装产品数量（个/箱）" @input="computeWeightLight"
               ref="packingAmout"></Input>
      </FormItem>
      </Col>
      <Col :span="8">
      <div class="product-weight">
        <div style="font-size:24px;line-height:40px;color:#333333;">单箱总重量: <span style="float:right">
            {{get(OrdinaryPackage.productInfo,'weight',0)}} 千克</span>
        </div>
        <p style="color:#999999;">系统自动计算单箱包装产品的总重</p>
      </div>
      </Col>
    </Row>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import { Package } from '@/utils/mixins'
export default {
  props: {
    count: {
      type: Number,
      default: 1
    }
  },
  mixins: [Package],
  data () {
    const validateProductSize = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入产品尺寸'))
      } else {
        const { sizeC, sizeK, sizeG } = this.form
        if (isNaN(sizeC) || isNaN(sizeK) || isNaN(sizeG)) {
          callback(new Error('请输入产品尺寸'))
        }
        callback()
      }
    }
    return {
      checkFieldNum: 1,
      requiredFields: ['productName', 'productWeight', 'productValue'],
      form: {
      },
      rules: {
        productName: [{ required: true, message: '输入产品名称' }],
        // productType: [{ required: true, message: '请选择产品所属分类' }],
        productWeight: [{ required: true, message: '请输入产品重量' }],
        productValue: [{ required: true, message: '请输入货值' }],
        // orderAmout: [{ required: true, message: '请输入产品年产量' }],
        addAuxiliaryWeight: [{ required: true, message: '请选择产品是否受压' }],
        sizeC: [{ required: true, validator: validateProductSize, trigger: 'blur' }],
        packingAmout: [{ required: true, message: '请输入产品装箱个数' }]
        // weightspreading: [{ required: true, message: '请选择承重分布方式' }],
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.$store.commit('SetOrdinaryPackage', { ...this.OrdinaryPackage, productInfo: this.form })
        this.computedCount()
      }
    }
  },
  computed: {
    ...mapState({
      OrdinaryPackage: state => state.OrdinaryPackage
    })
  },
  mounted () {
    if (this.OrdinaryPackage && this.OrdinaryPackage.productInfo) {
      this.form = Object.assign({}, this.form, this.OrdinaryPackage.productInfo)
      this.computeWeightLight()
      this.computedCount()
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.Form.validate(valid => {
          if (valid) {
            resolve(valid)
          }
          reject(valid)
        })
      })
    },
    // computeSumLight () {
    //   const {
    //     productNumLong,
    //     productNumWidth,
    //     productNumHeight
    //   } = this.form
    //   if (productNumLong && productNumWidth && productNumHeight) {
    //     this.form.packingAmout =
    //       productNumLong * productNumWidth * productNumHeight
    //     this.computeWeightLight()
    //   }
    // },
    computeWeightLight () {
      const { productWeight, packingAmout } = this.form
      if (!isNaN(productWeight) && !isNaN(packingAmout)) {
        this.form.weight = (productWeight * packingAmout).toFixed(2) * 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-form-item {
    margin-bottom: 40px;
}

.product-weight {
    background: rgba(103, 197, 236, 0.15);
    border-radius: 0px 0px 5px 5px;
    text-align: left;
    padding: 10px 20px;
}
</style>
