<template>
  <Form :model="form" ref="Form" :rules="rules" label-position="top">
    <Row :gutter="70">
      <Col :span="8">
      <FormItem label="产品名称" prop="productName" ref="productName">
        <Input ref="productName" v-model="form.productName" placeholder="输入产品名称" maxlength="100"></Input>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="产品所属分类" prop="productType" ref="productType">
        <select-dict v-model="form.productType" :default-value="form.productType" type="product_type" placeholder="产品所属分类"
                     ref="productType"></select-dict>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="产品类型" prop="isliquid">
        <!-- <Select v-model="form.isliquid" placeholder="请选择产品性状类型">
          <Option value="1">颗粒状固体</Option>
          <Option value="2">块状固体</Option>
          <Option value="3">液体</Option>
          <Option value="4">其他</Option>
        </Select> -->
        <select-dict v-model="form.isliquid" :default-value="form.isliquid" type="isliquid" placeholder="请选择产品类型"></select-dict>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="产品年产量 (个/年) " prop="orderAmout" ref="orderAmout">
        <Input type="number" v-model="form.orderAmout" placeholder="请输入产品年产量" :maxlength="9"></Input>
        <span class="input-help">请填写真实数据</span>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="堆码时产品是否能受压" prop="addAuxiliaryWeight" ref="addAuxiliaryWeight">
        <Select v-model="form.addAuxiliaryWeight" placeholder="请选择" clearable>
          <Option :value="1">是</Option>
          <Option :value="0">否</Option>
        </Select>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="产品货值 (元/个)" prop="productValue" ref="productValue">
        <Input v-model.lazy="form.productValue" placeholder="请输入货值" ref="productValue"></Input>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="产品尺寸（mm）" prop="sizeC" ref="sizeC">
        <div class="flex">
          <int-input v-model.number="form.sizeC" :default-value="form.sizeC" placeholder="长度">
          </int-input>
          <int-input v-model.number="form.sizeK" :default-value="form.sizeK" placeholder="宽度" style="margin-left:12px">
          </int-input>
          <int-input v-model.number="form.sizeG" :default-value="form.sizeG" placeholder="高度" style="margin-left:12px">
          </int-input>
        </div>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="单个产品质量（千克）" prop="productWeight" ref="productWeight">
        <Input type="number" v-model.number="form.productWeight" placeholder="请输入产品重量" @input="computeWeightLight"></Input>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="每箱包装产品数量（个/箱）">
        <Input v-model.number="form.packingAmout" placeholder="请输入产品平均装箱数量" @input="computeWeightLight">
        </Input>
      </FormItem>
      </Col>
      <Col :span="8">
      <div class="product-weight">
        <div style="font-size:24px;line-height:40px;color:#333333;">单箱总重量: <span style="float:right">
            {{get(HeavyPackage.productInfo,'weight',0)}} 千克</span>
        </div>
        <p style="color:#999999;">系统自动计算单箱包装产品的总重</p>
      </div>
      </Col>
      <Col :span="8">
      <FormItem label="产品着地面是否平面" prop="isFlat" ref="isFlat">
        <Select v-model="form.isFlat" placeholder="请选择" clearable>
          <Option :value="1">是</Option>
          <Option :value="0">否</Option>
        </Select>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="产品重心是否位于包装中心" prop="isCenter" ref="isCenter">
        <Select v-model="form.isCenter" placeholder="请选择" clearable>
          <Option :value="1">是</Option>
          <Option :value="0">否</Option>
        </Select>
      </FormItem>
      </Col>
      <!-- <Col :span="8">
      <FormItem label="产品摆放方式" prop="productNumLong" ref="productNumLong">
        <div class="flex">
          <int-input v-model.number="form.productNumLong" :default-value="form.productNumLong" @input="computeSumLight"
                     placeholder="长方向数量">
          </int-input>
          <int-input v-model.number="form.productNumWidth" :default-value="form.productNumWidth" @input="computeSumLight"
                     placeholder="宽方向数量" style="margin-left:12px">
          </int-input>
          <int-input v-model.number="form.productNumHeight" :default-value="form.productNumHeight" @input="computeSumLight"
                     placeholder="高方向数量" style="margin-left:12px">
          </int-input>
        </div>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="产品摆放间隙 (单位/mm)" prop="gap" ref="gap">
        <div class="flex is-success">
          <int-input v-model.number="form.gap" :default-value="form.gap" placeholder="长方向"></int-input>
          <int-input v-model.number="form.gapWidth" :default-value="form.gapWidth" placeholder="宽方向" style="margin-left:12px">
          </int-input>
          <int-input v-model.number="form.gapHeight" :default-value="form.gapHeight" placeholder="高方向" style="margin-left:12px">
          </int-input>
        </div>
      </FormItem>
      </Col> -->
      <!--
      <Col :span="8">
      <FormItem label="承重分布" prop="weightspreading">
        <select-dict v-model="form.weightspreading" :default-value="form.weightspreading" type="weightspreading"
                     placeholder="请选择"></select-dict>
        <Select v-model="form.weightspreading" placeholder="请选择" clearable ref="weightspreading"
                @on-change="event => onBlur(event, 'isliquid')">
          <Option value="2">平均分布</Option>
          <Option value="3">Lon集中分布don</Option>
          <Option value="4">不平均分布</Option>
        </Select>
      </FormItem>
      </Col> -->

    </Row>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import { Package } from '@/utils/mixins'
export default {
  props: {
    count: {
      type: Number,
      default: 1
    }
  },
  mixins: [Package],
  data () {
    const validateProductNumLong = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入产品摆放方式'))
      } else {
        const { productNumLong, productNumWidth, productNumHeight } = this.form
        if (isNaN(productNumLong) || isNaN(productNumWidth) || isNaN(productNumHeight)) {
          callback(new Error('请输入产品摆放方式'))
        }
        callback()
      }
    }
    const validateProductSize = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入产品尺寸'))
      } else {
        const { sizeC, sizeK, sizeG } = this.form
        if (isNaN(sizeC) || isNaN(sizeK) || isNaN(sizeG)) {
          callback(new Error('请输入产品尺寸'))
        }
        callback()
      }
    }
    return {
      checkFieldNum: 1,
      requiredFields: ['productName', 'productType', 'productWeight', 'productValue', 'productNumLong', 'gap', 'weightspreading', 'isliquid'],
      form: {
      },
      rules: {
        productName: [{ required: true, message: '输入产品名称' }],
        productType: [{ required: true, message: '请选择产品所属分类' }],
        orderAmout: [{ required: true, message: '请输入产品年产量' }],
        productWeight: [{ required: true, message: '请输入产品重量' }],
        productValue: [{ required: true, message: '请输入货值' }],
        addAuxiliaryWeight: [{ required: true, message: '请选择产品是否承重' }],
        sizeC: [{ required: true, validator: validateProductSize, trigger: 'blur' }],
        productNumLong: [{ required: true, validator: validateProductNumLong, trigger: 'blur' }],
        isFlat: [{ required: true, message: '请选择是否平面' }],
        isCenter: [{ required: true, message: '请选择是否位于包装中心' }]
        // weightspreading: [{ required: true, message: '请选择承重分布方式' }],
        // quntity: [{ required: true, message: '请输入产品装箱个数' }]
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.$store.commit('SetHeavyPackage', { ...this.HeavyPackage, productInfo: this.form })
        this.computedCount()
      }
    }
  },
  computed: {
    ...mapState({
      HeavyPackage: state => state.HeavyPackage
    })
  },
  mounted () {
    if (this.HeavyPackage && this.HeavyPackage.productInfo) {
      this.form = Object.assign({}, this.form, this.HeavyPackage.productInfo)
      this.computeWeightLight()
      this.$nextTick(() => {
        this.computedCount()
      })
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.Form.validate(valid => {
          if (valid) {
            resolve(valid)
          }
          reject(valid)
        })
      })
    },
    // computeSumLight () {
    //   const {
    //     productNumLong,
    //     productNumWidth,
    //     productNumHeight
    //   } = this.form
    //   if (productNumLong && productNumWidth && productNumHeight) {
    //     this.form.packingAmout =
    //       productNumLong * productNumWidth * productNumHeight
    //     this.computeWeightLight()
    //   }
    // },
    computeWeightLight () {
      const { productWeight, packingAmout } = this.form
      if (!isNaN(productWeight) && !isNaN(packingAmout)) {
        this.form.weight = (productWeight * packingAmout).toFixed(2) * 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-form-item {
    margin-bottom: 40px;
}
.product-weight {
    background: rgba(103, 197, 236, 0.15);
    border-radius: 0px 0px 5px 5px;
    text-align: left;
    padding: 5px 10px;
}
</style>
