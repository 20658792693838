<template>
  <Form :model="form" ref="Form" :rules="rules" label-position="top">
    <FormItem label="产品装箱方式" prop="isRegularPlace" ref="isRegularPlace">
      <template #label>
        产品装箱方式
      </template>
      <RadioGroup v-model="form.isRegularPlace">
        <Radio :label="1">
          产品规则摆放
          <Tooltip placement="top">
            <div href="" class="ico-tips">
              <Icon type="ios-alert" style="color:#67c5ec" />
            </div>
            <div slot="content">
              <p>单个产品装箱或多个<br />产品按照长宽高有序摆放</p>
            </div>
          </Tooltip>
        </Radio>
        <Radio :label="2">
          产品不规则摆放
          <Tooltip placement="top">
            <div href="" class="ico-tips">
              <Icon type="ios-alert" style="color:#67c5ec" />
            </div>
            <div slot="content">
              <p>多个产品装箱时无<br />序摆放或参差摆放</p>
            </div>
          </Tooltip>
        </Radio>
      </RadioGroup>
    </FormItem>
    <Row :gutter="40">
      <Col :span="8" v-if="form.isRegularPlace === 1">
      <FormItem label="产品摆放方式" prop="productNumLong" ref="productNumLong" :rules="moreRules.rulesProductNumLong">
        <div class="flex">
          <int-input v-model.number="form.productNumLong" :default-value="form.productNumLong" @input="computeSumLight"
                     placeholder="长方向数量">
          </int-input>
          <int-input v-model.number="form.productNumWidth" :default-value="form.productNumWidth" @input="computeSumLight"
                     placeholder="宽方向数量" style="margin-left:12px">
          </int-input>
          <int-input v-model.number="form.productNumHeight" :default-value="form.productNumHeight" @input="computeSumLight"
                     placeholder="高方向数量" style="margin-left:12px">
          </int-input>
        </div>
        <p class="input-help">说明：长*宽*高=每箱包装产品数量</p>
      </FormItem>
      </Col>
      <Col :span="8" v-if="form.isRegularPlace === 1">
      <FormItem label="产品摆放间隙（mm）" prop="gap" ref="gap" :rules="moreRules.rulesProductGap">
        <div class="flex is-success">
          <int-input v-model.number="form.gap" :default-value="form.gap" placeholder="长度" @input="computeSumLight"></int-input>
          <int-input v-model.number="form.gapWidth" :default-value="form.gapWidth" placeholder="宽度" @input="computeSumLight"
                     style="margin-left:12px">
          </int-input>
          <int-input v-model.number="form.gapHeight" :default-value="form.gapHeight" placeholder="高度" @input="computeSumLight"
                     style="margin-left:12px">
          </int-input>
        </div>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem v-if="form.isRegularPlace === 1 || form.isRegularPlace === 2" label="包装箱内尺寸（mm）" prop="Li"
                :ref="form.isRegularPlace === 2 ? 'Li' : 'FormLi'" :required="form.isRegularPlace === 2" :rules="moreRules.Li">
        <div class="flex">
          <int-input v-model.number="form.innerLong" :default-value="form.innerLong" placeholder="长度"
                     :disabled="form.isRegularPlace === 1">
          </int-input>
          <int-input v-model.number="form.innerWidth" :default-value="form.innerWidth" placeholder="宽度"
                     :disabled="form.isRegularPlace === 1" style="margin-left:12px">
          </int-input>
          <int-input v-model.number="form.innerHeight" :default-value="form.innerHeight" placeholder="高度"
                     :disabled="form.isRegularPlace === 1" style="margin-left:12px">
          </int-input>
        </div>
      </FormItem>
      </Col>
    </Row>
    <div class="panel-danger">
      <Collapse value="1">
        <Panel name="1">
          推荐方案1
          <p slot="content">
            <Row :gutter="40">
              <Col :span="12">
              <FormItem label="推荐箱型" prop="cartonType" ref="cartonType">
                <Select v-model="form.cartonType" placeholder="请选择箱型">
                  <Option v-for="item in boxTypeList" :key="item.id" :value="item.id">{{ item.boxName }}</Option>
                </Select>
                <!-- <p class="input-help"><a href="javascript:;" @click.prevent="$refs.FormCartonTypeModal.show()">查看更多箱型</a></p> -->
              </FormItem>
              </Col>
              <Col :span="12">
              <FormItem label="纸箱楞型" prop="lengxingId" ref="lengxingId">
                <!-- <template #label>
                  纸箱楞型
                  <Tooltip placement="top">
                    <div href="" class="ico-tips">
                      <Icon type="ios-alert" style="color:#67c5ec" />
                    </div>
                    <div slot="content">
                      <img src="@/assets/images/lengxing.png" width="600" alt="">
                    </div>
                  </Tooltip>
                </template> -->
                <select-dict v-model="form.lengxingId" :default-value="form.lengxingId" placeholder="选择纸箱楞型" type="lengxing">
                </select-dict>
              </FormItem>
              </Col>
            </Row>
            <Row :gutter="40">
              <Col :span="8">
              <FormItem label="与箱型匹配的托盘信息" prop="needPallet" ref="needPallet">
                <RadioGroup v-model="form.needPallet">
                  <Radio :label="1">需要</Radio>
                  <Radio :label="0">不需要</Radio>
                </RadioGroup>
                <template #label>
                  与箱型匹配的托盘信息
                  <Tooltip placement="top">
                    <div href="" class="ico-tips">
                      <Icon type="ios-alert" style="color:#67c5ec" />
                    </div>
                    <div slot="content">
                      <p>用于转移货物的可移动的承载物。</p>
                    </div>
                  </Tooltip>
                </template>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem v-if="form.needPallet === 1" label="托盘材料" ref="palletMaterial" prop="palletMaterial"
                        :rules="[{ required: true, message: '选择托盘材料' }]">
                <Select v-model="form.palletMaterial" placeholder="请选择托盘材料" :default-value="form.palletMaterial">
                  <Option value="1">桃花芯胶合板（国内外均适合使用）</Option>
                  <Option value="2">原木（仅适合国内使用，不支持出口）</Option>
                </Select>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem v-if="form.needPallet === 1 && form.palletMaterial" label="进叉方向" :ref="
                    form.palletMaterial !== '1'
                      ? 'forkDirection'
                      : 'FormforkDirection'
                  " prop="forkDirection" :rules="[
                    {
                      required: form.palletMaterial !== '1',
                      message: '请选择进叉方向',
                      trigger: 'change'
                    }
                  ]">
                <Select v-model="form.forkDirection" placeholder="请选择" :disabled="form.palletMaterial === '1'">
                  <Option value="2">双向进叉</Option>
                  <Option value="1">四向进叉</Option>
                </Select>
              </FormItem>
              </Col>
            </Row>
          </p>
        </Panel>
      </Collapse>
    </div>
    <div class="panel-primary" style="margin-top:20px;" v-for="(scheme, index) in form.customScheme" :key="index">
      <Collapse :value="'index'">
        <Panel :name="'index'">
          <span>
            自定义方案{{ index + 1 }}
            <span style="float:right;margin-right:20px;">
              <Button shape="circle" size="small" style="width:100px;" @click="removeScheme(index)"><span
                      style="font-size:12px!important;color:#67C5EC">删除方案</span></Button>
            </span>
          </span>
          <p slot="content">
            <Row :gutter="40">
              <Col :span="8">
              <FormItem label="自定义箱型" :prop="'customScheme.' + index + '.cartonType'"
                        :ref="'customScheme.' + index + '.cartonType'" :rules="[{ required: true, message: '请选择箱型' }]">
                <template #label>
                  自定义箱型<span class="input-help" style="position: absolute;right:20px;top:0px"><a href="javascript:;"
                       @click.prevent="$refs.FormCartonTypeModal.show()" style="text-decoration: underline;">查看更多箱型</a></span>
                </template>
                <Select v-model="scheme.cartonType" placeholder="请选择箱型">
                  <Option v-for="item in boxTypeList" :key="item.id" :value="item.id">{{ item.boxName }}</Option>
                </Select>
                <!-- <p class="input-help"><a href="javascript:;" @click.prevent="$refs.FormCartonTypeModal.show()">查看更多箱型</a></p> -->
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem label="纸箱楞型" :ref="'customScheme.' + index + '.lengxingId'"
                        :prop="'customScheme.' + index + '.lengxingId'" :rules="[{ required: true, message: '请选择纸箱楞型' }]">
                <!-- <template #label>
                  纸箱楞型
                  <Tooltip placement="top">
                    <div href="" class="ico-tips">
                      <Icon type="ios-alert" style="color:#67c5ec" />
                    </div>
                    <div slot="content">
                      <img src="@/assets/images/lengxing.png" width="600" alt="">
                    </div>
                  </Tooltip>
                </template> -->
                <select-dict v-model="scheme.lengxingId" :default-value="scheme.lengxingId" placeholder="请选择楞型" type="lengxing">
                </select-dict>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem label="输入指定边压（牛/米）" prop="bya">
                <template #label>
                  输入指定边压（牛/米）<span class="input-help" style="position: absolute;right:20px;top:0px"><a href="javascript:;"
                       style="text-decoration: underline;" @click="getSuggestionPx(scheme,true)">查看参考值</a></span>
                </template>
                <int-input type="number" v-model.lazy="scheme.bya" :default-value="scheme.bya" style="width:100%;"
                           placeholder="请输入数值范围0-26260的边压值" :maxlength="5" :min="0" :max="26260" :precision="0"
                           @on-blur="getSuggestionPx(scheme,false)">
                </int-input>
                <div class="ivu-form-item-error-tip" v-if="scheme.tjby && scheme.bya < scheme.tjby * 0.8" style="color:#ed4014">
                  提示：边压过低</div>
                <div class="ivu-form-item-error-tip" v-if="scheme.tjby && scheme.bya > scheme.tjby * 1.2" style="color:#ed4014">
                  提示：过度包装</div>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem label="与箱型匹配的托盘信息" :prop="'customScheme.' + index + '.needPallet'"
                        :rules="[{ required: true, message: '请选择托盘信息' }]" :ref="'customScheme.' + index + '.needPallet'">
                <RadioGroup v-model="scheme.needPallet">
                  <Radio :label="1">需要</Radio>
                  <Radio :label="0">不需要</Radio>
                </RadioGroup>
                <template #label>
                  与箱型匹配的托盘信息
                  <Tooltip placement="top">
                    <div href="" class="ico-tips">
                      <Icon type="ios-alert" style="color:#67c5ec" />
                    </div>
                    <div slot="content">
                      <p>用于转移货物的可移动的承载物。</p>
                    </div>
                  </Tooltip>
                </template>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem v-if="scheme.needPallet === 1" label="托盘材料" :ref="'customScheme.'+index+'.palletMaterial'"
                        :prop="'customScheme.'+index+'.palletMaterial'" :rules="[{required:true,message:'选择托盘材料'}]">
                <Select v-model="scheme.palletMaterial" placeholder="请选择托盘材料" :default-value="form.palletMaterial"
                        @on-change="(val) => handleChange(val,index)">
                  <Option :value="1">桃花芯胶合板（国内外均适合使用）</Option>
                  <Option :value="2">原木（仅适合国内使用，不支持出口）</Option>
                </Select>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem v-if="scheme.needPallet === 1 && scheme.palletMaterial" label="进叉方向"
                        :ref="scheme.palletMaterial !== 1?'customScheme.'+index+'.forkDirection':'FormforkDirection'"
                        :prop="'customScheme.'+index+'.forkDirection'"
                        :rules="[{required:scheme.palletMaterial !== 1,message:'请选择进叉方向'}]">
                <Select v-model="scheme.forkDirection" placeholder="请选择" :disabled="scheme.palletMaterial === 1">
                  <Option :value="2">双向进叉</Option>
                  <Option :value="1">四向进叉</Option>
                </Select>
              </FormItem>
              </Col>
            </Row>
          </p>
        </Panel>
      </Collapse>
    </div>
    <div class="addbox" v-if="form.customScheme.length < 4">
      <Button type="primary" @click="addScheme">
        <Icon type="ios-add" /> 添加自定义方案
      </Button>
      <p stye="color:#999999;margin-top:20px;">
        注：系统将根据以上信息为您生成1-2种箱型结构一致但材料不同的方案，您可以改变箱型或材料继续添加0-{{
          4 - form.customScheme.length
        }}条自定义方案，生成结果时将对方案进行对比参照。
      </p>
    </div>

    <!-- <Col :span="20">注：根据以上信息，将产生1～2种，箱型结构相同但材料不同的方案，针对这些方案，您可以改变箱型，或者材料产生新的方案，如果需要，请按照提示选择，是否需要生成新的方案
    <RadioGroup v-model="form.needNewScheme">
      <Radio :label="1">需要</Radio>
      <Radio :label="0">不需要</Radio>
    </RadioGroup>
    </Col>
    <Col :span="12">
    <RadioGroup v-if="form.needNewScheme === 1" v-model="form.whatToChange">
      <Radio :label="1">仅改变箱型</Radio>
      <Radio :label="2">仅改变材料</Radio>
      <Radio :label="3">箱型与材料同时改变</Radio>
    </RadioGroup>
    </Col> -->

    <CartonTypeModal ref="FormCartonTypeModal" @select="handleSelect"></CartonTypeModal>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import { Package } from '@/utils/mixins'
import { getBoxTypeList } from '@/api/packing'
import CartonTypeModal from '../components/CartonTypeModal.vue'
import { isNumber } from 'loadsh'
export default {
  components: {
    CartonTypeModal
  },
  mixins: [Package],
  data () {
    const validateLi = (rule, value, callback) => {
      if (this.form.isRegularPlace === 2) {
        const { innerLong, innerWidth, innerHeight } = this.form
        if (!innerLong || !innerWidth || !innerHeight) {
          callback(new Error('请输入内尺寸'))
        }
      }
      callback()
    }
    const validateProductNumLong = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入产品摆放方式'))
      } else {
        const { productNumLong, productNumWidth, productNumHeight } = this.form
        if (!productNumLong || !productNumWidth || !productNumHeight) {
          return callback(new Error('请输入产品摆放方式'))
        }
        if (productNumLong * productNumWidth * productNumHeight !== this.packingAmout) {
          return callback(new Error('长宽高乘积必须等于每箱包装产品数量'))
        }
      }
      callback()
    }
    const validateGap = (rule, value, callback) => {
      if (!isNumber(value)) {
        callback(new Error('请输入产品摆放间隙'))
      } else {
        const { gap, gapWidth, gapHeight } = this.form
        if (!isNumber(gap) || !isNumber(gapWidth) || !isNumber(gapHeight)) {
          callback(new Error('请输入产品摆放间隙'))
        }
      }
      callback()
    }
    return {
      first: true,
      boxTypeList: [],
      jsonString: '',
      form: {
        isRegularPlace: '',
        productNumLong: '',
        productNumWidth: '',
        productNumHeight: '',
        gap: '',
        gapWidth: '',
        gapHeight: '',
        innerLong: '',
        innerWidth: '',
        innerHeight: '',
        cartonType: '',
        lengxingId: '',
        needPallet: '',
        palletMaterial: '',
        forkDirection: '',
        customScheme: []
      },
      rules: {
        isRegularPlace: [
          {
            required: true,
            message: '请选择产品装箱方式'
          }
        ],
        lengxingId: [
          {
            required: true,
            message: '请选择纸箱楞型'
          }
        ],
        cartonType: [
          {
            required: true,
            message: '请选择箱型'
          }
        ],
        needPallet: [
          { required: true, message: '选择与箱型匹配的托盘信息' }
        ]
      },
      moreRules: {
        rulesProductNumLong: [
          { required: true, validator: validateProductNumLong }
        ],
        rulesProductGap: [{ required: true, validator: validateGap }],
        Li: [{ validator: validateLi }]
      }
    }
  },
  computed: {
    ...mapState({
      OrdinaryPackage: state => state.OrdinaryPackage
    }),

    isEdit () {
      return this.$route.name === 'OrdinaryPackage.edit'
    },

    /* recommendBoxType2 () {
      const { productInfo } = this.OrdinaryPackage
      const { sizeC, sizeK, sizeG, productNumLong2, productNumWidth2, productNumHeight2, gap2, gapWidth2, gapHeight2, productWeight } = productInfo || {}
      let type = ''
      const setType = (val) => {
        type = type || val
      }

      if (!sizeC || !sizeK || !sizeG || !productNumLong2 || !productNumWidth2 || !productNumHeight2 || !productWeight || (gap2 === undefined) || (gapHeight2 === undefined) || (gapWidth2 === undefined)) {
        return ''
      }

      // 计算纸箱内长度
      let Li = 0
      let Wi = 0
      let Hi = 0

      Li = sizeC * productNumLong2 + gap2 * (productNumLong2 + 1)
      Wi = sizeK * productNumWidth2 + gapWidth2 * (productNumWidth2 + 1)
      Hi = sizeG * productNumHeight2 + gapHeight2 * productNumHeight2
      const weight = productNumLong2 * productNumWidth2 * productNumHeight2 * productWeight
      const length = Li + Wi + Hi
      if (this.first2 === true) {
        if (weight <= 10 && length <= 1000) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.lengxingId2 = 1
        } else if (weight <= 40 && length <= 2000) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.lengxingId2 = 7
        } else if (weight <= 55 && length <= 2000) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.lengxingId2 = 10
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.first2 = false
        if (Hi < 150 && Hi > 70) {
          setType('0300型天地盒')
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.cartonType2 = 12
        }

        if (Li > 600 && Wi > 600 && Hi > 150 && Hi < 300) {
          setType('0306型天盖大地盒')
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.cartonType2 = 13
        }

        if (Li > 2 * Wi && Wi < 450 && Hi > 800) {
          setType('0203型全页箱')
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.cartonType2 = 11
        }

        if (Hi < 800) {
          setType('0201型半页箱')
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.cartonType2 = 10
        }

        if (Hi > 800) {
          setType('围框天地盖')
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.cartonType2 = 23
        }
      }
      return `（推荐选择${type}）`
    } */
    destination () {
      const { logisticsInformation } = this.OrdinaryPackage || {}
      return this.$get(logisticsInformation, 'destination')
    },
    packingAmout () {
      const { productInfo } = this.OrdinaryPackage || {}
      return this.$get(productInfo, 'packingAmout', 0)
    }
  },

  watch: {
    destination: function (newval, oldval) {
      if (this.form.needPallet === 1) {
        if (newval === 1) {
          this.form.palletMaterial = '2'
        } else {
          this.form.palletMaterial = '1'
          this.form.forkDirection = '1'
        }
      }
    },
    'form.isBag': function (newval) {
      if (newval === '0') {
        this.form.bagFunction = ''
        this.form.bagMaterial = ''
        this.form.bagType = ''
        this.form.bagThickness = ''
      }
    },
    'form.palletMaterial': function (newval) {
      if (newval === '1') {
        this.form.forkDirection = '1'
      } else {
        // this.form.forkDirection = ''
      }
    },
    'form.lengxingId': function (newval) {
      console.log(this.form.lengxingId)
    },
    'form.needPallet': function (newval) {
      if (newval === 0) {
        this.form.forkDirection = ''
        this.form.palletMaterial = ''
      } else {
        const { logisticsInformation } = this.OrdinaryPackage
        if (logisticsInformation && logisticsInformation.destination === 1) {
          this.form.palletMaterial = '2'
        } else {
          this.form.palletMaterial = '1'
        }
      }
    },
    'form.isRegularPlace': function (newval) {
      if (this.first) return true
      if (newval === 2) {
        this.form.productNumLong = ''
        this.form.productNumWidth = ''
        this.form.productNumHeight = ''
        this.form.gap = ''
        this.form.gapWidth = ''
        this.form.gapHeight = ''
      } else {
        this.form.innerLong = ''
        this.form.innerWidth = ''
        this.form.innerHeight = ''
      }
    },
    'form.productNumLong': function () {
      this.setBoxSize()
    },
    'form.productNumWidth': function () {
      this.setBoxSize()
    },
    'form.productNumHeight': function () {
      this.setBoxSize()
    },
    'form.gap': function () {
      this.setBoxSize()
    },
    'form.gapWidth': function () {
      this.setBoxSize()
    },
    'form.gapHeight': function () {
      this.setBoxSize()
    },
    'form.innerLong': function () {
      this.recommendBoxType()
    },
    'form.innerWidth': function () {
      this.recommendBoxType()
    },
    'form.innerHeight': function () {
      this.recommendBoxType()
    },
    form: {
      deep: true,
      handler: function (newval, oldval) {
        console.log('jsonString', this.jsonString)
        console.log('newval', JSON.stringify(newval))
        if (JSON.stringify(newval) !== this.jsonString) this.first = false
        this.$store.commit('SetOrdinaryPackage', {
          ...this.OrdinaryPackage,
          packagingRequirements: this.form
        })
        this.computedCount()
      }
    }
  },
  created () {
    this.first = true
  },
  async mounted () {
    await this.getBoxTypeList()
    if (this.OrdinaryPackage && this.OrdinaryPackage.packagingRequirements) {
      this.form = Object.assign(
        {},
        this.form,
        this.OrdinaryPackage.packagingRequirements
      )
      this.jsonString = JSON.stringify(this.form)
      this.$nextTick(() => {
        this.computedCount()
      })
    }
  },
  methods: {
    recommendBoxType () {
      if (this.first) return
      const { productInfo } = this.OrdinaryPackage
      const { sizeC, sizeK, sizeG, productWeight } = productInfo || {}
      let type = ''
      const setType = val => {
        type = type || val
      }

      // const { sizeC, sizeK, sizeG, productWeight } = productInfo || {}
      if (!sizeC || !sizeK || !sizeG || !productWeight) {
        return ''
      }
      // 计算纸箱内长度
      const weight = productInfo.weight
      const Li = this.form.innerLong
      const Wi = this.form.innerWidth
      const Hi = this.form.innerHeight
      const length = Li + Wi + Hi
      if (weight <= 10 && length <= 1000) {
        this.form.lengxingId = 1
      } else if (weight <= 40 && length <= 2000) {
        this.form.lengxingId = 8
      } else if (weight > 40 && length <= 2000) {
        this.form.lengxingId = 10
      } else if (weight <= 55 && length > 2000) {
        this.form.lengxingId = 10
      } else {
        this.form.lengxingId = 10
      }
      if (Hi < 150 && Hi > 70) {
        setType('0300型天地盒')
        this.form.cartonType = 12
      }

      if (Li > 600 && Wi > 600 && Hi > 150 && Hi < 300) {
        setType('0306型天盖大地盒')
        this.form.cartonType = 13
      }

      if (Li > 2 * Wi && Wi < 450 && Hi > 800) {
        setType('0203型全页箱')
        this.form.cartonType = 11
      }

      if (Hi < 800) {
        setType('0201型半页箱')
        this.form.cartonType = 10
      }

      if (Hi > 800) {
        setType('围框天地盖')
        this.form.cartonType = 23
      }
      // return `（推荐选择${type}）`
    },
    setBoxSize () {
      if (this.first) return
      const { productInfo } = this.OrdinaryPackage
      const { sizeC, sizeK, sizeG } = productInfo || {}
      const {
        productNumLong,
        productNumWidth,
        productNumHeight,
        gap,
        gapWidth,
        gapHeight
      } = this.form
      // 计算纸箱内长度
      let Li = 0
      let Wi = 0
      let Hi = 0

      Li = sizeC * productNumLong + gap * (productNumLong + 1)
      Wi = sizeK * productNumWidth + gapWidth * (productNumWidth + 1)
      Hi = sizeG * productNumHeight + gapHeight * productNumHeight
      // 在根据产品摆放后，得到的纸箱内尺寸的宽＞长，此时软件需自行判断，将原有的宽改为长，原有的长改为宽
      if (Wi > Li) {
        const w = Li
        Li = Wi
        Wi = w
      }
      this.form.innerLong = Li || ''
      this.form.innerWidth = Wi || ''
      this.form.innerHeight = Hi || ''
    },
    async getBoxTypeList () {
      return getBoxTypeList({ pageNum: 1, pageSize: 10, isRecommend: 0 }).then(
        res => {
          if (res.isSuccess) {
            this.boxTypeList = res.rows
          }
        }
      )
    },
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.Form.validate(valid => {
          if (valid) {
            resolve(valid)
          }
          reject(valid)
        })
      })
    },
    computeSumLight () {
      const { productNumLong, productNumWidth, productNumHeight } = this.form
      if (productNumLong && productNumWidth && productNumHeight) {
        this.form.quntity = productNumLong * productNumWidth * productNumHeight
        this.computeWeightLight()
      }
    },
    computeWeightLight () {
      const { productWeight, quntity } = this.form
      if (!isNaN(productWeight) && !isNaN(quntity)) {
        this.form.weight =
          this.form.productWeight &&
          this.form.productWeight * this.form.quntity
      }
    },
    getProtectImg (type, pos = 'dingbu') {
      if (!type) {
        return ''
      }
      // 不保护 - 没图片
      if (type + '' === '0') {
        return ''
      }
      return `static/huanchong/${pos}_${type}.png`
    },
    preivewImg (type, pos = 'dingbu') {
      this.$refs[`Form${pos}${type}`][0].clickHandler()
    },
    handleSelect (item) {
      if (!this.boxTypeList.find(e => e.id === item.id)) {
        this.boxTypeList.push(item)
      }
      this.form.cartonType = item.id
    },
    handleChange (value, index) {
      if (value === 1) {
        this.form.customScheme[index].forkDirection = 1
      }
    },
    addScheme () {
      const data = {
        cartonType: '',
        lengxingId: '',
        bya: '',
        palletMaterial: '',
        needPallet: '',
        forkDirection: ''
      }
      this.form.customScheme.push(data)
    },
    removeScheme (index) {
      this.form.customScheme.splice(index, 1)
    },
    getSuggestionPx (item, showMsg) {
      this.$emit('getSuggestionPx', item, showMsg)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-form-item {
    // margin-bottom: 40px;
}
/deep/ .ivu-checkbox-wrapper {
    margin-right: 40px;
}
/deep/ .ivu-radio-wrapper {
    margin-right: 40px;
}
.addbox {
    border: 1px solid #67c5ec;
    border-radius: 4px;
    padding: 10px 20px;
    text-align: center;
    margin-top: 20px;
    p {
        margin-top: 10px;
        color: #999999;
    }
}
</style>
