<template>
  <div>
    <Row>
      <Col :span="18">
      <table class="params-table">
        <tr v-for="item in changDaokaDataLight" :key="item.key">
          <td class="label">{{item.key}}：</td>
          <td>{{item.value}}</td>
        </tr>
      </table>
      </Col>
      <Col :span="6" class="text-right">
      <e-image fit="contain" style=" height: 80px;" src="static/daoka/changDaoka.png"
               :preview-src-list="['static/daoka/changDaoka.png']" />
      </Col>
    </Row>
    <!-- <Table :data="changDaokaDataLight" :columns="[
        { title: 'key', key: 'key', width: 200 },
        {
          title: 'value',
          key: 'value',
          render: (h, params) => {
            return tableFmt(h, params, 'value');
          }
        }
      ]" style="width: 100%" :show-header="false">
    </Table>
    <div class="title">长刀卡配纸方案</div>
    <Table :data="zhixiangData" :columns="[
        { title: '配置方案', key: 'peizhi', width: 200 },
        {
          title: '品牌',
          key: 'pinpai',
          render: (h, params) => {
            return tableFmt(h, params, 'pinpai');
          }
        },
        {
          title: '克重',
          key: 'kezhong',
          render: (h, params) => {
            return tableFmt(h, params, 'kezhong');
          }
        },
        {
          title: '环压',
          key: 'huanya',
          render: (h, params) => {
            return tableFmt(h, params, 'huanya');
          }
        }
      ]" style="width: 100%">
    </Table> -->
  </div>
</template>

<script>
import { PackageInfo } from '@/utils/mixins'
import { isNumber } from 'loadsh'
export default {
  mixins: [PackageInfo],
  inject: {
    zhixiangData: {
      default: () => ({})
    }
  },
  data () {
    return {
      changDaokaDataLight: [
        {
          key: '长刀卡尺寸(mm)',
          value: ''
        },
        {
          key: '长刀卡数量',
          value: ''
        }, {
          key: '材料',
          value: ''
        }
      ]
    }
  },
  created () {
    this.assignDaokaDataLight(this.packageInfo)
  },
  methods: {
    assignDaokaDataLight (info) {
      const daokaInfo = info.packageinfo.auxiliary || {}
      // 长刀卡
      this.changDaokaDataLight[0].value = `${daokaInfo.longDaokaLong ||
        '---'} * ${daokaInfo.longDaokaWidth || '---'} mm`
      this.changDaokaDataLight[1].value = daokaInfo.longDaokaAmount
      const lengxing = this.zhixiangData.find(e => e.peizhi === '楞型')
      const lengxingName = lengxing ? ' ' + lengxing.kezhong : ''
      this.changDaokaDataLight[2].value = this.$get(this.zhixiangData, '[0].pinpai') + ' ' + this.zhixiangData.filter(e => e.kezhong && isNumber(e.kezhong)).map(e => e.kezhong).join('/') + lengxingName + ' ' + this.$get(this.packageInfo, 'packageinfo.packageMaterialPlan.px', '') + 'N/m'
    },
    tableFmt (h, params, key) {
      if (typeof params.row[key] === 'number') {
        return h('span', params.row[key])
      }
      return h('span', params.row[key] || '---')
    },
    updateData () {
      this.assignDaokaDataLight(this.packageInfo)
    }
  }

}
</script>

<style lang="scss" scoped></style>
