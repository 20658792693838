<template>
  <Modal v-model="modal1" title="产品信息" @on-ok="ok" @on-cancel="cancel" footer-hide>
    <Form :label-width="80" label-colon>
      <Row :gutter="40">
        <Col :span="12">
        <FormItem label="产品名称">
          {{get(packageInfo,'productName')}}
        </FormItem>
        </Col>
        <Col :span="12">

        <FormItem label="产品尺寸">
          {{get(packageInfo,'productSize')}}
        </FormItem>
        </Col>
        <Col :span="12">
        <FormItem label="产品数量">
          {{get(packageInfo,'orderAmout')}}
        </FormItem>
        </Col>
        <Col :span="12">
        <FormItem label="产品重量">
          {{get(packageInfo,'productWeight')}}kg
        </FormItem>
        </Col>
        <Col :span="12">
        <FormItem label="产品类型">
          {{get(packageInfo,'productType')|dict('product_type')}}
        </FormItem>
        </Col>
        <Col :span="12">
        <FormItem label="产品价值">
          {{get(packageInfo,'productValue')}}
        </FormItem>
        </Col>
      </Row>

    </Form>
  </Modal>
</template>
<script>

export default {
  inject: {
    packageInfo: {
      default: () => ({})
    }
  },
  data () {
    return {
      modal1: false
    }
  },
  methods: {
    show () {
      this.modal1 = true
    },
    ok () {

    },
    cancel () {
      this.modal1 = false
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .ivu-form-item-label {
    color: #999999;
}
</style>
