var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Table',{staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.priceListLight,"columns":[
      {
        title:'序号',
        key: 'index',
        width: 80,
        align: 'center',
        render: (h, params) => {
          //return h('span', params.row.index === '合计' ? '合计': params.row.index +''+ (params.index+1) )
          return h('span', params.row.index === '合计' ? '合计': (params.index+1) )
        }
      },
      {
        title: '名称',
        key: 'name',
        align: 'center',
        render: (h, params) => {
          return _vm.tableFmt(h, params, 'name');
        }
      },
      {
        title: '尺寸mm',
        key: 'chicun',
        align: 'center',
        render: (h, params) => {
          return _vm.tableFmt(h, params, 'chicun');
        }
      },
      {
        title: '材料',
        key: 'cailiao',
        align: 'center',
        render: (h, params) => {
          return _vm.tableFmt(h, params, 'cailiao');
        }
      },
      {
        title: '单价',
        key: 'unit',
        align: 'center',
        render: (h, params) => {
          return _vm.tableFmt(h, params, 'unit');
        }
      },
      {
        title: '数量',
        key: 'amount',
        align: 'center',
        render: (h, params) => {
          return _vm.tableFmt(h, params, 'amount');
        }
      },
      {
        title: '总价（元）',
        key: 'total',
        align: 'center',
        render: (h, params) => {
          return _vm.tableFmt(h, params, 'total');
        }
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }