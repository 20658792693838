<template>
  <div>
    <Row>
      <Col :span="12">
      <table class="params-table">
        <tr v-for="item in zhanbanDataTop" :key="item.key">
          <td class="label">{{item.key}}：</td>
          <td>{{item.value}}</td>
        </tr>
      </table>
      </Col>
      <Col :span="12" class="text-right">
      <e-image fit="contain" style="width: 200px; height: 200px; padding-left: 10px" :src="getZhanbanImgHeavy() + '.png'"
               :preview-src-list="[getZhanbanImgHeavy() + '.png']">
        <div class="el-image__error" slot="error">暂无图片</div>
      </e-image>
      <e-image fit="contain" style="width: 200px; height: 200px; padding-left: 10px" :src="getZhanbanImgHeavy()+'_2.png'"
               :preview-src-list="[getZhanbanImgHeavy()+'_2.png']">
        <div class="el-image__error" slot="error">暂无图片</div>
      </e-image>
      </Col>
    </Row>
    <!-- <Table :data="zhanbanDataTop" :columns="[
        { title: 'key', key: 'key', width: 200 },
        {
          title: 'value',
          key: 'value',
          render: (h, params) => {
            return tableFmt(h, params, 'value');
          }
        }
      ]" style="width: 100%" :show-header="false">
    </Table> -->
    <Table border :loading="loading" :data="zhanbanData" :columns="[
        { title: '构建清单', key: 'bom',slot: 'bom',align:'center'},
        {
          title: '长度(mm)',
          key: 'length',
          align:'center',
          slot: 'length',
        },
        {
          title: '宽度(mm)',
          key: 'width',
          align:'center',
          slot: 'width',
        },
        {
          title: '高度(mm)',
          key: 'height',
          align:'center',
          slot: 'height',
        },
        {
          title: '数量',
          key: 'quantity',
           align:'center',
          slot: 'quantity',
        },{
          title: '材料',
          key: 'bomMaterial',
           align:'center',
          slot: 'bomMaterial',
          width:200
        },
         {
          title: '操作',
          key: 'action',
           align:'center',
          slot: 'action',
        }
      ]" style="width: 100%">
      <template slot-scope="{ row }" slot="bom">
        <span v-if="row.bomId">{{row.bomName}}</span>
        <Input v-else v-model="zhanbanData[row._index].bomName" placeholder="输入名称"></Input>
      </template>
      <template slot-scope="{ row }" slot="length">
        <span v-if="row.bomId">{{row.bomLong}}</span>
        <Input v-else type="number" v-model.number="zhanbanData[row._index].bomLong" :readonly="row.bomId"
               placeholder="输入尺寸"></Input>
      </template>
      <template slot-scope="{ row }" slot="width">
        <span v-if="row.bomId">{{row.bomWidth}}</span>
        <Input v-else type="number" v-model.number="zhanbanData[row._index].bomWidth" :readonly="row.bomId"
               placeholder="输入尺寸"></Input>
      </template>
      <template slot-scope="{ row }" slot="height">
        <span v-if="row.bomId">{{row.bomHeight}}</span>
        <Input v-else type="number" v-model.number="zhanbanData[row._index].bomHeight" :readonly="row.bomId"
               placeholder="输入尺寸"></Input>
      </template>
      <template slot-scope="{ row }" slot="quantity">
        <span v-if="row.bomId">{{row.bomAmount}}</span>
        <Input v-else type="number" v-model.number="zhanbanData[row._index].bomAmount" :readonly="row.bomId"
               placeholder="输入数量"></Input>
      </template>
      <template slot-scope="{ row }" slot="bomMaterial">
        <span v-if="row.bomId">
          <template v-if="row.bomType === 0">
            <span v-if="row.bomMaterial === 1">桃花芯胶合板</span>
            <span v-if="row.bomMaterial === 2">原木</span>
          </template>
          <template v-if="row.bomType === 1">
            <span>{{getName(row.bomMaterial)}}</span>
          </template>
        </span>
        <Select v-else v-model="zhanbanData[row._index].bomMaterial" placeholder="请选择物料材料" transfer :disabled="row.bomId"
                filterable>
          <Option v-for="item in customMaterialList" :key="item.materialId" :value="item.materialId">{{item.materialName}}
          </Option>
        </Select>
      </template>
      <template slot-scope="{ row }" slot="action">
        <template v-if="!row.bomId">
          <a href="javascript:;" style="margin-right: 10px;" @click="saveBom(row)">保存</a>
          <Poptip confirm title="确认移除吗?" @on-ok="removeRow(row._index)" transfer>
            <a href="javascript:;">移除</a>
          </Poptip>
        </template>
        <Poptip v-else confirm title="确认移除吗?" @on-ok="removeBom(row)" transfer>
          <a href="javascript:;">移除</a>
        </Poptip>
      </template>
    </Table>
    <div class="text-center mt20"><Button ghost type="primary" @click="addBom">添加一条</Button></div>
  </div>
</template>

<script>
import { listPalletBomInfo, delPalletBomInfo, addPalletBomInfo, getUserPackageHistoryRecordsAndPage, getCustomMaterial } from '@/api/packing'

import { PackageInfo } from '@/utils/mixins'
export default {
  mixins: [PackageInfo],
  data () {
    return {
      loading: false,
      customMaterialList: [],
      zhanbanDataTop: [
        {
          key: '品名',
          value: '托盘/栈板'
        },
        {
          key: '规格(mm)',
          value: ''
        },
        {
          key: '材料',
          value: ''
        },
        {
          key: '数量',
          value: '1'
        }
      ],
      zhanbanData: [
        {
          bom: '面板',
          length: '',
          width: '',
          height: '',
          quantity: ''
        },
        {
          bom: '上拉板1',
          length: '',
          width: '',
          height: '',
          quantity: ''
        },
        {
          bom: '上拉板2',
          length: '',
          width: '',
          height: '',
          quantity: ''
        },
        {
          bom: '脚蹲',
          length: '',
          width: '',
          height: '',
          quantity: ''
        },
        {
          bom: '下拉板1',
          length: '',
          width: '',
          height: '',
          quantity: ''
        },
        {
          bom: '下拉板2',
          length: '',
          width: '',
          height: '',
          quantity: ''
        }
      ]
    }
  },
  computed: {
    packageType () {
      return this.$get(this.packageInfo, 'packageinfo.packageType', '') || 2
    }
  },
  created () {
    this.assignZhanbanData()
    this.listPalletBomInfo()
    this.getCustomMaterial()
  },
  methods: {
    getCustomMaterial () {
      getCustomMaterial().then(res => {
        if (res.code === 0) {
          this.customMaterialList = res.rows || []
        }
      })
    },
    getName (bomMaterial) {
      const obj = this.customMaterialList.find(e => e.materialId === bomMaterial)
      return obj && obj.materialName
    },
    getZhanbanImgHeavy () {
      const palletInfoHeavy = this.packageInfo.packageinfo.pallet.palletInfoHeavy || {}
      const palletType = palletInfoHeavy.palletType || {}

      // 上拉板类型
      const upplate1Num = palletInfoHeavy.upplate1Num
      const upplate2Num = palletInfoHeavy.upplate2Num
      let uppalletType = 0
      if (upplate1Num === 0 && upplate2Num === 0) {
        uppalletType = 0// 没有上拉板
      } else if (upplate1Num !== 0 && upplate2Num !== 0) {
        uppalletType = 1// 日字型上拉板
      } else {
        uppalletType = 2// 川字型上拉板
      }

      // 下拉板类型
      const downplate1Num = palletInfoHeavy.downplate1Num
      const downplate2Num = palletInfoHeavy.downplate2Num
      let downpalletType = 0
      if (downplate1Num === 0 && downplate2Num === 0) {
        downpalletType = 0// 没有下拉板
      } else if (downplate1Num !== 0 && downplate2Num !== 0) {
        downpalletType = 1// 日字型下拉板
      } else {
        downpalletType = 2// 川字型下拉板
      }

      let lengthLowerLimit = palletInfoHeavy.lengthLowerLimit
      let loadingLowerLimit = palletInfoHeavy.loadingLowerLimit

      // 匹配尺寸和承重范围
      if (palletType === 2) { // 普通式
        console.log(uppalletType, downpalletType)
        if (uppalletType === 1 && downpalletType === 1) { // 第一类：日字形上拉板+日字形下拉板
          // 序号1，2
          if (lengthLowerLimit === 0) {
            if (loadingLowerLimit !== 0) {
              loadingLowerLimit = 200
            }
          }
          loadingLowerLimit = 0
          // 序号3
          if (lengthLowerLimit < 1200) {
            lengthLowerLimit = 600
          } else {
            // 序号4
            lengthLowerLimit = 1200
          }
        } else if (uppalletType === 0 && downpalletType === 1) { // 第五类：无上拉板+日字形下拉板
          loadingLowerLimit = 0
          // 序号1
          if (lengthLowerLimit < 1200) {
            lengthLowerLimit = 0
          } else {
            // 序号2
            lengthLowerLimit = 1200
          }
        } else { // 第二类+第三类+第四类
          console.log(lengthLowerLimit)
          // 序号1
          if (lengthLowerLimit === 0) {
            loadingLowerLimit = 200
          } else {
            // 序号2，3
            loadingLowerLimit = 0
            if (lengthLowerLimit < 1200) {
              lengthLowerLimit = 0
            } else {
              lengthLowerLimit = 1200
            }
          }
        }
      }

      if (palletType === 3) { // 嵌套式
        // 第一类，第二类
        loadingLowerLimit = 0
        if (lengthLowerLimit < 1200) {
          lengthLowerLimit = 600
        } else {
          loadingLowerLimit = 1200
        }
      }
      // if(uppalletType == 1 && downpalletType == 1){//第一类：日字形上拉板+日字形下拉板
      //     //序号1，2
      //     if(lengthLowerLimit == 0){
      //       if(loadingLowerLimit != 0){
      //         loadingLowerLimit = 200;
      //       }
      //     }
      //   }

      console.log(palletType, uppalletType, downpalletType, lengthLowerLimit, loadingLowerLimit)
      return (
        `static/zhanbanheavy/${palletType}_${uppalletType}_${downpalletType}_${lengthLowerLimit}_${loadingLowerLimit}`
      )
    },
    assignZhanbanData () {
      const palletInfo = this.packageInfo.packageinfo.pallet || {}
      const palletInfoHeavy = palletInfo.palletInfoHeavy || {}

      // 规格
      let palletFinalLong = palletInfo.palletLong
      let palletFinalWitdh = palletInfo.palletWidth
      if (palletInfo.palletType !== null && palletInfo.palletType === 3) {
        palletFinalLong = palletFinalLong + 40
        palletFinalWitdh = palletFinalWitdh + 40
      }
      this.zhanbanDataTop[1].value = `${palletFinalLong} * ${palletFinalWitdh} * ${palletInfo.palletHeight || '---'}`

      this.zhanbanDataTop[2].value = this.getZhanbanMaterialLabel(
        palletInfo.palletMaterial
      )

      // this.zhanbanDataTop[1].value = `${palletInfo.palletLong ||'---'} * ${palletInfo.palletWidth ||'---'}
      //  * ${palletInfo.palletHeight || '---'}`;

      // 面板
      this.zhanbanData[0].length = palletInfo.palletLong
      this.zhanbanData[0].width = palletInfo.palletWidth
      this.zhanbanData[0].height = palletInfoHeavy.panelThickness
      this.zhanbanData[0].quantity = palletInfoHeavy.panelNum
      // 上拉板1
      this.zhanbanData[1].length = palletInfo.palletUpplate1Long
      this.zhanbanData[1].width = palletInfoHeavy.upplate1Width
      this.zhanbanData[1].height = palletInfoHeavy.upplate1Thickness
      this.zhanbanData[1].quantity = palletInfoHeavy.upplate1Num
      // 上拉板2
      this.zhanbanData[2].length = palletInfo.palletUpplate2Long
      this.zhanbanData[2].width = palletInfoHeavy.upplate2Width
      this.zhanbanData[2].height = palletInfoHeavy.upplate2Thickness
      this.zhanbanData[2].quantity = palletInfoHeavy.upplate2Num
      // 脚蹲
      this.zhanbanData[3].length = palletInfoHeavy.stackfootLong
      this.zhanbanData[3].width = palletInfoHeavy.stackfootWidth
      this.zhanbanData[3].height = palletInfoHeavy.stackfootThickness
      this.zhanbanData[3].quantity = palletInfoHeavy.stackfootNum
      // 下拉板1
      this.zhanbanData[4].length = palletInfo.palletDownplate1Long
      this.zhanbanData[4].width = palletInfoHeavy.downplate1Width
      this.zhanbanData[4].height = palletInfoHeavy.downplate1Thickness
      this.zhanbanData[4].quantity = palletInfoHeavy.downplate1Num
      // 下拉板2
      this.zhanbanData[5].length = palletInfo.palletDownplate2Long
      this.zhanbanData[5].width = palletInfoHeavy.downplate2Width
      this.zhanbanData[5].height = palletInfoHeavy.downplate2Thickness
      this.zhanbanData[5].quantity = palletInfoHeavy.downplate2Num
    },
    getZhanbanMaterialLabel (val) {
      switch (true) {
        case val === 1:
          return '桃花芯胶合板'
        case val === 2:
        default:
          return '原木'
      }
    },
    tableFmt (h, params, key) {
      if (typeof params.row[key] === 'number') {
        return h('span', params.row[key])
      }
      return h('span', params.row[key] || '---')
    },
    listPalletBomInfo () {
      this.loading = true
      listPalletBomInfo({ packageInfoId: this.packageInfo.packageinfoId }).then(res => {
        if (res.code === 0) {
          this.loading = false
          this.zhanbanData = res.data
          this.zhanbanData = this.zhanbanData.filter(e => e.bomType !== 2)
        }
      })
    },
    removeBom (row) {
      const { packageInfoId, bomId } = row
      delPalletBomInfo({ packageInfoId, bomId }).then(res => {
        if (res.code === 0) {
          this.listPalletBomInfo()
          this.refreshSolution(this.packageInfo.packageinfoId)
        }
      })
    },
    addBom () {
      this.zhanbanData.push({
        bomName: '',
        bomLong: '',
        bomWidth: '',
        bomHeight: '',
        bomAmount: '',
        bomType: 1
      })
    },
    removeRow (index) {
      this.zhanbanData.splice(index, 1)
    },
    saveBom (data) {
      addPalletBomInfo({ palletBomInfo: [{ ...data, packageInfoId: this.packageInfo.packageinfoId }] }).then(res => {
        if (res.code === 0) {
          this.listPalletBomInfo()
          this.refreshSolution(this.packageInfo.packageinfoId)
        }
      })
    },
    refreshSolution (packageInfoId) {
      getUserPackageHistoryRecordsAndPage({ packageInfoId, pageIndex: 1, pageSize: 10, packageType: this.packageType, status: this.$route.query.status }).then(res => {
        if (res.isSuccess) {
          // this.listData = res.data[0]
          if (res.data.length) {
            this.$store.commit('SetPackageInfo', res.data[0])
          }
        } else {
          this.$Message.error(res.msg)
        }
      })
    },
    updateData () {
      this.assignZhanbanData()
      this.listPalletBomInfo()
    }
  }

}
</script>

<style lang="scss" scoped>
/deep/ .ivu-input {
    border: none;
    text-align: center;
}
</style>
