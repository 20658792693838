<template>
  <div>
    <Modal v-model="isShow" title="纸箱CAD图纸" width="940px">
      <Alert type="warning">提示：生成CAD图纸的尺寸可以根据您的实际需求进行修改</Alert>
      <div ref="box">
        <div class="cadImage">
          <img src="@/assets/images/cad.png" alt="">
          <input class="input input1" type="number" v-model.number="form.length1">
          <input class="input input2" type="number" v-model.number="form.length">
          <input class="input input3" type="number" v-model.number="form.width">
          <input class="input input4" type="number" v-model.number="form.height">
          <input class="input tag1" type="number" v-model.number="form.length">
          <input class="input tag2" type="number" v-model.number="form.width">
          <span class="tag tag3">{{form.length1 + form.width*2 + form.length*2}}</span>
          <span class="tag tag4">{{(((form.width-paperboardThickness) / 2) + paperboardThickness)*2 + form.height}}</span>
          <span class="tag tag5">{{((form.width-paperboardThickness) / 2) + paperboardThickness}}</span>
          <span class="tag tag6">{{((form.width-paperboardThickness) / 2) + paperboardThickness}}</span>
        </div>
        <table class="params-table">
              <tr v-for="item in zhixiangDataTop" :key="item.key">
                <td class="label">{{item.key}}：</td>
                <td>{{item.value}}</td>
              </tr>
              <tr>
                <td class="label">{{shouldShowZhixiangData3? '围框' :'配纸方案'}}：</td>
                <td>
                  <span v-for="item in zhixiangData2" style="margin-right:10px;" :key="item.key">
                    {{item.value}}
                  </span>
                </td>
              </tr>
            </table>
      </div>
      <div slot="footer">
        <Button ghost type="primary" @click="downloadImg">生成图片</Button>
        <Button ghost type="primary" @click="generateCAD">生成CAD图纸</Button>
        <Button type="primary" @click="updateCartonSize">保存修改</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { updateCartonSize, getUserPackageHistoryRecordsAndPage } from '@/api/packing'
import qs from 'qs'
import { mapState } from 'vuex'
import html2canvas from 'html2canvas'
export default {
  name: 'ViewCad',
  data () {
    return {
      isShow: false,
      form: {
        length: 300,
        width: 260,
        height: 440,
        length1: 40
      }
    }
  },
  computed: {
    ...mapState({
      packageInfo: state => state.packageInfo
    }),
    cartonSizeSummary () {
      const { cartonSizeSummary } = this.packageInfo.packageinfo
      return cartonSizeSummary || { productionLong1: '', productionWidth1: '', productionHeight1: '' }
    },
    paperboardThickness () {
      const { paperboardThickness } = this.packageInfo
      return paperboardThickness
    },
    cartonType () {
      const { cartonTypeValue } = this.packageInfo
      return cartonTypeValue
    },
    boxName () {
      const { boxType } = this.packageInfo
      return boxType && boxType.boxName
    },
    zhixiangDataTop () {
      return this.$parent.zhixiangDataTop
    },
    zhixiangData2 () {
      return this.$parent.zhixiangData2
    },
    shouldShowZhixiangData3 () {
      return this.$parent.shouldShowZhixiangData3
    }
  },
  mounted () {
    const { productionLong1, productionWidth1, productionHeight1 } = this.cartonSizeSummary
    this.form.length = productionLong1
    this.form.width = productionWidth1
    this.form.height = productionHeight1
    if (this.paperboardThickness < 7) {
      this.form.length1 = 35
    } else if (this.paperboardThickness <= 10) {
      this.form.length1 = 45
    } else if (this.paperboardThickness <= 20) {
      this.form.length1 = 55
    }
  },

  methods: {
    show () {
      this.isShow = true
    },
    downloadImg (imgSrc) {
      const that = this
      console.debug(imgSrc)
      // window.location.href = imgSrc
      html2canvas(this.$refs.box).then(function (canvas) {
        console.log(canvas) // 生成canvas画布
        const base64Str = canvas.toDataURL()
        console.log(base64Str) // 将canvas转为base64图片
        const aLink = document.createElement('a')
        aLink.style.display = 'none'
        aLink.href = base64Str
        aLink.download = that.boxName.replace(/(<([^>]+)>)/ig, '') + '.jpg'
        // 触发点击-然后移除
        document.body.appendChild(aLink)
        aLink.click()
        document.body.removeChild(aLink)
      })
    },
    generateCAD () {
      const params = qs.stringify({ packageinfoId: this.packageInfo.packageinfoId, ...this.form, height1: ((this.form.width - this.paperboardThickness) / 2) + this.paperboardThickness })
      window.open(document.location.protocol + '//' + document.location.host + '/dist/generateCAD?' + params)
    },
    updateCartonSize () {
      const data = {
        ...this.form,
        packageinfoId: this.packageInfo.packageinfoId
      }
      updateCartonSize(data).then(res => {
        if (res.code === 0) {
          this.$Message.success('操作成功')
          window.location.reload()
        }
      })
    },
    refreshSolution () {
      getUserPackageHistoryRecordsAndPage({ packageInfoId: this.packageInfo.packageinfoId, pageIndex: 1, pageSize: 10, packageType: this.packageInfo.packageinfo.packageType }).then(res => {
        if (res.isSuccess) {
          // this.listData = res.data[0]
          if (res.data.length) {
            this.$store.commit('SetPackageInfo', res.data[0])
          }
        } else {
          this.$Message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cadImage {
    position: relative;
    width: 600px;
    margin: 0 auto;
    img {
        width: 100%;
    }
    .tag {
        display: block;
        padding: 2px;
        text-align: center;
        background: #ffffff;
        position: absolute;
        min-width: 40px;
    }
    .input {
        width: 60px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #8d8d8d;
        border-radius: 5px;
        position: absolute;
        padding: 2px 5px;
        text-align: center;
        -moz-appearance: textfield;
    }
    .input1 {
        left: -10px;
        bottom: 22px;
    }
    .input2 {
        left: 90px;
        bottom: 42px;
    }
    .input3 {
        left: 195px;
        bottom: 42px;
    }
    .input4 {
        right: 40px;
        top: 140px;
        transform: rotate(90deg);
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .tag1 {
        left: 295px;
        bottom: 42px;
    }
    .tag2 {
        left: 400px;
        bottom: 42px;
    }
    .tag3 {
        bottom: 15px;
        left: 245px;
    }
    .tag4 {
        right: 5px;
        top: 140px;
        transform: rotate(90deg);
    }
    .tag5 {
        right: 30px;
        top: 40px;
        transform: rotate(90deg);
    }
    .tag6 {
        right: 30px;
        top: 250px;
        transform: rotate(90deg);
    }
}
</style>
