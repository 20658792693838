<template>
  <page-view :title="get(packageInfo,'productName') + '的解决方案'">
    <template slot="extral">
      <Button ghost type="primary" icon="md-download" @click="bindPrint">下载</Button>
      <Button ghost type="primary" icon="md-arrow-back" @click="$router.go(-1)" style="margin-left: 10px;">返回</Button>
    </template>
    <Row :gutter="20">
      <Col :span="24">
      <Card>
        <div v-if="loading" style="width:80px;margin:0 auto;padding-top:100px;">
          <Spin size="large">
            <!-- <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
        加载中... -->
          </Spin>
        </div>
        <div id="printTable">
          <Ordinary v-if="packageType === 2"></Ordinary>
          <Heavy v-if="packageType === 3"></Heavy>
        </div>
      </Card>
      </Col>
      <!-- <Col :span="5">
      <Affix :offset-top="10">
        <RightBar></RightBar>
      </Affix>
      </Col> -->
    </Row>
    <div style="height:100px;"></div>
    <div class="footer-bar">
      <div class="container">
        <div style="flex:1" class="text-center">
        </div>
        <div>
          <!-- <Button ghost type="primary" size="large" @click="generatePdf">生成PDF</Button> -->
          <Button ghost type="primary" size="large" style="margin-left: 20px;" @click="$refs.ToolsModal.show()">自定义工具</Button>
          <Button ghost type="primary" size="large" style="margin-left: 20px;"
                  @click="$refs.ProductInfoModal.show()">查看原产品信息</Button>
          <Button ghost type="primary" size="large" style="margin-left: 20px;" :loading="isCreate"
                  @click="regeneratePack">再次生成方案</Button>
        </div>
      </div>
    </div>
    <ToolsModal ref="ToolsModal"></ToolsModal>
    <ProductInfoModal ref="ProductInfoModal"></ProductInfoModal>
  </page-view>
</template>

<script>
// import banner from '../static/home_rolls.png'

// import pic1 from '@/assets/packinggo/toolPic1.png'
// import pic2 from '@/assets/packinggo/toolPic2.png'
// import pic3 from '@/assets/packinggo/toolPic3.png'
// import pic4 from '@/assets/packinggo/toolPic4.png'
// import pic5 from '@/assets/packinggo/toolPic5.png'
// import pic6 from '@/assets/packinggo/toolPic6.png'
// import pic7 from '@/assets/packinggo/toolPic7.png'
// import pic8 from '@/assets/packinggo/toolPic8.png'
// eslint-disable-next-line no-unused-vars
import print from 'print-js'
import { mapState } from 'vuex'
import { Modal } from 'view-design'
// import RightBar from './components/RightBar'
import ToolsModal from './components/ToolsModal'
import ProductInfoModal from './components/ProductInfoModal'
import Ordinary from './Ordinary'
import Heavy from './Heavy'
import { getUserPackageHistoryRecordsAndPage, createNormalProductArrangement, createProductArrangement } from '@/api/packing'
import { PackageInfo } from '@/utils/mixins'
// import { getPackageInfo } from '@/api/packing'
import htmlToPdf from '@/utils/htmlToPdf'
export default {
  mixins: [PackageInfo],
  components: {
    // RightBar,
    Ordinary,
    Heavy,
    ToolsModal,
    ProductInfoModal
  },
  provide () {
    return {
      packageInfo: this.packageInfo
    }
  },
  data () {
    return {
      isCreate: false,
      loading: false,
      // packageInfo: {},

      packageType: '',
      currentPackageInfo: ''

    }
  },
  watch: {
    packageInfo: {
      deep: true,
      handler: function () {
      }
    }
  },
  computed: {
    ...mapState({
      packageInfo: state => state.packageInfo
    })
  },
  async created () {
    await this.getPackageInfo()
    this.init()
  },
  methods: {
    async getPackageInfo () {
      const { id, packageType, status } = this.$route.query
      this.loading = true
      return getUserPackageHistoryRecordsAndPage({ packageInfoId: id, pageIndex: 1, pageSize: 10, packageType, status }).then(res => {
        if (res.isSuccess) {
          this.loading = false
          this.$store.commit('SetPackageInfo', res.data[0])
        }
      })
    },
    init () {
      const { packageType } = this.packageInfo.packageinfo
      this.packageType = packageType
    },
    generatePdf () {
      htmlToPdf.downloadPDF(document.getElementById('printTable'), 'logo', 12345678)
    },
    regeneratePack () {
      this.$Modal.confirm({
        title: '提示',
        content: '确认重新生成方案吗?',
        onOk: () => {
          const api = this.packageType === 2 ? createNormalProductArrangement : createProductArrangement
          const data = {
            ...this.formData,
            status: 1
          }
          this.isCreate = true
          api(data).then(res => {
            if (res.isSuccess) {
              this.$Message.success('方案生成成功')
              this.$store.commit('SetOrdinaryPackage', {})
              this.$router.push({ name: 'PackagingRecord' })
            } else {
              setTimeout(() => {
                this.isCreate = false
                Modal.error({ title: '提示', content: '方案信息有误，请检查是否需要更改楞型或使用重型方案' })
              }, 1000)
            }
          })
        },
        onCancel: () => {
          // this.$Message.info('Clicked cancel')
        }
      })
    },
    updateData () {
    },
    bindPrint () {
      // eslint-disable-next-line no-undef
      printJS({
        printable: 'printTable',
        type: 'html',
        header: null,
        targetStyles: ['*'],
        style: '@page {margin:0 10mm}'
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.title {
    border-left: 4px solid #67c5ec;
    padding-left: 10px;
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-bottom: 10px;
}
</style>
