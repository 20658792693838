<template>
  <page-view title="包装记录">
    <template slot="extral">
      <Button ghost type="primary" @click="$router.go(-1)">返回</Button>
    </template>
    <div class="search-box">
      <Form ref="formInline" size="large" :model="form" inline>
        <FormItem>
          <Select v-model="form.packageType" style="width: 300px;">
            <Option :value="0">全部</Option>
            <Option :value="2">普通包装</Option>
            <Option :value="3">重型包装</Option>
          </Select>
        </FormItem>
        <FormItem>
          <Input v-model="form.productName" placeholder="输入产品名称进行搜索" style="width: 300px;" @on-enter="search">
          <Icon slot="suffix" type="ios-search" style="cursor: pointer;" @click="search" />
          </Input>
        </FormItem>
      </Form>
      <div class="search-box__right" v-if="listData.length">
        <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>
        <Button style="margin-left:20px;color:#FF4E4E;width: 100px;border:none" @click="batchDel">删除</Button>
      </div>
    </div>
    <div v-if="loading" style="width:80px;margin:0 auto;padding-top:100px;">
      <Spin size="large">
        <!-- <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
        加载中... -->
      </Spin>
    </div>
    <div class="product-list" v-loading="loading">
      <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
        <div :key="item.packageinfoId" class="product-list-item " v-for="item in listData">
          <div class="product-list-item__radio">
            <Checkbox :label="item.packageinfoId"> </Checkbox>
          </div>
          <div class="product-list-item__content" @click="gotoDetail(item)">
            <div class="product-info">
              <div class="product-name">产品名称： {{ item.productName }}</div>
              <p>创建时间：{{ item.createTime }}</p>
              <p>完成时间：{{ item.createTime }}</p>
            </div>
            <div class="product-tag">
              <span>产品尺寸：{{ item.productSize }}</span>
              <span>产品数量：{{ item.packageAmout }}</span>
              <span>产品重量：{{ item.productWeight }}Kg</span>
              <span class="gray">合计总价 ：{{
                  get(item, "packageinfo.price.planTotalPrice")
                }}元</span>
            </div>
          </div>
          <div class="product-list-item__bts">
            <a href="javascript:;" @click.stop="handleEdit(item)">
              编辑
            </a>
            <a href="javascript:;" @click.stop="handleDel(item.packageinfoId)">
              删除
            </a>
          </div>
        </div>

      </CheckboxGroup>
      <Empty v-if="listData.length === 0 && !loading"></Empty>
      <div style="height:160px;"></div>
      <div v-if="total" class="footer-bar">
        <div class="container">
          <div style="flex:1" class="text-center">
            <Page :total="total" :page-size="form.pageSize" :current.sync="form.pageIndex" show-total />
          </div>
        </div>
      </div>
    </div>
  </page-view>
</template>

<script>
import {
  getUserPackageHistoryRecordsAndPage,
  getPackageTypeCountById,
  deletePackageInfo
} from '@/api/packing'
export default {
  data () {
    return {
      searchForm: {},
      selectAll: false,
      listData: [],
      indeterminate: false,
      checkAll: false,
      checkAllGroup: [],
      form: {
        pageIndex: 1,
        pageSize: 6,
        packageType: 0,
        status: 1
      },
      total: 0
    }
  },
  watch: {
    'form.packageType': function () {
      this.search()
    },
    'form.pageIndex': function () {
      this.getUserPackageHistoryRecordsAndPage()
    }
  },
  created () {
    this.getUserPackageHistoryRecordsAndPage()
    this.getPackageTypeCountById()
  },
  methods: {
    handleCheckAll () {
      if (this.indeterminate) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.indeterminate = false

      if (this.checkAll) {
        this.checkAllGroup = []
        this.listData.forEach(e => {
          this.checkAllGroup.push(e.packageinfoId)
        })
      } else {
        this.checkAllGroup = []
      }
    },
    checkAllGroupChange (data) {
      if (data.length === this.listData.length) {
        this.indeterminate = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.indeterminate = true
        this.checkAll = false
      } else {
        this.indeterminate = false
        this.checkAll = false
      }
    },
    search () {
      this.form.pageIndex = 1
      this.getUserPackageHistoryRecordsAndPage()
      this.getPackageTypeCountById()
    },
    getUserPackageHistoryRecordsAndPage () {
      this.loading = true
      getUserPackageHistoryRecordsAndPage(this.form).then(res => {
        this.loading = false
        if (res.isSuccess) {
          this.listData = res.data
        } else {
          this.$Message.error(res.msg)
        }
      })
    },
    getPackageTypeCountById () {
      getPackageTypeCountById().then(res => {
        if (res.isSuccess) {
          const data = res.data.filter(
            e =>
              this.form.packageType === 0 ||
              e.package_type === this.form.packageType
          )
          this.total = data.length
            ? data.map(e => e.count).reduce((total, a) => total + a)
            : 0
        }
      })
    },
    gotoDetail (item) {
      this.$store.commit('SetPackageInfo', item)
      this.$router.push({
        name: 'PackageInfo',
        query: { id: item.packageinfoId, packageType: item.packageinfo.packageType, status: 1 }
      })
    },
    handleEdit (item) {
      // todo
      const { packageType, packageinfoId } = item

      if (packageType === '普通包装') {
        this.$router.push({
          name: 'OrdinaryPackage.edit',
          query: { packageinfoId, status: 1, packageType: item.packageinfo.packageType }
        })
      } else {
        this.$router.push({
          name: 'HeavyPackage.edit',
          query: { packageinfoId, status: 1, packageType: item.packageinfo.packageType }
        })
      }
    },
    handleDel (id) {
      // todo
      this.$Modal.confirm({
        title: '提示',
        content: '确认删除方案吗?',
        onOk: () => {
          deletePackageInfo(id).then(res => {
            if (res.isSuccess) {
              this.$Message.success('删除成功')
              this.getUserPackageHistoryRecordsAndPage()
            } else {
              this.$Message.error(res.msg)
            }
          })
        },
        onCancel: () => {
          // this.$Message.info('Clicked cancel')
        }
      })
    },
    batchDel () {
      if (this.checkAllGroup.length === 0) {
        this.$Message.error('请选择要删除的方案')
        return
      }
      this.handleDel(this.checkAllGroup.join(','))
    }
  }
}
</script>

<style lang="scss" scoped>
.search-box {
    display: flex;
    justify-content: space-between;
}

/deep/ .ivu-checkbox-wrapper span:nth-of-type(2) {
    display: none;
}

.product-list {
    &-item {
        display: flex;
        align-items: center;
        padding: 30px 20px;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-bottom: 30px;
        &:hover {
            // box-shadow: 0px 4px 4px rgba(103, 197, 236, 0.2);
        }
        &__radio {
            width: 30px;
            text-align: center;
        }
        &__content {
            display: flex;
            flex: 1;
            margin-left: 20px;
            cursor: pointer;
        }
        &__bts {
            a {
                color: #67c5ec;
                margin-right: 30px;
            }
        }
    }

    .product-info {
        flex: 1;
        margin-top: 10px;
        .product-name {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 10px;
            letter-spacing: -0.02em;
            color: #000000;
        }
        p {
            line-height: 25px;
            letter-spacing: -0.02em;
            color: #ababab;
        }
    }

    .product-tag {
        margin-left: 60px;
        width: 500px;
        span {
            display: inline-block;
            padding: 8px 12px;
            margin: 10px 8px;
            background: rgba(103, 197, 236, 0.2);
            border: 1px solid #67c5ec;
            box-sizing: border-box;
            border-radius: 20px;
            font-size: 12px;
            color: #67c5ec;
            text-align: center;
        }
        .gray {
            background: rgba(224, 224, 224, 0.2);
            border: 1px solid #e0e0e0;
            color: #999999;
        }
    }
}
</style>
