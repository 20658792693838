<template>
  <page-view title="普通包装方案" class="customTab">
    <template slot="extral">
      <Button ghost type="primary" @click="back" style="background:#ffffff;width:100px;">返 回</Button>
    </template>
    <Tabs v-model="activeIndex">
      <TabPane :label="`产品信息  ${pass1}/${total1}`" :name="'1'"></TabPane>
      <TabPane :label="`物流信息 ${pass2}/${total2}`" :name="'2'"></TabPane>
      <TabPane :label="`包装要求1 ${pass3}/${total3}`" :name="'3'"></TabPane>
      <TabPane :label="`包装要求2 ${pass4}/${total4}`" :name="'4'"></TabPane>
      <!-- <TabPane label="参考信息" :name="'4'"></TabPane> -->
    </Tabs>
    <div v-if="!isEdit || (isEdit&&loading===false)" class="form-wrap">
      <ProductInfo v-model="form.ProductInfo" :count.sync="pass1" :total.sync="total1" ref="ProductInfo"
                   v-show="activeIndex === '1'">
      </ProductInfo>
      <LogisticsInformation ref="LogisticsInformation" v-model="form.LogisticsInformation" :count.sync="pass2"
                            :total.sync="total2" v-show="activeIndex === '2'">
      </LogisticsInformation>
      <PackagingRequirements ref="PackagingRequirements" v-model="form.PackagingRequirements" :count.sync="pass3"
                             :total.sync="total3" v-show="activeIndex === '3'" @getSuggestionPx="getSuggestionPx">
      </PackagingRequirements>
      <PackagingRequirementsTwo ref="PackagingRequirementsTwo" v-model="form.PackagingRequirements" :count.sync="pass4"
                                :total.sync="total4" v-show="activeIndex === '4'">
      </PackagingRequirementsTwo>
    </div>

    <div style="height:160px;"></div>
    <div class="footer-bar">
      <div class="container">
        <div style="flex:1" class="text-center">
          <Button ghost type="primary" size="large" style="margin-left: 140px;" @click="up">上一步</Button>
          <Button v-if="currentStep < 4" ghost type="primary" size="large" style="margin-left: 20px;" @click="next">下一步</Button>
        </div>
        <div>
          <Button ghost type="primary" size="large" @click="saveDraft">保存草稿</Button>
          <Button type="primary" size="large" style="margin-left: 20px;" :loading="submit" @click="create(1)">生成方案</Button>
        </div>
      </div>
    </div>
  </page-view>
</template>

<script>
import PackagingRequirementsTwo from './PackagingRequirementsTwo'
import ProductInfo from './ProductInfo'
import LogisticsInformation from './LogisticsInformation'
import PackagingRequirements from './PackagingRequirements'
import { createNormalProductArrangement, createDraftProductArrangement, getSuggestionPx } from '@/api/packing'
import { mapState } from 'vuex'
import { omit } from 'loadsh'
import { Modal } from 'view-design'
import { Package } from '@/utils/mixins'
export default {
  mixins: [Package],
  components: {
    ProductInfo,
    LogisticsInformation,
    PackagingRequirements,
    PackagingRequirementsTwo
  },
  data () {
    return {
      loading: false,
      activeIndex: '1',
      total1: 6,
      total2: 2,
      total3: 4,
      total4: 2,
      pass1: 0,
      pass2: 0,
      pass3: 0,
      pass4: 0,
      form: {},
      submit: false
    }
  },
  computed: {
    isEdit () {
      return this.$route.name === 'OrdinaryPackage.edit'
    },
    isDrafts () {
      return this.$route.query.status === 0
    },
    ...mapState({
      OrdinaryPackage: state => state.OrdinaryPackage
    }),
    currentStep () {
      return this.activeIndex * 1
    },
    formData () {
      try {
        const { productInfo, logisticsInformation, packagingRequirements, packagingRequirements2, referenceInformation } = this.OrdinaryPackage
        const { sizeC, sizeK, sizeG } = productInfo || {}
        const { transport } = logisticsInformation || {}
        const { jubuC, jubuK, jubuG } = packagingRequirements2 || {}
        const { miaoshu, optimizes } = referenceInformation || {}
        let params = {
          ...productInfo,
          productSize: [sizeC, sizeK, sizeG].join('*'),
          ...logisticsInformation,
          modeTransport: transport,
          ...packagingRequirements,
          jubuOuterSize: [jubuC, jubuK, jubuG].join('*'),
          ...referenceInformation,
          miaoshu: miaoshu && miaoshu.join('*'),
          optimizes: optimizes && optimizes.join('*'),
          packageinfoSource: 2,
          ...packagingRequirements2
        }
        params = omit(params, ['isBag', 'packageinfoId'])
        return params
      } catch (error) {
        console.error(new Error(error))
        return {}
      }
    }
  },
  destroyed () {
    this.$store.commit('SetOrdinaryPackage', {})
  },
  async created () {
    if (this.isEdit) {
      this.loading = true
      await this.createFormData()
      this.loading = false
    }
  },
  methods: {
    tabClick (name) {
      return false
    },
    up () {
      if (this.currentStep === 1) {
        this.$router.go(-1)
      }
      this.activeIndex = parseInt(this.activeIndex) - 1 + ''
    },
    next () {
      if (this.currentStep === 1) {
        this.$refs.ProductInfo.validate().then(res => {
          this.activeIndex = parseInt(this.activeIndex) + 1 + ''
        }).catch(() => {
          this.$Modal.warning({ title: '提示', content: '请将信息填写完整' })
        })
      }
      if (this.currentStep === 2) {
        this.$refs.LogisticsInformation.validate().then(res => {
          this.activeIndex = parseInt(this.activeIndex) + 1 + ''
        }).catch(() => {
          this.$Modal.warning({ title: '提示', content: '请将信息填写完整' })
        })
      }
      if (this.currentStep === 3) {
        this.$refs.PackagingRequirements.validate().then(res => {
          this.activeIndex = parseInt(this.activeIndex) + 1 + ''
        }).catch(() => {
          this.$Modal.warning({ title: '提示', content: '请将信息填写完整' })
        })
      }
      if (this.currentStep === 4) {
        this.$refs.PackagingRequirementsTwo.validate().then(res => {
          this.activeIndex = parseInt(this.activeIndex) + 1 + ''
        }).catch((error) => {
          this.$Modal.warning({ title: '提示', content: error.message || '请将信息填写完整' })
        })
      }
    },
    back () {
      this.$Modal.confirm({
        title: '提示',
        content: '返回会取消当前包装方案，确认返回？?',
        onOk: () => {
          this.$router.go(-1)
        },
        onCancel: () => {
          // this.$Message.info('Clicked cancel')
        }
      })
    },
    saveDraft () {
      const api = this.isEdit && this.isDrafts ? createDraftProductArrangement : createDraftProductArrangement
      const data = {
        ...this.formData,
        status: 0,
        packageType: 2,
        sizeCode: 0
      }
      if (this.isEdit && this.isDrafts) data.packageinfoId = this.$route.query.packageinfoId
      api(data).then(res => {
        if (res.isSuccess) {
          this.$Message.success('保存草稿成功')
          this.$store.commit('SetOrdinaryPackage', {})
          this.$router.push({ name: 'Drafts' })
        } else {
          this.$Modal.error({ title: '提示', content: res.msg })
        }
      })
    },
    create (status) {
      Promise.all([this.$refs.ProductInfo.validate(), this.$refs.LogisticsInformation.validate(), this.$refs.PackagingRequirements.validate(), this.$refs.PackagingRequirementsTwo.validate()]).then(res => {
        console.log(res)
        if (status === 1) {
          this.$Modal.confirm({
            title: '提示',
            content: '确认提交方案吗?',
            onOk: () => {
              this.submitData(status)
            },
            onCancel: () => {
              // this.$Message.info('Clicked cancel')
            }
          })
        } else {
          this.submitData(status)
        }
      }).catch(err => {
        this.$Modal.warning({ title: '提示', content: err.message || '请将信息填写完整' })
        console.log(err)
      })
    },
    submitData (status = 1) {
      // const api = this.isEdit ? updateNormalPackageInfo : createNormalProductArrangement
      const data = {
        ...this.formData,
        status
      }
      if (this.isEdit) data.id = this.$route.query.packageinfoId
      this.submit = true
      createNormalProductArrangement(data).then(res => {
        if (res.isSuccess) {
          this.$Message.success(status ? '方案生成成功' : '保存草稿成功')
          this.$store.commit('SetOrdinaryPackage', {})
          this.$router.push({ name: status ? 'PackagingRecord' : 'Drafts' })
        } else {
          setTimeout(() => {
            this.submit = false
            Modal.error({ title: '提示', content: res.msg || '方案信息有误，请检查是否需要更改楞型或使用重型方案' })
          }, 1000)
        }
      })
    },
    getSuggestionPx (item, showMsg = false) {
      const data = {
        ...this.formData,
        lengxingId: item.lengxingId,
        status: 1
      }
      getSuggestionPx(data).then(res => {
        if (res.isSuccess) {
          if (showMsg) {
            Modal.info({
              title: '推荐参考值为：' + res.data
            })
          }
          this.$set(item, 'tjby', res.data)
        } else {
          setTimeout(() => {
            this.submit = false
            Modal.error({ title: '提示', content: '方案信息有误，请检查是否需要更改楞型或使用重型方案' })
          }, 1000)
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-bar {
    border-bottom: none;
}
/deep/ .ivu-btn-large {
    width: 120px;
}
.form-wrap {
    padding: 30px;
    min-height: 520px;
    background: #ffffff;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding-bottom: 80px;
}
</style>
