<template>
  <page-view>
    <Row :gutter="110" style="margin-top: 80px;">
      <Col :span="12">
      <div class="solution-box wow  animate__zoomIn" @click="$router.push({name:'OrdinaryPackage'})">
        <div class="solution-name">普通包装
          <Icon type="ios-arrow-forward" />
        </div>
        <div class="solution-meta">
          <div>
            <span>内装物品：</span>
            <span>质量&lt; 55kg</span>
          </div>
          <div>
            <span>综合尺寸：</span>
            <span> 长+宽+高&lt; 2500mm</span>
          </div>
          <div>
            <span>箱体材料：</span>
            <span>国产瓦楞纸</span>
          </div>
        </div>
        <div class="bg">
          <img src="@/assets/images/img_aiplan_01.png" alt="">
        </div>
      </div>
      </Col>
      <Col :span="12">
      <div class="solution-box wow  animate__zoomIn" @click="$router.push({name:'HeavyPackage'})">
        <div class="solution-name">重型包装
          <Icon type="ios-arrow-forward" />
        </div>
        <div class="solution-meta">
          <div>
            <span>内装物品：</span>
            <span>质量&gt; 55kg</span>
          </div>
          <div>
            <span>综合尺寸：</span>
            <span> 长+宽+高&gt; 2500mm</span>
          </div>
          <div>
            <span>箱体材料：</span>
            <span>瓦楞纸</span>
          </div>
        </div>
        <div class="bg">
          <img src="@/assets/images/img_aiplan_02.png" alt="">
        </div>
      </div>
      </Col>
    </Row>
    <Row :gutter="110" style="margin-top: 60px;">
      <Col :span="12">
      <div class="solution-box wow animate__zoomIn" @click="$router.push('/AiSolution/PackagingRecord')">
        <div class="solution-name">包装记录
          <Icon type="ios-arrow-forward" />
        </div>
        <div class="solution-meta">
          <div v-for="(item, index) in listData" :key="index">
            <span v-if="item.package_type == 2">普通包装：</span>
            <span v-if="item.package_type == 3">重型包装：</span>
            <span>{{item.count}}</span>
          </div>
          <template v-if="listData.length === 0">
            <div>
              <span>普通包装：</span>
              <span>0</span>
            </div>
            <div>
              <span>重型包装：</span>
              <span>0</span>
            </div>
          </template>

        </div>
        <div class="bg">
          <img src="@/assets/images/img_aiplan_03.png" alt="">
        </div>
      </div>
      </Col>
      <Col :span="12">
      <div class="solution-box wow  animate__zoomIn" @click="$router.push('/AiSolution/Drafts')">
        <div class="solution-name">草稿箱
          <Icon type="ios-arrow-forward" />
        </div>
        <div class="solution-meta">
          <div :key="index" v-for="(item,index) in caogaoData">
            <span v-if="item.package_type == 2">普通包装：</span>
            <span v-if="item.package_type == 3">重型包装：</span>
            <span>{{item.count}}</span>
          </div>
          <template v-if="caogaoData.length === 0">
            <div>
              <span>普通包装：</span>
              <span>0</span>
            </div>
            <div>
              <span>重型包装：</span>
              <span>0</span>
            </div>
          </template>
        </div>
        <div class="bg">
          <img src="@/assets/images/img_aiplan_04.png" alt="">
        </div>
      </div>
      </Col>
    </Row>
  </page-view>
</template>
<script>
import { WOW } from 'wowjs'
import { mapGetters } from 'vuex'
import { isEmpty } from '@/utils/util'
import { getPackageTypeCountById, getPackageTypeCountByIdAndStatus } from '@/api/packing'
export default {
  components: {
  },
  data () {
    return {
      listData: [],
      caogaoData: []
    }
  },
  computed: {
    ...mapGetters(['PackageAttribute', 'userInfo'])
    // isPerfect () {
    //   const { company, businessScope } = this.userInfo
    //   return company !== '' && businessScope !== ''
    // }
  },
  created () {
    // if (this.isPerfect) {
    //   this.$Modal.warning({
    //     title: '提示',
    //     content: '请先完善个人信息！',
    //     onOk: () => {
    //       this.$router.push('/usercenter')
    //     }
    //   })
    // }
    if (isEmpty(this.PackageAttribute)) {
      this.$store.dispatch('GET_PACKAGEATTR')
    }
    this.getPackageTypeCountById()
    this.getPackageTypeCountByIdAndStatus()
  },
  methods: {
    getPackageTypeCountById () {
      getPackageTypeCountById().then(res => {
        if (res.isSuccess) {
          this.listData = res.data
        } else {
          this.$Message.error(res.msg)
        }
      })
    },
    getPackageTypeCountByIdAndStatus () {
      getPackageTypeCountByIdAndStatus().then(res => {
        if (res.isSuccess) {
          this.caogaoData = res.data
        } else {
          this.$Message.error(res.msg)
        }
      })
    }
  },
  mounted () {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animate__animated',
      offset: 0,
      mobile: true
    })
    wow.init()
  }
}
</script>
<style lang="scss" scoped>
.solution-box {
    position: relative;
    width: 100%;
    height: 312px;
    padding: 40px 60px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    .bg {
        position: absolute;
        top: -65px;
        right: 0px;
    }
}
.solution-name {
    padding: 20px 0;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: -0.02em;
    color: #333333;
    border-bottom: 2px solid #67c5ec;
    .ivu-icon {
        color: #67c5ec;
    }
}

.solution-meta {
    margin-top: 20px;
    & > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #999999;
    }
}
</style>
