<template>
  <div>
    <Table border :data="priceListLight" :columns="[
        {
          title:'序号',
          key: 'index',
          width: 80,
          align: 'center',
          render: (h, params) => {
            //return h('span', params.row.index === '合计' ? '合计': params.row.index +''+ (params.index+1) )
            return h('span', params.row.index === '合计' ? '合计': (params.index+1) )
          }
        },
        {
          title: '名称',
          key: 'name',
          align: 'center',
          render: (h, params) => {
            return tableFmt(h, params, 'name');
          }
        },
        {
          title: '尺寸mm',
          key: 'chicun',
          align: 'center',
          render: (h, params) => {
            return tableFmt(h, params, 'chicun');
          }
        },
        {
          title: '材料',
          key: 'cailiao',
          align: 'center',
          render: (h, params) => {
            return tableFmt(h, params, 'cailiao');
          }
        },
        {
          title: '单价',
          key: 'unit',
          align: 'center',
          render: (h, params) => {
            return tableFmt(h, params, 'unit');
          }
        },
        {
          title: '数量',
          key: 'amount',
          align: 'center',
          render: (h, params) => {
            return tableFmt(h, params, 'amount');
          }
        },
        {
          title: '总价（元）',
          key: 'total',
          align: 'center',
          render: (h, params) => {
            return tableFmt(h, params, 'total');
          }
        }
      ]" style="width: 100%">
    </Table>
  </div>
</template>

<script>
import { listPalletBomInfo, getCustomMaterial } from '@/api/packing'
import { mapState } from 'vuex'
export default {
  inject: {
    attributeMap: {
      default: () => ({})
    }
  },
  data () {
    return {
      defaultPriceList: [
        {
          index: '物料',
          name: '',
          chicun: '',
          cailiao: '',
          unit: '',
          amount: '',
          total: ''
        },
        {
          index: '物料',
          name: '短刀卡',
          chicun: '',
          cailiao: '',
          unit: '',
          amount: '',
          total: ''
        },
        {
          index: '物料',
          name: '长刀卡',
          chicun: '',
          cailiao: '',
          unit: '',
          amount: '',
          total: ''
        },
        {
          index: '物料',
          name: '平卡',
          chicun: '',
          cailiao: '',
          unit: '',
          amount: '',
          total: ''
        },
        {
          index: '物料',
          name: '顶部---',
          chicun: '',
          cailiao: '',
          unit: '',
          amount: '',
          total: ''
        },
        {
          index: '物料',
          name: '高度四边---',
          chicun: '',
          cailiao: '',
          unit: '',
          amount: '',
          total: ''
        },

        {
          index: '物料',
          name: '底部---',
          chicun: '',
          cailiao: '',
          unit: '',
          amount: '',
          total: ''
        },
        {
          index: '物料',
          name: '局部---',
          chicun: '',
          cailiao: '',
          unit: '',
          amount: '',
          total: ''
        },
        {
          index: '物料',
          name: '内袋----',
          chicun: '',
          cailiao: '',
          unit: '',
          amount: '',
          total: ''
        },

        {
          index: '物料',
          name: '栈板',
          chicun: '',
          cailiao: '',
          unit: '',
          amount: '',
          total: ''
        }
      ],
      priceListLight: []
    }
  },
  computed: {
    ...mapState({
      packageInfo: state => state.packageInfo
    })
  },
  watch: {
    'packageInfo.packageinfo': {
      deep: true,
      handler: function () {
        this.assignPriceListLight(this.packageInfo)
      }
    }
  },
  async created () {
    this.assignPriceListLight(this.packageInfo)
    await this.getCustomMaterial()
    this.listPalletBomInfo()
  },
  methods: {
    listPalletBomInfo () {
      this.loading = true
      listPalletBomInfo({ packageInfoId: this.packageInfo.packageinfoId, bomType: 2 }).then(res => {
        if (res.code === 0) {
          this.loading = false
          this.zhanbanData = res.data
          this.zhanbanData.forEach(e => {
            this.priceListLight.push({
              index: '物料',
              name: e.bomName,
              unit: e.unitPrice,
              amount: e.bomAmount,
              total: e.totalPrice,
              chicun: e.bomLong + '*' + e.bomWidth + (e.bomHeight ? ('*' + e.bomHeight) : ''),
              cailiao: this.getName(e.bomMaterial)
            })
          })
          // copy
          const priceInfo = this.packageInfo.packageinfo.price || {}
          // 总计
          this.priceListLight.push({
            index: '合计',
            name: '',
            unit: '',
            amount: '',
            total: priceInfo.planTotalPrice,
            chicun: '',
            cailiao: ''
          })
        }
      })
    },
    async getCustomMaterial () {
      return getCustomMaterial().then(res => {
        if (res.code === 0) {
          this.customMaterialList = res.rows || []
        }
      })
    },
    getName (bomMaterial) {
      const obj = this.customMaterialList.find(e => e.materialId === bomMaterial)
      return obj && obj.materialName
    },
    assignPriceListLight (info) {
      // copy
      const prices = this.defaultPriceList.map((v) => ({ ...v }))
      const priceInfo = info.packageinfo.price || {}
      console.log(prices, priceInfo)
      console.log('heavy', info.packMaterialPlan)
      // 纸箱
      prices[0].name = '纸箱'
      prices[0].chicun = this.get(info, 'cartonSize', '').replace('尺寸1：', '')
      prices[0].cailiao = this.get(info, 'packMaterialPlan.lizhi.brand')
      prices[0].unit = priceInfo.paperboardUnitPrice
      prices[0].amount = priceInfo.paperboardAmount
      prices[0].total = priceInfo.paperboardTotalPrice
      // 短刀卡
      prices[1].unit = priceInfo.shortDaokaUnitPrice
      prices[1].amount = priceInfo.shortDaokaAmount
      prices[1].total = priceInfo.shortDaokaTotalPrice
      prices[1].chicun = info.packageinfo.auxiliary.shortDaokaLong + '*' + info.packageinfo.auxiliary.shortDaokaWidth
      prices[1].cailiao = this.$get(info, 'packageinfo.auxiliary.heavyDaokaMaterial.materialtypeName')
      // 长刀卡
      prices[2].unit = priceInfo.longDaokaUnitPrice
      prices[2].amount = priceInfo.longDaokaAmount
      prices[2].total = priceInfo.longDaokaTotalPrice
      prices[2].chicun = info.packageinfo.auxiliary.longDaokaLong + '*' + info.packageinfo.auxiliary.longDaokaWidth
      prices[2].cailiao = this.$get(info, 'packageinfo.auxiliary.heavyDaokaMaterial.materialtypeName')
      // 平卡
      prices[3].unit = priceInfo.pingkaUnitPrice
      prices[3].amount = priceInfo.pingkaAmount
      prices[3].total = priceInfo.pingkaTotalPrice
      prices[3].chicun = info.packageinfo.auxiliary.pingkaLong + '*' + info.packageinfo.auxiliary.pingkaWidth
      prices[3].cailiao = this.$get(info, 'packageinfo.auxiliary.heavyDaokaMaterial.materialtypeName')
      // 缓冲
      const huanchongInfo = info.packageinfo.auxiliary || {}
      const positions = ['dingbu', 'sibian', 'dibu', 'jubu']
      const pricePos = [4, 5, 6, 7]
      positions.forEach((pos, i) => {
        prices[pricePos[i]].name = prices[pricePos[i]].name.replace(
          /---/,
          this.getHCStructureLabel(huanchongInfo[`${pos}ProtectionMethod`], pos)
        )
        prices[pricePos[i]].unit = priceInfo[`${pos}MaterialUnitPrice`]
        prices[pricePos[i]].amount = priceInfo[`${pos}MaterialAmount`]
        prices[pricePos[i]].total = priceInfo[`${pos}MaterialTotalPrice`]
        prices[pricePos[i]].chicun = info.packageinfo.auxiliary[`${pos}OuterSize`]
        prices[pricePos[i]].cailiao = this.getProtectionMaterial(info.packageinfo.auxiliary[`${pos}Material`])
      })
      // 内袋
      const neidaiInfo = info.packageinfo.auxiliary || {}
      prices[8].name = this.getBagTypeLabel(neidaiInfo.bagType)
      prices[8].unit = priceInfo.bagUnitPrice
      prices[8].amount = priceInfo.bagAmount
      prices[8].total = priceInfo.bagTotalPrice
      prices[8].chicun = info.packageinfo.auxiliary.bagLong + '*' + info.packageinfo.auxiliary.bagWidth + '*' + info.packageinfo.auxiliary.bagHeight
      prices[8].cailiao = info.packageinfo.auxiliary.bagMaterial
      // 栈板
      prices[9].unit = priceInfo.palletUnitPrice
      prices[9].amount = priceInfo.palletAmount
      prices[9].total = priceInfo.palletTotalPrice
      if (info.packageinfo.pallet) {
        prices[9].chicun = info.packageinfo.pallet.palletLong + '*' + info.packageinfo.pallet.palletWidth + '*' + info.packageinfo.pallet.palletHeight
        prices[9].cailiao = this.getZhanbanMaterialLabel(info.packageinfo.pallet.palletMaterial)
      }
      // 总计
      // prices[10].total = priceInfo.planTotalPrice

      this.priceListLight = prices.filter(
        (v, i) => i === prices.length - 1 || (v.amount && v.amout !== 0)
      )
    },
    getZhanbanMaterialLabel (val) {
      switch (true) {
        case val === 1:
          return '桃花芯胶合板'
        case val === 2:
        default:
          return '原木'
      }
    },
    getBagTypeLabel (value) {
      return this.getLabelByValue(value, 'bag_type')
    },
    getProtectionMaterial (value) {
      return this.getLabelByValue(value, 'protection_material')
    },
    getLabelByValue (value, type) {
      let label = null

      this.attributeMap[type].forEach((v) => {
        if (v.attributeValue === value + '') {
          label = v.attributeLabel
        }
      })

      return label
    },
    getHCStructureLabel (value, position) {
      if (value == null || !this.attributeMap) {
        return null
      }
      let label = null

      this.attributeMap[`${position}_protection_structure`].forEach((v) => {
        if (v.attributeValue === value + '') {
          label = v.attributeLabel
        }
      })

      return label
    },
    tableFmt (h, params, key) {
      if (typeof params.row[key] === 'number') {
        return h('span', params.row[key])
      }
      return h('span', params.row[key] || '---')
    }
  }
}
</script>

<style lang="scss" scoped></style>
