<template>
  <Form :model="form" ref="Form" :rules="rules" label-position="top">
    <Row :gutter="50">
      <Col :span="8">
      <FormItem label="选择运输方式" prop="transport" ref="transport">
        <!-- <Checkbox-group v-model="form.transport">
          <Checkbox v-for="item in modeTransport" :label="item.attributeValue" :key="item.attributeLabel">{{item.attributeLabel}}
          </Checkbox>
        </Checkbox-group> -->
        <Radio-group v-model="form.transport">
          <Radio v-for="item in modeTransport" :label="item.attributeValue" :key="item.attributeLabel">{{item.attributeLabel}}
          </Radio>
        </Radio-group>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem label="运输目的地" prop="destination" ref="destination">
        <Radio-group v-model="form.destination">
          <Radio :label="1">国内</Radio>
          <Radio :label="0">国外</Radio>
          <Radio :label="2">多个目的地</Radio>
        </Radio-group>
        <!-- <Select v-model="form.destination" placeholder="目的地">
          <Option :value="1">国内</Option>
          <Option :value="0">国外</Option>
          <Option :value="2">多个目的地</Option>
        </Select> -->
      </FormItem>
      </Col>
      <Col :span="8" v-if="form.transport">
      </Col>
      <!-- <Col :span="8" v-if="form.transport.includes('1') || form.transport.includes('2')"> -->
      <Col :span="8" v-if="['1', '2'].includes(form.transport)">
      <FormItem label="货柜尺寸" prop="containerSize" ref="containerSize" :rules="[{required:true,message:'请选择货柜尺寸'}]">
        <Select v-model="form.containerSize" placeholder="请选择货柜尺寸">
          <Option :value="1">20尺标柜（内:5.898×2.352×2.385 m 限重：17.5t）</Option>
          <Option :value="2">20尺开顶柜（内:5.898×2.352×2.342 m 限重：20t）</Option>
          <Option :value="4">40尺标柜（内:12.032×2.352×2.385 m 限重：22t）</Option>
          <Option :value="6">40尺开顶柜（内:12.034×2.352×2.330 m 限重：30.4t）</Option>
          <Option :value="5">40尺高柜（内:12.032×2.352×2.69 m 限重：29t）</Option>
          <Option :value="8">45尺高柜（内:13.556×2.352×2.698 m 限重：29t）</Option>
        </Select>
      </FormItem>
      </Col>
      <!-- <Col :span="8" v-if="form.transport.length > 0"> -->
      <Col :span="8" v-if="['1', '2', '3'].includes(form.transport)">
      <FormItem label="静态堆码高度（mm）" prop="staticStackHeight" key="staticStackHeight">
        <template #label>
          静态堆码高度（mm）
          <Tooltip content="仓储堆叠高度" placement="top">
            <div href="" class="ico-tips">
              <Icon type="ios-alert" style="color:#67c5ec" />
            </div>
          </Tooltip>
        </template>
        <int-input v-model="form.staticStackHeight" :default-value="form.staticStackHeight" placeholder="请输入静态堆码高度">
        </int-input>
      </FormItem>
      </Col>
      <Col :span="8" v-if="['1', '2', '3'].includes(form.transport)">
      <FormItem label="动态堆码高度（mm）" key="dynamicStackHeight" prop="dynamicStackHeight" ref="dynamicStackHeight"
                :rules="[{required: true , message: '请输入动态堆码高度'}]">
        <template #label>
          动态堆码高度（mm）
          <Tooltip content="运输堆叠高度" placement="top">
            <div href="" class="ico-tips">
              <Icon type="ios-alert" style="color:#67c5ec" />
            </div>
          </Tooltip>
        </template>
        <int-input v-model="form.dynamicStackHeight" :default-value="form.dynamicStackHeight" placeholder="请输入动态堆码高度">
        </int-input>
        <p class="input-help" v-if="form.transport === '3'">不确定可设置为1600mm</p>
        <template v-else>
          <p class="input-help" v-if="[2].includes(form.containerSize)">推荐堆码高度为2342mm</p>
          <p class="input-help" v-else-if="[6].includes(form.containerSize)">推荐堆码高度为2330mm</p>
          <p class="input-help" v-else-if="[5,8].includes(form.containerSize)">高柜推荐堆码高度为2690mm</p>
          <p class="input-help" v-else-if="[1,4].includes(form.containerSize)">推荐堆码高度为2385mm</p>
        </template>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem v-if="['1', '2', '3'].includes(form.transport)" label="是否对位堆码" prop="isStacking" ref="isStacking">
        <template #label>
          是否对位堆码
          <Tooltip placement="top">
            <div href="" class="ico-tips">
              <Icon type="ios-alert" style="color:#67c5ec" />
            </div>
            <div slot="content">
              <p>对位堆码：相同包装上下</p>
              <p>堆叠整齐，堆叠方向一致。</p>
            </div>
          </Tooltip>
        </template>
        <Radio-group v-model="form.isStacking">
          <Radio :label="1">是</Radio>
          <Radio :label="0">否</Radio>
        </Radio-group>
        <!-- <Select v-model="form.isStacking" placeholder="请选择" clearable>
          <Option :value="1">对位堆码</Option>
          <Option :value="0">
            非对位堆码
          </Option>
        </Select> -->
      </FormItem>
      </Col>
      <!-- <Col :span="8">
      <FormItem v-if="['1', '2', '3'].includes(form.transport)" label="是否循环使用包装" prop="isCycle" ref="isCycle"
                :rules="[{required: true , message: '请选择是否循环使用包装'}]">
        <Radio-group v-model="form.isCycle">
          <Radio :label="1">是</Radio>
          <Radio :label="0">否</Radio>
        </Radio-group>
      </FormItem>
      </Col> -->
      <!-- <Col :span="8">
      <FormItem label="循环次数" prop="cartonCycleNum" ref="cartonCycleNum">
        <int-input v-model="form.cartonCycleNum" :default-value="form.cartonCycleNum" placeholder="请输入纸箱循环使用次数"></int-input>
      </FormItem>
      </Col> -->

    </Row>
  </Form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Package } from '@/utils/mixins'
export default {
  mixins: [Package],
  data () {
    return {
      form: {
        cartonCycleNum: 0,
        transport: null
      },
      rules: {
        transport: [
          {
            required: true, message: '请选择运输方式'
          }
        ],
        destination: [
          {
            required: true, message: '请选择运输目的地'
          }
        ],
        // staticStackHeight: [
        //   {
        //     required: true, message: '请输入静态堆码高度'
        //   }
        // ],
        // dynamicStackHeight: [
        //   {
        //     required: true, message: '请输入动态堆码高度'
        //   }
        // ],
        isStacking: [
          {
            required: true, message: '请选择对位堆码'
          }
        ]
        // cartonCycleNum: [
        //   {
        //     required: true, message: '请输入纸箱循环使用次数'
        //   }
        // ]
      }
    }
  },
  computed: {
    ...mapGetters(['PackageAttribute']),
    ...mapState({
      OrdinaryPackage: state => state.OrdinaryPackage
    }),
    modeTransport () {
      return this.$get(this.PackageAttribute, ['mode_transport'], [])
    }
  },
  watch: {
    'form.transport': function (newval) {
      if (newval === '3') {
        this.form.containerSize = ''
      }
    },
    form: {
      deep: true,
      handler: function () {
        this.$store.commit('SetOrdinaryPackage', { ...this.OrdinaryPackage, logisticsInformation: this.form })
        this.computedCount()
      }
    }
  },
  mounted () {
    if (this.OrdinaryPackage && this.OrdinaryPackage.logisticsInformation) {
      this.form = Object.assign({}, this.form, this.OrdinaryPackage.logisticsInformation)
      this.$nextTick(() => {
        this.computedCount()
      })
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.Form.validate(valid => {
          if (valid) {
            resolve(valid)
          }
          reject(valid)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-form-item {
    margin-bottom: 40px;
}
/deep/ .ivu-radio-wrapper {
    margin-right: 20px;
}
</style>
