<template>
  <Form :model="form" ref="Form" :rules="rules" label-position="top">
    <FormItem label="产品装箱方式" prop="isRegularPlace" ref="isRegularPlace">
      <template #label>
        产品装箱方式
      </template>
      <RadioGroup v-model="form.isRegularPlace">
        <Radio :label="1">
          产品规则摆放 <Tooltip placement="top">
            <div href="" class="ico-tips">
              <Icon type="ios-alert" style="color:#67c5ec" />
            </div>
            <div slot="content">
              <p>单个产品装箱或多个<br>产品按照长宽高有序摆放</p>
            </div>
          </Tooltip>
        </Radio>
        <Radio :label="2">
          产品不规则摆放 <Tooltip placement="top">
            <div href="" class="ico-tips">
              <Icon type="ios-alert" style="color:#67c5ec" />
            </div>
            <div slot="content">
              <p>多个产品装箱时无<br>序摆放或参差摆放</p>
            </div>
          </Tooltip>
        </Radio>
      </RadioGroup>
    </FormItem>
    <Row :gutter="40">
      <Col :span="8" v-if="form.isRegularPlace === 1">
      <FormItem label="产品摆放方式" prop="productNumLong" ref="productNumLong" :rules="moreRules.rulesProductNumLong">
        <div class="flex">
          <int-input v-model.number="form.productNumLong" :default-value="form.productNumLong" placeholder="长方向数量">
          </int-input>
          <int-input v-model.number="form.productNumWidth" :default-value="form.productNumWidth" placeholder="宽方向数量"
                     style="margin-left:12px">
          </int-input>
          <int-input v-model.number="form.productNumHeight" :default-value="form.productNumHeight" placeholder="高方向数量"
                     style="margin-left:12px">
          </int-input>
        </div>
        <p class="input-help">说明：长*宽*高=每箱包装产品数量</p>
      </FormItem>
      </Col>
      <Col :span="8" v-if="form.isRegularPlace === 1">
      <FormItem label="产品摆放间隙（mm）" prop="gap" ref="gap" :rules="moreRules.rulesProductGap">
        <div class="flex is-success">
          <int-input v-model.number="form.gap" :default-value="form.gap" placeholder="长度"></int-input>
          <int-input v-model.number="form.gapWidth" :default-value="form.gapWidth" placeholder="宽度" style="margin-left:12px">
          </int-input>
          <int-input v-model.number="form.gapHeight" :default-value="form.gapHeight" placeholder="高度" style="margin-left:12px">
          </int-input>
        </div>
      </FormItem>
      </Col>
      <Col :span="8">
      <FormItem v-if="form.isRegularPlace === 1 || form.isRegularPlace === 2" label="包装箱内尺寸（mm）" prop="innerLong"
                :ref="form.isRegularPlace === 2 ? 'innerLong' : 'FormLi'" :required="form.isRegularPlace === 2"
                :rules="moreRules.innerLong">
        <div class="flex">
          <int-input v-model.number="form.innerLong" :default-value="form.innerLong" placeholder="长度"
                     :disabled="form.isRegularPlace === 1">
          </int-input>
          <int-input v-model.number="form.innerWidth" :default-value="form.innerWidth" placeholder="宽度"
                     :disabled="form.isRegularPlace === 1" style="margin-left:12px">
          </int-input>
          <int-input v-model.number="form.innerHeight" :default-value="form.innerHeight" placeholder="高度"
                     :disabled="form.isRegularPlace === 1" style="margin-left:12px">
          </int-input>
        </div>
      </FormItem>
      </Col>
    </Row>
    <div class="panel-danger">
      <Collapse value="1">
        <Panel name="1">
          推荐方案1
          <p slot="content">
            <Row :gutter="40">
              <Col :span="8">
              <FormItem label="与箱型匹配的托盘信息" prop="needPallet" ref="needPallet">
                <RadioGroup v-model="form.needPallet">
                  <Radio :label="1">需要</Radio>
                  <Radio :label="0">不需要</Radio>
                </RadioGroup>
                <template #label>
                  与箱型匹配的托盘信息
                  <Tooltip placement="top">
                    <div href="" class="ico-tips">
                      <Icon type="ios-alert" style="color:#67c5ec" />
                    </div>
                    <div slot="content">
                      <p>用于转移货物的可移动的承载物。</p>
                    </div>
                  </Tooltip>
                </template>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem v-if="form.needPallet === 1" label="托盘材料" ref="palletMaterial" prop="palletMaterial"
                        :rules="[{required:true,message:'选择托盘材料'}]">
                <Select v-model="form.palletMaterial" placeholder="请选择托盘材料" :default-value="form.palletMaterial">
                  <Option value="1">桃花芯胶合板（国内外均适合使用）</Option>
                  <!-- <Option value="2">原木（仅适合国内使用，不支持出口）</Option> -->
                </Select>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem v-if="form.needPallet === 1 && form.palletMaterial" label="进叉方向"
                        :ref="form.palletMaterial !== '1'?'forkDirection':'FormforkDirection'" prop="forkDirection"
                        :rules="[{required:form.palletMaterial !== '1',message:'请选择进叉方向',trigger: 'change'}]">
                <Select v-model="form.forkDirection" placeholder="请选择" :disabled="form.palletMaterial === '1'">
                  <Option value="2">双向进叉</Option>
                  <Option value="1">四向进叉</Option>
                </Select>
              </FormItem>
              </Col>
            </Row>
            <Row :gutter="40">
              <Col :span="8">
              <FormItem label="推荐箱型" prop="cartonType" ref="cartonType">
                <Select v-model="form.cartonType" placeholder="请选择箱型" @on-change="onChange">
                  <Option v-for="item in boxTypeList" :key="item.id" :value="item.id">{{item.boxName}}</Option>
                </Select>
                <!-- <p class="input-help"><a href="javascript:;" @click.prevent="$refs.FormCartonTypeModal.show()">查看更多箱型</a></p> -->
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem label="推荐箱体材料" prop="heavyMaterialId1" ref="heavyMaterialId1">
                <!-- <select-dict v-model="form.lengxingId" :default-value="form.lengxingId" placeholder="选择箱体材料" type="lengxing">
                </select-dict> -->
                <Select v-model="form.heavyMaterialId1" placeholder="请选择箱体材料">
                  <Option v-for="item in heavyMaterialList" :key="item.materialtypeId" :value="item.materialtypeId">
                    {{item.materialtypeName}}</Option>
                </Select>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem label="推荐盖子材料" prop="heavyMaterialId2"
                        :ref="form.isWithCover ? 'heavyMaterialId2' :'FormheavyMaterialId2'" :rules="[
          {
            required: form.isWithCover,
            message: '请选择推荐盖子材料'
          }
        ]">
                <!-- <select-dict v-model="form.lengxingId" :default-value="form.lengxingId" placeholder="选择盖子材料" type="lengxing">
                </select-dict> -->
                <Select v-model="form.heavyMaterialId2" placeholder="请选择盖子材料">
                  <!-- <Option value="2C500">2C500</Option>
                  <Option value="2C850">2C850</Option>
                  <Option value="3C1100">3C1100</Option>
                  <Option value="3C1200">3C1200</Option>
                  <Option value="3A1300加强">3A1300加强</Option> -->
                  <template v-if="form.isWithCover">
                    <Option v-for="item in heavyMaterialList" :key="item.materialtypeId" :value="item.materialtypeId">
                      {{item.materialtypeName}}</Option>
                  </template>
                </Select>
              </FormItem>
              </Col>
            </Row>

          </p>
        </Panel>
      </Collapse>
    </div>
    <div class="panel-primary" style="margin-top:20px;" v-for="(scheme,index) in form.customScheme" :key="index">
      <Collapse :value="'index'">
        <Panel :name="'index'">
          <span>
            自定义方案{{index+1}}
            <span style="float:right;margin-right:20px;">
              <Button shape="circle" size="small" style="width:100px;" @click="removeScheme(index)"><span
                      style="font-size:12px!important;color:#67C5EC">删除方案</span></Button>
            </span>
          </span>
          <p slot="content">
            <Row :gutter="40">
              <Col :span="8">
              <FormItem label="自定义箱型" :prop="'customScheme.'+index+'.cartonType'" :ref="'customScheme.'+index+'.cartonType'"
                        :rules="[{required:true,message:'请选择箱型'}]">
                <template #label>
                  自定义箱型<span class="input-help" style="position: absolute;right:20px;top:0px"><a href="javascript:;"
                       @click.prevent="$refs.FormCartonTypeModal.show()" style="text-decoration: underline;">查看更多箱型</a></span>
                </template>
                <Select v-model="scheme.cartonType" placeholder="请选择箱型"
                        @on-change="value => handleCustomCartonTypeChange(value,index)">
                  <Option v-for="item in boxTypeList" :key="item.id" :value="item.id">{{item.boxName}}</Option>
                </Select>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem label="箱体材料" :prop="'customScheme.'+index+'.heavyMaterialId1'"
                        :ref="'customScheme.'+index+'.heavyMaterialId1'" :rules="[{required:true,message:'请选择箱体材料'}]">
                <!-- <select-dict v-model="scheme.lengxingId" :default-value="form.lengxingId" placeholder="选择箱体材料" type="lengxing">
                </select-dict> -->
                <Select v-model="scheme.heavyMaterialId1" placeholder="请选择箱体材料">
                  <Option v-for="item in heavyMaterialList" :key="item.materialtypeId" :value="item.materialtypeId">
                    {{item.materialtypeName}}</Option>
                </Select>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem label="盖子材料" :prop="'customScheme.'+index+'.heavyMaterialId2'"
                        :ref="scheme.isWithCover ? 'customScheme.'+index+'.heavyMaterialId2':'FormcustomScheme.'+index+'.heavyMaterialId2'"
                        :rules="[{required:scheme.isWithCover,message:'请选择盖子材料'}]">
                <Select v-model="scheme.heavyMaterialId2" placeholder="请选择盖子材料">
                  <!-- <Option value="2C500">2C500</Option>
                  <Option value="2C850">2C850</Option>
                  <Option value="3C1100">3C1100</Option>
                  <Option value="3C1200">3C1200</Option>
                  <Option value="3A1300加强">3A1300加强</Option> -->
                  <template v-if="scheme.isWithCover">
                    <Option v-for="item in heavyMaterialList" :key="item.materialtypeId" :value="item.materialtypeId">
                      {{item.materialtypeName}}</Option>
                  </template>
                </Select>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem label="与箱型匹配的托盘信息" :prop="'customScheme.'+index+'.needPallet'"
                        :rules="[{required:true,message:'请选择托盘信息'}]" :ref="'customScheme.'+index+'.needPallet'">
                <RadioGroup v-model="scheme.needPallet" @on-change="(val) => handleNeedPalletChange(val,index)">
                  <Radio :label="1">需要</Radio>
                  <Radio :label="0">不需要</Radio>
                </RadioGroup>
                <template #label>
                  与箱型匹配的托盘信息
                  <Tooltip placement="top">
                    <div href="" class="ico-tips">
                      <Icon type="ios-alert" style="color:#67c5ec" />
                    </div>
                    <div slot="content">
                      <p>用于转移货物的可移动的承载物。</p>
                    </div>
                  </Tooltip>
                </template>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem v-if="scheme.needPallet === 1" label="托盘材料" ref="palletMaterial"
                        :prop="'customScheme.'+index+'.palletMaterial'" :rules="[{required:true,message:'选择托盘材料'}]">
                <Select v-model="scheme.palletMaterial" placeholder="请选择托盘材料" :default-value="form.palletMaterial"
                        @on-change="(val) => handleChange(val,index)">
                  <Option :value="1">桃花芯胶合板（国内外均适合使用）</Option>
                  <!-- <Option :value="2">原木（仅适合国内使用，不支持出口）</Option> -->
                </Select>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem v-if="scheme.needPallet === 1 && scheme.palletMaterial" label="进叉方向"
                        :ref="scheme.palletMaterial !== 1?'forkDirection':'FormforkDirection'"
                        :prop="'customScheme.'+index+'.forkDirection'"
                        :rules="[{required:scheme.palletMaterial !== 1,message:'请选择进叉方向'}]">
                <Select v-model="scheme.forkDirection" placeholder="请选择" :disabled="scheme.palletMaterial === 1">
                  <Option :value="2">双向进叉</Option>
                  <Option :value="1">四向进叉</Option>
                </Select>
              </FormItem>
              </Col>
            </Row>
          </p>
        </Panel>
      </Collapse>
    </div>
    <div class="addbox" v-if="form.customScheme.length < 4">
      <Button type="primary" @click="addScheme">
        <Icon type="ios-add" /> 添加自定义方案
      </Button>
      <p stye="color:#999999;margin-top:20px;">
        注：系统将根据以上信息为您生成1-2种箱型结构一致但材料不同的方案，您可以改变箱型或材料继续添加0-{{4-form.customScheme.length}}条自定义方案，生成结果时将对方案进行对比参照。</p>
    </div>

    <!-- <Col :span="20">注：根据以上信息，将产生1～2种，箱型结构相同但材料不同的方案，针对这些方案，您可以改变箱型，或者材料产生新的方案，如果需要，请按照提示选择，是否需要生成新的方案
    <RadioGroup v-model="form.needNewScheme">
      <Radio :label="1">需要</Radio>
      <Radio :label="0">不需要</Radio>
    </RadioGroup>
    </Col>
    <Col :span="12">
    <RadioGroup v-if="form.needNewScheme === 1" v-model="form.whatToChange">
      <Radio :label="1">仅改变箱型</Radio>
      <Radio :label="2">仅改变材料</Radio>
      <Radio :label="3">箱型与材料同时改变</Radio>
    </RadioGroup>
    </Col> -->

    <CartonTypeModal ref="FormCartonTypeModal" @select="handleSelect"></CartonTypeModal>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import { Package } from '@/utils/mixins'
import { getBoxTypeList, getHeavyMaterialList } from '@/api/packing'
import CartonTypeModal from '../components/CartonTypeModal.vue'
import { isNumber } from 'loadsh'
export default {
  mixins: [Package],
  components: {
    CartonTypeModal
  },
  data () {
    const validateLi = (rule, value, callback) => {
      if (this.form.isRegularPlace === 2) {
        const { innerLong, innerWidth, innerHeight } = this.form
        if (!innerLong || !innerWidth || !innerHeight) {
          callback(new Error('请输入内尺寸'))
        }
      }
      callback()
    }
    const validateProductNumLong = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入产品摆放方式'))
      } else {
        const { productNumLong, productNumWidth, productNumHeight } = this.form
        if (!productNumLong || !productNumWidth || !productNumHeight) {
          callback(new Error('请输入产品摆放方式'))
        }
        if (productNumLong * productNumWidth * productNumHeight !== this.packingAmout) {
          return callback(new Error('长宽高乘积必须等于每箱包装产品数量'))
        }
      }
      callback()
    }
    const validateGap = (rule, value, callback) => {
      if (!isNumber(value)) {
        callback(new Error('请输入产品摆放间隙'))
      } else {
        const { gap, gapWidth, gapHeight } = this.form
        if (!isNumber(gap) || !isNumber(gapWidth) || !isNumber(gapHeight)) {
          callback(new Error('请输入产品摆放间隙'))
        }
      }
      callback()
    }
    return {
      boxTypeList: [],
      heavyMaterialList: [],
      tempInfo: null,
      first: false,
      jsonString: '',
      form: {
        heavyMaterialId1: '',
        isWithCover: '',
        heavyMaterialId2: '',
        isRegularPlace: '',
        productNumLong: '',
        productNumWidth: '',
        productNumHeight: '',
        gap: '',
        gapWidth: '',
        gapHeight: '',
        innerLong: '',
        innerWidth: '',
        innerHeight: '',
        cartonType: '',
        lengxingId: '',
        needPallet: '',
        customScheme: [],
        palletMaterial: '',
        forkDirection: ''
        // isPart: 0,
        // isFlat: 0,
        // isCenter: 0
      },
      rules: {
        isRegularPlace: [
          {
            required: true,
            message: '请选择产品装箱方式'
          }
        ],
        heavyMaterialId1: [
          {
            required: true,
            message: '请选择推荐箱体材料'
          }
        ],
        heavyMaterialId2: [
          {
            required: true,
            message: '请选择推荐盖子材料'
          }
        ],
        cartonType: [
          {
            required: true,
            message: '请选择箱型'
          }
        ],
        needPallet: [
          { required: true, message: '选择与箱型匹配的托盘信息' }
        ]
      },
      moreRules: {
        rulesProductNumLong: [
          { required: true, validator: validateProductNumLong }
        ],
        rulesProductGap: [{ required: true, validator: validateGap }],
        innerLong: [{ validator: validateLi }]
      }
    }
  },
  computed: {
    ...mapState({
      HeavyPackage: state => state.HeavyPackage
    }),
    isEdit () {
      return this.$route.name === 'HeavyPackage.edit'
    },
    productInfo () {
      const { productInfo } = this.HeavyPackage || {}
      return productInfo || {}
    },
    logisticsInformation () {
      const { logisticsInformation } = this.HeavyPackage || {}
      return logisticsInformation || {}
    },
    destination () {
      return this.$get(this.logisticsInformation, 'destination')
    },
    productValue () {
      return this.$get(this.productInfo, 'productValue')
    },
    isStacking () {
      return this.$get(this.logisticsInformation, 'isStacking')
    },
    isCycle () {
      return this.$get(this.logisticsInformation, 'isCycle')
    },
    packingAmout () {
      const { productInfo } = this.HeavyPackage || {}
      return this.$get(productInfo, 'packingAmout', 0)
    }
  },
  watch: {
    destination: function (newval, oldval) {
      if (this.form.needPallet === 1) {
        if (newval === 1) {
          this.form.palletMaterial = '2'
        } else {
          this.form.palletMaterial = '1'
          this.form.forkDirection = '1'
        }
      }
    },
    'form.isBag': function (newval) {
      if (newval === '0') {
        this.form.bagFunction = ''
        this.form.bagMaterial = ''
        this.form.bagType = ''
        this.form.bagThickness = ''
      }
    },
    'form.bagFunction': function (newval) {
      if (newval === '1') {
        this.form.bagMaterial = 'PE袋，LLDPE'
        this.form.bagThickness = '8'
      }
      if (newval === '2') {
        this.form.bagMaterial = 'VCI气象防锈袋'
        this.form.bagThickness = '8'
      }
    },
    'form.palletMaterial': function (newval) {
      if (newval === '1') {
        this.form.forkDirection = '1'
      } else {
        // this.form.forkDirection = ''
      }
    },
    'form.needPallet': function (newval) {
      if (newval === 0) {
        this.form.forkDirection = ''
        this.form.palletMaterial = ''
      } else {
        this.form.palletMaterial = '1'
      }
      this.recommendMaterial()
    },
    'form.isRegularPlace': function (newval) {
      if (newval === 2) {
        this.form.productNumLong = ''
        this.form.productNumWidth = ''
        this.form.productNumHeight = ''
        this.form.gap = ''
        this.form.gapWidth = ''
        this.form.gapHeight = ''
      } else {
        this.form.innerLong = ''
        this.form.innerWidth = ''
        this.form.innerHeight = ''
      }
    },
    'form.productNumLong': function () {
      this.setBoxSize()
    },
    'form.productNumWidth': function () {
      this.setBoxSize()
    },
    'form.productNumHeight': function () {
      this.setBoxSize()
    },
    'form.gap': function () {
      this.setBoxSize()
    },
    'form.gapWidth': function () {
      this.setBoxSize()
    },
    'form.gapHeight': function () {
      this.setBoxSize()
    },
    'form.innerLong': function () {
      this.recommendBoxType()
    },
    'form.innerWidth': function () {
      this.recommendBoxType()
    },
    'form.innerHeight': function () {
      this.recommendBoxType()
    },
    form: {
      deep: true,
      handler: function (newval) {
        if (JSON.stringify(newval) !== this.jsonString) this.first = false
        this.$store.commit('SetHeavyPackage', { ...this.HeavyPackage, packagingRequirements: this.form })
        this.setBoxSize()
        this.computedCount()
      }
    },
    'form.cartonType': function () {
      this.recommendMaterial()
    },
    productValue: function (newval) {
      this.recommendMaterial()
    },
    isStacking: function (newval) {
      this.recommendMaterial()
    },
    isCycle: function (newval) {
      this.recommendMaterial()
    }
  },
  created () {
    this.first = true
  },
  async mounted () {
    await this.getBoxTypeList()
    await this.getHeavyMaterialList()
    if (this.HeavyPackage && this.HeavyPackage.packagingRequirements) {
      this.form = Object.assign({}, this.form, this.HeavyPackage.packagingRequirements)
      this.jsonString = JSON.stringify(this.form)
      this.onChange(this.form.cartonType)
      this.form.customScheme.forEach((e, index) => {
        this.handleCustomCartonTypeChange(e.cartonType, index)
      })
    }
    this.$nextTick(() => {
      this.computedCount()
    })
  },
  methods: {
    onChange (value) {
      const boxType = this.boxTypeList.find(e => e.id === value)
      const { isWithCover } = boxType || {}
      this.form.isWithCover = isWithCover
    },
    recommendBoxType () {
      if (this.first) return
      const { productInfo } = this.HeavyPackage
      const { sizeC, sizeK, sizeG, productWeight } = productInfo || {}
      // const { productNumLong, productNumWidth, productNumHeight } = this.form
      let type = ''
      const setType = val => {
        type = type || val
      }

      // const { sizeC, sizeK, sizeG, productWeight } = productInfo || {}
      if (!sizeC || !sizeK || !sizeG || !productWeight) {
        return ''
      }
      // 计算纸箱内长度
      // const weight =
      //   productNumLong * productNumWidth * productNumHeight * productWeight
      const Li = this.form.innerLong
      const Wi = this.form.innerWidth
      const Hi = this.form.innerHeight
      // const length = Li + Wi + Hi
      // if (weight <= 10 && length <= 1000) {
      //   this.form.lengxingId = 1
      // } else if (weight <= 40 && length <= 2000) {
      //   this.form.lengxingId = 7
      // } else if (weight <= 55 && length <= 2000) {
      //   this.form.lengxingId = 10
      // }
      if (Hi < 150 && Hi > 70) {
        setType('0300型天地盒')
        this.form.cartonType = 12
      }

      if (Li > 600 && Wi > 600 && Hi > 150 && Hi < 300) {
        setType('0306型天盖大地盒')
        this.form.cartonType = 13
      }

      if (Li > 2 * Wi && Wi < 450 && Hi > 800) {
        setType('0203型全页箱')
        this.form.cartonType = 11
      }

      if (Hi < 800) {
        setType('0201型半页箱')
        this.form.cartonType = 10
      }

      if (Hi > 800) {
        setType('围框天地盖')
        this.form.cartonType = 23
      }
      this.onChange(this.form.cartonType)
      this.recommendMaterial()
      // return `（推荐选择${type}）`
    },
    recommendMaterial () {
      if (this.first) return
      try {
        this.form.heavyMaterialId1 = ''
        this.form.heavyMaterialId2 = ''

        // 堆码情况（动载2层或2层以上），但不需要周转，根据堆码抗压公斤数（动载量级）在纸箱材料表中选择对应的材料，并且查找相应的材料厚度
        const T = 10
        const { innerHeight, needPallet } = this.form

        let H = innerHeight + 2 * T
        if (needPallet) H = H + 135
        const { dynamicStackHeight } = this.logisticsInformation
        const { weight } = this.productInfo
        const n = parseInt(dynamicStackHeight / H)
        const G = n === 1 ? weight : weight * (n - 1)
        const isStacking = n >= 2
        // 不周转不堆码的情况，根据货值选材
        if (!isStacking && this.form.cartonType) {
          const productValue = this.productValue
          if (productValue < 10000) {
            this.form.heavyMaterialId1 = 2
            this.form.heavyMaterialId2 = 2
          } else if (productValue < 50000) {
            this.form.heavyMaterialId1 = 3
            this.form.heavyMaterialId2 = 3
          } else if (productValue < 100000) {
            this.form.heavyMaterialId1 = 4
            this.form.heavyMaterialId2 = 4
          } else if (productValue < 5 * 100000) {
            this.form.heavyMaterialId1 = 6
            this.form.heavyMaterialId2 = 6
          } else {
            this.form.heavyMaterialId1 = 7
            this.form.heavyMaterialId2 = 7
          }
        }

        if (isStacking && this.isCycle === 0) {
          // a.确定单托虚拟外尺寸（如果重量大于等于50kg，建议使用栈板）：
          // 无托盘，单托高：H’=h + 2T，T = 10，T为纸板厚度，h即纸箱内空高度Hi。
          // 有托盘，单托高：H’=h + 2T + 135，T = 10，T为纸板厚度, 135代表托盘高度（虚拟）。
          // b.动态堆码层数n = 动态堆码高度 / H’
          // c.计算动载量级：G = g * (n - 1)单位为（kg），g表示产品单位重量，n代表动态堆码层数，当n = 1的时候，动载量级按照1层计算
          if (n >= 2) {
            if (G < 100) {
              this.form.heavyMaterialId1 = 2
              this.form.heavyMaterialId2 = 2
            } else if (G < 250) {
              this.form.heavyMaterialId1 = 3
              this.form.heavyMaterialId2 = 3
            } else if (G < 350) {
              this.form.heavyMaterialId1 = 4
              this.form.heavyMaterialId2 = 4
            } else if (G < 400) {
              this.form.heavyMaterialId1 = 5
              this.form.heavyMaterialId2 = 5
            } else if (G < 700) {
              this.form.heavyMaterialId1 = 6
              this.form.heavyMaterialId2 = 6
            } else if (G < 1200) {
              this.form.heavyMaterialId1 = 7
              this.form.heavyMaterialId2 = 7
            }
          }
        }

        if (isStacking && this.isCycle === 1) {
          // a.确定单托虚拟外尺寸（如果重量大于等于50kg，建议使用栈板）：
          // 无托盘，单托高：H’=h + 2T，T = 10，T为纸板厚度，h即纸箱内空高度Hi。
          // 有托盘，单托高：H’=h + 2T + 135，T = 10，T为纸板厚度, 135代表托盘高度（虚拟）。
          // b.动态堆码层数n = 动态堆码高度 / H’
          // c.计算动载量级：G = g * (n - 1)单位为（kg），g表示产品单位重量，n代表动态堆码层数，当n = 1的时候，动载量级按照1层计算
          if (n >= 2) {
            if (G < 100) {
              this.form.heavyMaterialId1 = 3
              this.form.heavyMaterialId2 = 3
            } else if (G < 250) {
              this.form.heavyMaterialId1 = 4
              this.form.heavyMaterialId2 = 4
            } else if (G < 350) {
              this.form.heavyMaterialId1 = 5
              this.form.heavyMaterialId2 = 5
            } else if (G < 400) {
              this.form.heavyMaterialId1 = 6
              this.form.heavyMaterialId2 = 6
            } else if (G < 700) {
              this.form.heavyMaterialId1 = 7
              this.form.heavyMaterialId2 = 7
            } else if (G < 1200) {
              this.form.heavyMaterialId1 = 7
              this.form.heavyMaterialId2 = 7
            }
          }
        }

        // 如果箱型选择围框天地盖或者无底（盖）半页箱+天地盒，那么天地盖的材料档次在箱体材料的基础上降一级，材料档次由低到高依次为：2C850,3C1100,3C1200,3C1400,3A1300加强,6C2000。例如：围框为3C1200、那么天地盖为3C1100：补充：如果箱体材料为2C850，则盖子也使用2C850
        const boxType = this.boxTypeList.find(e => e.id === this.form.cartonType)
        const { isWithCover, boxName } = boxType || {}
        if (this.form.heavyMaterialId1 !== 2 && (boxName.includes('0310') || boxName.includes('0312') || boxName.includes('0313') || boxName.includes('0325'))) {
          // this.form.heavyMaterialId1--
          this.form.heavyMaterialId2--
        }
        if (isWithCover === 0) {
          this.form.heavyMaterialId2 = ''
        }
      } catch (error) {
        console.error(error)
      }
    },
    setBoxSize () {
      const { isRegularPlace } = this.form
      if (isRegularPlace === 2) {
        return
      }
      const { productInfo } = this.HeavyPackage
      const { sizeC, sizeK, sizeG } = productInfo || {}
      const {
        productNumLong,
        productNumWidth,
        productNumHeight,
        gap,
        gapWidth,
        gapHeight
      } = this.form
      // 计算纸箱内长度
      let Li = 0
      let Wi = 0
      let Hi = 0

      Li = sizeC * productNumLong + gap * (productNumLong + 1)
      Wi = sizeK * productNumWidth + gapWidth * (productNumWidth + 1)
      Hi = sizeG * productNumHeight + gapHeight * productNumHeight
      // 在根据产品摆放后，得到的纸箱内尺寸的宽＞长，此时软件需自行判断，将原有的宽改为长，原有的长改为宽
      if (Wi > Li) {
        const w = Li
        Li = Wi
        Wi = w
      }
      this.form.innerLong = Li || ''
      this.form.innerWidth = Wi || ''
      this.form.innerHeight = Hi || ''
    },
    async getBoxTypeList () {
      return getBoxTypeList({ pageNum: 1, pageSize: 100, isRecommend: 0 }).then(res => {
        if (res.isSuccess) {
          this.boxTypeList = res.rows
        }
      })
    },
    async getHeavyMaterialList () {
      return getHeavyMaterialList({ pageNum: 1, pageSize: 100, isRecommend: 0 }).then(res => {
        if (res.isSuccess) {
          this.heavyMaterialList = res.rows
        }
      })
    },
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.Form.validate(valid => {
          if (valid) {
            resolve(valid)
          }
          reject(valid)
        })
      })
    },
    getProtectImg (type, pos = 'dingbu') {
      if (!type) {
        return ''
      }
      // 不保护 - 没图片
      if (type + '' === '0') {
        return ''
      }
      return `static/huanchong/${pos}_${type}.png`
    },
    preivewImg (type, pos = 'dingbu') {
      this.$refs[`Form${pos}${type}`][0].clickHandler()
    },
    handleSelect (item) {
      if (!this.boxTypeList.find(e => e.id === item.id)) {
        this.boxTypeList.push(item)
      }
      this.form.cartonType = item.id
      this.tempInfo = item
    },
    handleChange (value, index) {
      if (value === 1) {
        this.form.customScheme[index].forkDirection = 1
      }
    },
    handleNeedPalletChange (value, index) {
      if (value === 1) {
        this.form.customScheme[index].palletMaterial = 1
        this.form.customScheme[index].forkDirection = 1
      } else {
        this.form.customScheme[index].palletMaterial = ''
        this.form.customScheme[index].forkDirection = ''
      }
    },
    handleCustomCartonTypeChange (value, index) {
      const boxType = this.boxTypeList.find(e => e.id === value)
      const { isWithCover } = boxType || {}
      this.form.customScheme[index].isWithCover = isWithCover
    },
    addScheme () {
      const data = {
        cartonType: '',
        heavyMaterialId1: '',
        heavyMaterialId2: '',
        palletMaterial: '',
        needPallet: '',
        forkDirection: '',
        isWithCover: ''
      }
      this.form.customScheme.push(data)
    },
    removeScheme (index) {
      this.form.customScheme.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-form-item {
    // margin-bottom: 40px;
}
/deep/ .ivu-checkbox-wrapper {
    margin-right: 40px;
}
/deep/ .ivu-radio-wrapper {
    margin-right: 40px;
}
.addbox {
    border: 1px solid #67c5ec;
    border-radius: 4px;
    padding: 10px 20px;
    text-align: center;
    margin-top: 20px;
    p {
        margin-top: 10px;
        color: #999999;
    }
}
</style>
