import { render, staticRenderFns } from "./ProductInfoModal.vue?vue&type=template&id=ed85a5ca&scoped=true"
import script from "./ProductInfoModal.vue?vue&type=script&lang=js"
export * from "./ProductInfoModal.vue?vue&type=script&lang=js"
import style0 from "./ProductInfoModal.vue?vue&type=style&index=0&id=ed85a5ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed85a5ca",
  null
  
)

export default component.exports