import { mapState } from 'vuex'
import { omit } from 'loadsh'
import { getUserPackageHistoryRecordsAndPage } from '@/api/packing'
export const Package = {
  methods: {
    computedCount () {
      this.$nextTick(() => {
        let total = 0
        let keys = Object.keys(this.$refs)
        keys = keys.filter(e => !e.includes('Form') && this.$refs[e] !== undefined)

        keys.forEach(e => {
          console.log(this.$refs[e])
          const val = this.$get(this.form, e)
          if (val !== '' && val !== null && val !== undefined) {
            total++
          } else {
          }
        })
        this.$emit('update:total', keys.length)
        this.$emit('update:count', total)
      })
    },
    async createFormData () {
      const { packageinfoId, packageType, status } = this.$route.query
      const res = await getUserPackageHistoryRecordsAndPage({ packageInfoId: packageinfoId, pageIndex: 1, pageSize: 10, packageType, status })
      if (res.isSuccess) {
        const item = res.data[0] || {}
        const {
          productName,
          productSize,
          productValue,
          productType,
          productWeight,
          orderAmout,
          packageinfo,
          modeTransport,
          destination,
          weightspreading,
          cartonTypeValue: cartonType,
          optimize,
          miaoshu,
          chicun1,
          chicun2,
          chicun3,
          remarks,
          palletProgramme,
          daokaCailiao
        } = item
        const {
          packingAmout,
          productNumLong,
          productNumWidth,
          productNumHeight,
          gap,
          gapWidth,
          gapHeight,
          addAuxiliaryWeight,
          isliquid,
          staticStackHeight,
          dynamicStackHeight,
          cartonCycleNum,
          isStacking,
          isCycle,
          containerSize,
          lengxingId,
          needDaoka,
          needPallet,
          auxiliary,
          isCenter,
          isFlat,
          isPart,
          customScheme,
          isRegularPlace,
          cartonSizeSummary,
          palletBomInfo,
          heavyMaterialId1,
          heavyMaterialId2
        } = packageinfo || {}
        const { pallet } = packageinfo || {}
        const { palletMaterial, forkDirection } = pallet || {}
        const { bagType, bagMaterial, bagFunction, bagThickness, dingbuProtectionMethod, sibianProtectionMethod, dibuProtectionMethod, jubuProtectionMethod, dingbuMaterial, dibuMaterial, sibianMaterial, jubuMaterial, dingbuAmount, dibuAmount, sibianAmount, jubuAmount, dingbuThickness, dibuThickness, sibianThickness, jubuThickness, jubuOuterSize, otherMaterialName, otherMaterialLong, otherMaterialWidth, otherMaterialAmount, otherMaterial } = auxiliary || {}
        const [sizeC, sizeK, sizeG] = productSize.split('*')
        const [jubuC, jubuK, jubuG] = jubuOuterSize && jubuOuterSize.split('*')
        const { innerLong, innerWidth, innerHeight } = cartonSizeSummary || {}
        const productInfo = {
          productName,
          sizeC,
          sizeK,
          sizeG,
          productValue,
          productType: productType + '',
          productWeight,
          packingAmout,
          orderAmout,
          addAuxiliaryWeight,
          weightspreading,
          isliquid: isliquid,
          isCenter,
          isFlat
        }
        const logisticsInformation = {
          transport: modeTransport,
          destination,
          containerSize: containerSize * 1,
          isStacking,
          staticStackHeight,
          dynamicStackHeight,
          cartonCycleNum,
          isCycle
        }
        let packagingRequirements = {
          isRegularPlace: isRegularPlace || 1,
          productNumLong,
          productNumWidth,
          productNumHeight,
          gap,
          gapWidth,
          gapHeight,
          innerLong,
          innerWidth,
          innerHeight,
          cartonType: cartonType * 1,
          lengxingId,
          forkDirection: forkDirection ? forkDirection + '' : '',
          palletProgramme,
          needPallet,
          palletMaterial: palletMaterial ? palletMaterial + '' : '',
          isPart,
          customScheme: customScheme || []
        }
        const packagingRequirements2 = {
          needDaoka,
          daokaCailiao,
          bagThickness: bagThickness + '',
          bagType,
          bagMaterial,
          bagFunction,
          isBag: bagType ? '1' : '0',
          dingbuProtectionMethod,
          sibianProtectionMethod,
          dibuProtectionMethod,
          jubuProtectionMethod,
          dingbuMaterial,
          dibuMaterial,
          sibianMaterial,
          jubuMaterial,
          dingbuAmount,
          dibuAmount,
          sibianAmount,
          jubuAmount,
          dingbuThickness,
          dibuThickness,
          sibianThickness,
          jubuThickness,
          jubuC,
          jubuK,
          jubuG,
          otherMaterialName,
          otherMaterialLong,
          otherMaterialWidth,
          otherMaterialAmount,
          otherMaterial,
          palletBomInfo: palletBomInfo || []
        }
        const referenceInformation = {
          optimizes: optimize ? optimize.split('*') : [],
          remarks,
          miaoshu: miaoshu ? miaoshu.split('*') : [],
          chicun1,
          chicun2,
          chicun3
        }
        if (packageType === 2) {
          this.$store.commit('SetOrdinaryPackage', {
            productInfo,
            logisticsInformation,
            packagingRequirements,
            packagingRequirements2,
            referenceInformation
          })
        } else {
          packagingRequirements = {
            ...packagingRequirements,
            heavyMaterialId1,
            heavyMaterialId2
          }

          this.$store.commit('SetHeavyPackage', {
            productInfo,
            logisticsInformation,
            packagingRequirements,
            packagingRequirements2,
            referenceInformation
          })
        }
      }
    }
  }
}
export const PackageInfo = {
  watch: {
    packageInfo: {
      deep: true,
      handler: function () {
        this.updateData()
      }
    }
  },
  computed: {
    ...mapState({
      packageInfo: state => state.packageInfo,
      attributeMap: state => state.PackageAttribute
    }),
    createFormData () {
      const item = this.packageInfo
      const {
        productName,
        productSize,
        productValue,
        productType,
        productWeight,
        orderAmout,
        packageinfo,
        modeTransport,
        destination,
        weightspreading,
        cartonTypeValue: cartonType,
        optimize,
        miaoshu,
        chicun1,
        chicun2,
        chicun3,
        remarks,
        palletProgramme,
        daokaCailiao
      } = item
      const {
        packingAmout,
        productNumLong,
        productNumWidth,
        productNumHeight,
        gap,
        gapWidth,
        gapHeight,
        addAuxiliaryWeight,
        isliquid,
        staticStackHeight,
        dynamicStackHeight,
        cartonCycleNum,
        isStacking,
        isCycle,
        containerSize,
        lengxingId,
        needDaoka,
        needPallet,
        auxiliary,
        isCenter,
        isFlat,
        isPart,
        customScheme,
        isRegularPlace,
        cartonSizeSummary,
        palletBomInfo,
        heavyMaterialId1,
        heavyMaterialId2,
        packageType
      } = packageinfo || {}
      const { pallet } = packageinfo || {}
      const { palletMaterial, forkDirection } = pallet || {}
      const { bagType, bagMaterial, bagFunction, bagThickness, dingbuProtectionMethod, sibianProtectionMethod, dibuProtectionMethod, jubuProtectionMethod, dingbuMaterial, dibuMaterial, sibianMaterial, jubuMaterial, dingbuAmount, dibuAmount, sibianAmount, jubuAmount, dingbuThickness, dibuThickness, sibianThickness, jubuThickness, jubuOuterSize, otherMaterialName, otherMaterialLong, otherMaterialWidth, otherMaterialAmount, otherMaterial } = auxiliary || {}
      const [sizeC, sizeK, sizeG] = productSize.split('*')
      const [jubuC, jubuK, jubuG] = jubuOuterSize && jubuOuterSize.split('*')
      const { innerLong, innerWidth, innerHeight } = cartonSizeSummary || {}
      const productInfo = {
        productName,
        sizeC,
        sizeK,
        sizeG,
        productValue,
        productType: productType + '',
        productWeight,
        packingAmout,
        orderAmout,
        addAuxiliaryWeight,
        weightspreading,
        isliquid: isliquid,
        isCenter,
        isFlat
      }
      const logisticsInformation = {
        transport: modeTransport,
        destination,
        containerSize: containerSize * 1,
        isStacking,
        staticStackHeight,
        dynamicStackHeight,
        cartonCycleNum,
        isCycle
      }
      let packagingRequirements = {
        isRegularPlace: isRegularPlace || 1,
        productNumLong,
        productNumWidth,
        productNumHeight,
        gap,
        gapWidth,
        gapHeight,
        innerLong,
        innerWidth,
        innerHeight,
        cartonType: cartonType * 1,
        lengxingId,
        forkDirection: forkDirection + '',
        palletProgramme,
        needPallet,
        palletMaterial: palletMaterial + '',
        isPart,
        customScheme: customScheme || []
      }

      const packagingRequirements2 = {
        needDaoka,
        daokaCailiao,
        bagThickness: bagThickness + '',
        bagType,
        bagMaterial,
        bagFunction,
        isBag: bagType ? '1' : '0',
        dingbuProtectionMethod,
        sibianProtectionMethod,
        dibuProtectionMethod,
        jubuProtectionMethod,
        dingbuMaterial,
        dibuMaterial,
        sibianMaterial,
        jubuMaterial,
        dingbuAmount,
        dibuAmount,
        sibianAmount,
        jubuAmount,
        dingbuThickness,
        dibuThickness,
        sibianThickness,
        jubuThickness,
        jubuC,
        jubuK,
        jubuG,
        otherMaterialName,
        otherMaterialLong,
        otherMaterialWidth,
        otherMaterialAmount,
        otherMaterial,
        palletBomInfo: palletBomInfo || []
      }
      const referenceInformation = {
        optimizes: optimize ? optimize.split('*') : [],
        remarks,
        miaoshu: miaoshu ? miaoshu.split('*') : [],
        chicun1,
        chicun2,
        chicun3
      }
      if (packageType === 3) {
        packagingRequirements = {
          ...packagingRequirements,
          heavyMaterialId1,
          heavyMaterialId2
        }
      }
      return {
        productInfo,
        logisticsInformation,
        packagingRequirements,
        packagingRequirements2,
        referenceInformation
      }
    },
    formData () {
      try {
        const { productInfo, logisticsInformation, packagingRequirements, packagingRequirements2, referenceInformation } = this.createFormData
        const { sizeC, sizeK, sizeG } = productInfo || {}
        const { transport } = logisticsInformation || {}
        const { jubuC, jubuK, jubuG } = packagingRequirements2 || {}
        const { miaoshu, optimizes } = referenceInformation || {}
        let params = {
          ...productInfo,
          productSize: [sizeC, sizeK, sizeG].join('*'),
          ...logisticsInformation,
          modeTransport: transport,
          ...packagingRequirements,
          jubuOuterSize: [jubuC, jubuK, jubuG].join('*'),
          ...referenceInformation,
          miaoshu: miaoshu && miaoshu.join('*'),
          optimizes: optimizes && optimizes.join('*'),
          packageinfoSource: 2,
          ...packagingRequirements2
        }
        params = omit(params, ['isBag', 'packageinfoId'])
        return params
      } catch (error) {
        console.error(new Error(error))
        return {}
      }
    }
  }
}
