<template>
  <div>
    <Row>
      <Col :span="18">
      <table class="params-table">
        <tr v-for="item in zhanbanDataTopLight" :key="item.key">
          <td class="label">{{item.key}}：</td>
          <td>{{item.value}}</td>
        </tr>
      </table>
      </Col>
      <Col :span="6" class="text-right">
      <e-image fit="contain" style="width: 200px; height: 200px; padding-left: 10px" :src="getZhanbanImgLight()"
               :preview-src-list="[getZhanbanImgLight()]">
        <div class="el-image__error" slot="error">暂无图片{{getZhanbanImgLight()}}</div>
      </e-image>
      </Col>
    </Row>
    <!-- <Table :data="zhanbanDataTopLight" :columns="[
        { title: 'key', key: 'key', width: 120 },
        {
          title: 'value',
          key: 'value',
          render: (h, params) => {
            return tableFmt(h, params, 'value');
          }
        }
      ]" style="width: 100%" :show-header="false">
    </Table> -->
    <Table border :loading="loading" :data="zhanbanData" :columns="[
        { title: '构建清单', key: 'bom',slot: 'bom',align:'center'},
        {
          title: '长度(mm)',
          key: 'length',
          align:'center',
          slot: 'length',
        },
        {
          title: '宽度(mm)',
          key: 'width',
          align:'center',
          slot: 'width',
        },
        {
          title: '高度(mm)',
          key: 'height',
          align:'center',
          slot: 'height',
        },
        {
          title: '数量',
          key: 'quantity',
           align:'center',
          slot: 'quantity',
        },{
          title: '材料',
          key: 'bomMaterial',
           align:'center',
          slot: 'bomMaterial',
          width:200
        },
         {
          title: '操作',
          key: 'action',
           align:'center',
          slot: 'action',
        }
      ]" style="width: 100%">
      <template slot-scope="{ row }" slot="bom">
        <span v-if="row.bomId">{{row.bomName}}</span>
        <Input v-else v-model="zhanbanData[row._index].bomName" placeholder="输入名称"></Input>
      </template>
      <template slot-scope="{ row }" slot="length">
        <span v-if="row.bomId">{{row.bomLong}}</span>
        <Input v-else type="number" v-model.number="zhanbanData[row._index].bomLong" :readonly="row.bomId"
               placeholder="输入尺寸"></Input>
      </template>
      <template slot-scope="{ row }" slot="width">
        <span v-if="row.bomId">{{row.bomWidth}}</span>
        <Input v-else type="number" v-model.number="zhanbanData[row._index].bomWidth" :readonly="row.bomId"
               placeholder="输入尺寸"></Input>
      </template>
      <template slot-scope="{ row }" slot="height">
        <span v-if="row.bomId">{{row.bomHeight}}</span>
        <Input v-else type="number" v-model.number="zhanbanData[row._index].bomHeight" :readonly="row.bomId"
               placeholder="输入尺寸"></Input>
      </template>
      <template slot-scope="{ row }" slot="quantity">
        <span v-if="row.bomId">{{row.bomAmount}}</span>
        <Input v-else type="number" v-model.number="zhanbanData[row._index].bomAmount" :readonly="row.bomId"
               placeholder="输入数量"></Input>
      </template>
      <template slot-scope="{ row }" slot="bomMaterial">
        <span v-if="row.bomId">
          <template v-if="row.bomType === 0">
            <span v-if="row.bomMaterial === 1">桃花芯胶合板</span>
            <span v-if="row.bomMaterial === 2">原木</span>
          </template>
          <template v-if="row.bomType === 1">
            <span>{{getName(row.bomMaterial)}}</span>
          </template>
        </span>
        <Select v-else v-model="zhanbanData[row._index].bomMaterial" placeholder="请选择物料材料" transfer :disabled="row.bomId"
                filterable>
          <Option v-for="item in customMaterialList" :key="item.materialId" :value="item.materialId">{{item.materialName}}
          </Option>
        </Select>
      </template>
      <template slot-scope="{ row }" slot="action">
        <template v-if="!row.bomId">
          <a href="javascript:;" style="margin-right: 10px;" @click="saveBom(row)">保存</a>
          <Poptip confirm title="确认移除吗?" @on-ok="removeRow(row._index)" transfer>
            <a href="javascript:;">移除</a>
          </Poptip>
        </template>
        <Poptip v-else confirm title="确认移除吗?" @on-ok="removeBom(row)" transfer>
          <a href="javascript:;">移除</a>
        </Poptip>
      </template>
    </Table>
    <div class="text-center mt20"><Button ghost type="primary" @click="addBom">添加一条</Button></div>
  </div>
</template>

<script>
import { listPalletBomInfo, delPalletBomInfo, addPalletBomInfo, getUserPackageHistoryRecordsAndPage, getCustomMaterial } from '@/api/packing'
import { PackageInfo } from '@/utils/mixins'
export default {
  mixins: [PackageInfo],
  inject: {
    zhixiangData: {
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false,
      customMaterialList: [],
      zhanbanDataTopLight: [
        {
          key: '品名',
          value: '托盘/栈板'
        },
        {
          key: '规格(mm)',
          value: ''
        },
        {
          key: '材料',
          value: ''
        },
        {
          key: '数量',
          value: '1'
        }
      ],
      zhanbanData: [
        {
          bom: '面板',
          length: '',
          width: '',
          height: '',
          quantity: ''
        },
        {
          bom: '上拉板1',
          length: '',
          width: '',
          height: '',
          quantity: ''
        },
        {
          bom: '上拉板2',
          length: '',
          width: '',
          height: '',
          quantity: ''
        },
        {
          bom: '脚蹲',
          length: '',
          width: '',
          height: '',
          quantity: ''
        },
        {
          bom: '下拉板1',
          length: '',
          width: '',
          height: '',
          quantity: ''
        },
        {
          bom: '下拉板2',
          length: '',
          width: '',
          height: '',
          quantity: ''
        }
      ]
    }
  },
  computed: {
    packageType () {
      return this.$get(this.packageInfo, 'packageinfo.packageType', '') || 2
    }
  },
  created () {
    this.assignZhanbanDataLight()
    this.listPalletBomInfo()
    this.getCustomMaterial()
  },
  methods: {
    getCustomMaterial () {
      getCustomMaterial().then(res => {
        if (res.code === 0) {
          this.customMaterialList = res.rows || []
        }
      })
    },
    getName (bomMaterial) {
      const obj = this.customMaterialList.find(e => e.materialId === bomMaterial)
      return obj && obj.materialName
    },
    getZhanbanImgLight () {
      try {
        const { palletMaterial, forkDirection } =
          this.packageInfo.packageinfo.pallet || {}
        const { loadingUpLimit } =
          this.packageInfo.packageinfo.pallet.palletInfoNormal || {}
        console.log(this.packageInfo.packageinfo)
        return (
          `static/zhanban/${forkDirection}_${palletMaterial}_${loadingUpLimit}.png`
        )
      } catch (error) {
        return ''
      }
    },
    assignZhanbanDataLight () {
      const packageinfo = this.packageInfo.packageinfo

      const palletInfo = packageinfo.pallet || {}
      const palletInfoNormal = palletInfo.palletInfoNormal || {}
      // v2.1
      // 规格
      this.zhanbanDataTopLight[1].value = `${palletInfo.palletLong ||
        '---'} * ${palletInfo.palletWidth ||
        '---'} * ${palletInfo.palletHeight || '---'}`
      // 材料
      this.zhanbanDataTopLight[2].value = this.getZhanbanMaterialLabel(
        palletInfo.palletMaterial
      )

      // 面板
      this.zhanbanData[0].length = palletInfoNormal.panelLong
      this.zhanbanData[0].width = palletInfoNormal.panelWidth
      this.zhanbanData[0].height = palletInfoNormal.panelThickness
      this.zhanbanData[0].quantity = palletInfoNormal.panelNum
      // 上拉板1
      this.zhanbanData[1].length = palletInfoNormal.upplate1Long
      this.zhanbanData[1].width = palletInfoNormal.upplate1Width
      this.zhanbanData[1].height = palletInfoNormal.upplate1Thickness
      this.zhanbanData[1].quantity = palletInfoNormal.upplate1Num
      // 上拉板2
      this.zhanbanData[2].length = palletInfoNormal.upplate2Long
      this.zhanbanData[2].width = palletInfoNormal.upplate2Width
      this.zhanbanData[2].height = palletInfoNormal.upplate2Thickness
      this.zhanbanData[2].quantity = palletInfoNormal.upplate2Num
      // 脚蹲
      this.zhanbanData[3].length = palletInfoNormal.stackfootLong
      this.zhanbanData[3].width = palletInfoNormal.stackfootWidth
      this.zhanbanData[3].height = palletInfoNormal.stackfootThickness
      this.zhanbanData[3].quantity = palletInfoNormal.stackfootNum
      // 下拉板1
      this.zhanbanData[4].length = palletInfoNormal.downplate1Long
      this.zhanbanData[4].width = palletInfoNormal.downplate1Width
      this.zhanbanData[4].height = palletInfoNormal.downplate1Thickness
      this.zhanbanData[4].quantity = palletInfoNormal.downplate1Num
      // 下拉板2
      this.zhanbanData[5].length = palletInfoNormal.downplate2Long
      this.zhanbanData[5].width = palletInfoNormal.downplate2Width
      this.zhanbanData[5].height = palletInfoNormal.downplate2Thickness
      this.zhanbanData[5].quantity = palletInfoNormal.downplate2Num
    },
    getZhanbanMaterialLabel (val) {
      switch (true) {
        case val === 1:
          return '桃花芯胶合板'
        case val === 2:
        default:
          return '原木'
      }
    },
    tableFmt (h, params, key) {
      if (typeof params.row[key] === 'number') {
        return h('span', params.row[key])
      }
      return h('span', params.row[key] || '---')
    },
    listPalletBomInfo () {
      this.loading = true
      listPalletBomInfo({ packageInfoId: this.packageInfo.packageinfoId }).then(res => {
        if (res.code === 0) {
          this.loading = false
          this.zhanbanData = res.data
          this.zhanbanData = this.zhanbanData.filter(e => e.bomType !== 2)
        }
      })
    },
    removeBom (row) {
      const { packageInfoId, bomId } = row
      delPalletBomInfo({ packageInfoId, bomId }).then(res => {
        if (res.code === 0) {
          this.listPalletBomInfo()
          this.refreshSolution(this.packageInfo.packageinfoId)
        }
      })
    },
    addBom () {
      this.zhanbanData.push({
        bomName: '',
        bomLong: '',
        bomWidth: '',
        bomHeight: '',
        bomAmount: '',
        bomType: 1
      })
    },
    removeRow (index) {
      this.zhanbanData.splice(index, 1)
    },
    saveBom (data) {
      addPalletBomInfo({ palletBomInfo: [{ ...data, packageInfoId: this.packageInfo.packageinfoId }] }).then(res => {
        if (res.code === 0) {
          this.listPalletBomInfo()
          this.refreshSolution(this.packageInfo.packageinfoId)
        }
      })
    },
    refreshSolution (packageInfoId) {
      getUserPackageHistoryRecordsAndPage({ packageInfoId, pageIndex: 1, pageSize: 10, packageType: this.packageType, status: this.$route.query.status }).then(res => {
        if (res.isSuccess) {
          // this.listData = res.data[0]
          if (res.data.length) {
            this.$store.commit('SetPackageInfo', res.data[0])
          }
        } else {
          this.$Message.error(res.msg)
        }
      })
    },
    updateData () {
      this.assignZhanbanDataLight()
      this.listPalletBomInfo()
    }
  }

}
</script>

<style lang="scss" scoped>
/deep/ .ivu-input {
    border: none;
    text-align: center;
}
</style>
