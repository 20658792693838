<template>
  <div class="packing-view">
    <Row>
      <Col :span="8">
      <div class="attrs">
        <span>货柜载重:</span>{{deadWeight}} kg<br>
        <span>实际载重量:</span>{{actualWeight}} kg<br>
        <span>是否超载：</span>{{deadWeight > actualWeight ? '否':'是'}}<br>
        <span>装箱率：</span>{{cabinetRate}}%<br>
        <span>包装数：</span>{{packingNum}}<br>
        <span>产品数：</span>{{productCount}}<br>
      </div>
      <div class="packing-view__bts">
        <div>模拟装箱</div>
        <Button type="primary" ghost @click="UnpackItemInRender">上一个</Button>
        <Button type="primary" ghost @click="PackItemInRender" style="margin-left: 20px;">下一个</Button>
        <Button type="primary" ghost @click="renderAll" style="margin-left: 20px;">一键填充</Button>
      </div>
      </Col>
      <Col :span="16" class="text-right">
      <div class="packing-view__canvas" ref="packingView" :key="canvasId"></div>
      </Col>
    </Row>

  </div>
</template>

<script>
import { getPlanInfo } from '@/api/packing'
import * as THREE from 'three/build/three'

import { PackageInfo } from '@/utils/mixins'

THREE.OrbitControls = require('three-orbit-controls')(THREE)
export default {
  mixins: [PackageInfo],
  name: 'PackingView',

  props: {
    /**
     * 'zhanban' | 'guizi'
     */
    // type: String,

    layer: {
      default: 1,
      type: Number
    },

    containerWidth: {
      type: Number,
      default: 28
    },
    containerLong: {
      type: Number,
      default: 40
    },
    containerHeight: {
      type: Number,
      default: 20
    },
    packingAmout: {
      type: Number,
      default: 20
    },

    itemWidth: Number,
    itemLong: Number,
    itemHeight: Number
  },

  data () {
    return {
      canvasId: 0,
      ContainerID: 1004,
      Name: 'Box5',
      ContainerOriginOffset: {
        x: 0,
        y: 0,
        z: 0
      },
      AlgorithmPackingResults: [],
      ItemsToRender: [],
      LastItemRenderedIndex: -1,
      cabinetRate: '',
      packingNum: '',
      productCount: ''
    }
  },

  computed: {
    Length () {
      return this.containerLong
    },
    Width () {
      return this.containerWidth
    },
    Height () {
      return this.containerHeight
    },
    pallet () {
      const { pallet } = this.packageInfo.packageinfo || {}
      return pallet || {}
    },
    type () {
      const { palletId } = this.pallet
      return palletId ? 'zhanban' : 'guizi'
    },
    deadWeight () {
      const { packageinfo } = this.packageInfo
      const { containerSize } = packageinfo || {}
      let weight = ''
      switch (containerSize) {
        case '1':
          weight = 17.5 * 1000
          break
        case '2':
          weight = 20 * 1000
          break
        case '4':
          weight = 22 * 1000
          break
        case '5':
          weight = 29 * 1000
          break
        case '6':
          weight = 30.4 * 1000
          break
        case '8':
          weight = 29 * 1000
          break

        default:
          break
      }
      return weight
    },
    actualWeight () {
      const { packageinfo, productWeight } = this.packageInfo
      const { packingAmout } = packageinfo || {}
      return productWeight * packingAmout * this.packingNum
    }
  },

  watch: {
    type () {
      this.canvasId++
      this.$nextTick(() => {
        this.InitializeDrawing()
        this.ShowPackingView()
      })
    }
  },

  methods: {
    getContainerFrame (type) {
      switch (type) {
        case 'zhanban':
          return this.getZhanbanFrame()
        case 'guizi':
        default:
          return this.getGuiziFrame()
      }
    },
    getZhanbanFrame () {
      var container = this
      this.ContainerOriginOffset.x = (-1 * container.Length) / 2
      // NOTE: offsetY = 0
      this.ContainerOriginOffset.y = 0
      this.ContainerOriginOffset.z = (-1 * container.Width) / 2

      const geometry = new THREE.PlaneGeometry(
        container.Length,
        container.Width
      )

      var geo = new THREE.EdgesGeometry(geometry) // or WireframeGeometry( geometry )
      var mat = new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 2 })
      var wireframe = new THREE.LineSegments(geo, mat)
      // NOTE: rotate 90 deg
      wireframe.rotateX(Math.PI / 2)
      wireframe.position.set(0, 0, 0)
      wireframe.name = 'container'

      return wireframe
    },
    getGuiziFrame () {
      var container = this

      this.ContainerOriginOffset.x = (-1 * container.Length) / 2
      this.ContainerOriginOffset.y = (-1 * container.Height) / 2
      this.ContainerOriginOffset.z = (-1 * container.Width) / 2

      var geometry = new THREE.BoxGeometry(
        container.Length,
        container.Height,
        container.Width
      )

      var geo = new THREE.EdgesGeometry(geometry) // or WireframeGeometry( geometry )
      var mat = new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 2 })
      var wireframe = new THREE.LineSegments(geo, mat)
      wireframe.position.set(0, 0, 0)
      wireframe.name = 'container'

      return wireframe
    },
    InitializeDrawing () {
      this.$nextTick(() => {
        const container = this.$refs.packingView

        this.scene = new THREE.Scene()
        this.camera = new THREE.PerspectiveCamera(
          50,
          window.innerWidth / window.innerHeight,
          0.1,
          100000
        )
        this.camera.lookAt(this.scene.position)

        // var axisHelper = new THREE.AxisHelper(5)
        // this.scene.add(axisHelper)

        // LIGHT
        const light = new THREE.PointLight(0xffffff)
        light.position.set(0, 150, 100)
        this.scene.add(light)

        // Get the item stuff ready.
        this.itemMaterial = new THREE.MeshNormalMaterial({
          transparent: true,
          opacity: 0.6
        })

        this.renderer = new THREE.WebGLRenderer({ antialias: true }) // WebGLRenderer CanvasRenderer
        // renderer.setClearColor('#edeef3');
        this.renderer.setClearColor('#f2f5fc')
        this.renderer.setPixelRatio(window.devicePixelRatio)
        // this.renderer.setSize(window.innerWidth / 2, window.innerHeight / 2);
        this.renderer.setSize(container.offsetWidth, window.innerHeight / 2)
        container.append(this.renderer.domElement)

        this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement)
        window.addEventListener('resize', this.onWindowResize, false)

        this.animate()
      })
    },
    onWindowResize () {
      const container = this.$refs.packingView
      this.camera.aspect = window.innerWidth / window.innerHeight
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(container.offsetWidth, window.innerHeight / 2)
    },
    animate () {
      requestAnimationFrame(this.animate)
      this.controls.update()
      this.renderView()
    },
    renderView () {
      this.renderer.render(this.scene, this.camera)
    },
    ShowPackingView (algorithmPackingResult) {
      var container = this
      const scene = this.scene
      var selectedObject = scene.getObjectByName('container')
      scene.remove(selectedObject)

      for (var i = 0; i < 1000; i++) {
        const selectedObject = scene.getObjectByName('cube' + i)
        scene.remove(selectedObject)
      }

      this.camera.position.set(container.Length, container.Length, container.Length)

      // self.ItemsToRender(algorithmPackingResult.PackedItems);

      // reset Index
      this.LastItemRenderedIndex = -1

      scene.add(this.getContainerFrame(this.type))
    },
    renderAll () {
      this.ItemsToRender.forEach(e => {
        this.PackItemInRender()
      })
    },
    PackItemInRender () {
      const self = this

      if (self.LastItemRenderedIndex === self.ItemsToRender.length - 1) {
        return
      }

      var itemIndex = self.LastItemRenderedIndex + 1
      console.log('index', itemIndex)
      var itemOriginOffset = {
        x: self.ItemsToRender[itemIndex].packDimX / 2,
        // y: self.ItemsToRender[itemIndex].packDimY / 2,
        y: Math.min(
          // NOTE: use client side itemHeight
          self.itemHeight / 2,
          this.Height / 2
        ),
        z: self.ItemsToRender[itemIndex].packDimZ / 2
      }

      var itemGeometry = new THREE.BoxGeometry(
        self.ItemsToRender[itemIndex].packDimX,
        Math.min(
          // NOTE: use client side itemHeight
          self.itemHeight,
          this.Height - 10
        ),
        self.ItemsToRender[itemIndex].packDimZ
      )
      var cube = new THREE.Mesh(itemGeometry, this.itemMaterial)
      cube.position.set(
        self.ContainerOriginOffset.x +
        itemOriginOffset.x +
        self.ItemsToRender[itemIndex].coordX,
        self.ContainerOriginOffset.y +
        itemOriginOffset.y +
        self.ItemsToRender[itemIndex].coordY,
        self.ContainerOriginOffset.z +
        itemOriginOffset.z +
        self.ItemsToRender[itemIndex].coordZ
      )
      cube.name = 'cube' + itemIndex
      this.scene.add(cube)

      self.LastItemRenderedIndex++
    },
    UnpackItemInRender () {
      const self = this
      if (self.LastItemRenderedIndex === -1) {
        return
      }

      var selectedObject = this.scene.getObjectByName(
        'cube' + self.LastItemRenderedIndex
      )
      this.scene.remove(selectedObject)
      self.LastItemRenderedIndex--
    },
    getLayerData (data, layer = 1) {
      if (layer === 1) {
        return data
      }
      // const layerHeight = (data[0].PackDimX + data[0].PackDimZ) / layer;
      const layerHeight = this.itemHeight
      // const itemPerLayer = data.length

      const finalData = [...Array(layer)].reduce((acc, v, i) => {
        if (i === 0) {
          return acc.concat(data)
        }

        const layerData = [...data]
        layerData.forEach((layerItem, layerItemIdx) => {
          layerData[layerItemIdx] = {
            ...layerItem,
            CoordY: layerHeight * i
          }
        })

        return acc.concat(layerData)
      }, [])

      return finalData
    },
    updateData () {
      console.log('this', this)
      const params = {
        itemLong: this.itemLong,
        itemWidth: this.itemWidth,
        itemHeight: this.itemHeight,
        containerLong: this.containerLong,
        containerWidth: this.containerWidth,
        containerHeight: this.containerHeight,
        packingAmout: this.packingAmout
      }

      this.$nextTick(() => {
        this.onWindowResize()
      })
      getPlanInfo(params).then(res => {
        const { cabinetRate, packingNum, productCount } = res.data
        res = res.data
        this.cabinetRate = cabinetRate
        this.packingNum = packingNum
        this.productCount = productCount
        this.ItemsToRender = this.getLayerData(
          res.algorithmPackingResults[0].packedItems,
          this.layer
        )

        setTimeout(() => {
          this.ShowPackingView(res.algorithmPackingResults)
        }, 100)
      })
    }
  },

  mounted () {
    this.InitializeDrawing()
    this.updateData()
    // const itemsToRender = this.getLayerData(
    //   mockPackingRes[0].AlgorithmPackingResults[0].PackedItems,
    //   this.layer
    // );

    // this.ItemsToRender = itemsToRender;

    // setTimeout(() => {
    //   this.ShowPackingView(mockPackingRes[0].AlgorithmPackingResults);
    // }, 100);
  }
}
</script>

<style lang="scss" scoped>
.packing-view__bts {
    position: absolute;
    bottom: 0px;
}
.attrs span {
    display: inline-block;
    width: 160px;
}
</style>
