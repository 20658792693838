<template>
  <div>
    <template v-for="item in zhanbanData">
      <Collapse :value="item.bomId+''" class="mt20" :key="item.bomId">
        <Panel :name="item.bomId+''">
          {{item.bomName}}
          <div slot="content">
            <Row>
              <Col :span="18">
              <table class="params-table">
                <tr>
                  <td class="label">尺寸(mm)：</td>
                  <td>{{item.bomLong}}*{{item.bomWidth}}{{item.bomHeight && '*'+item.bomHeight}}</td>
                </tr>
                <tr>
                  <td class="label">数量：</td>
                  <td>{{item.bomAmount}}</td>
                </tr>
                <tr>
                  <td class="label">材料：</td>
                  <td>{{getName(item.bomMaterial)}}</td>
                </tr>
              </table>
              </Col>
              <Col :span="6" class="text-right">
              <e-image fit="contain" style="width: 100%; height: 140px; " :src="getMaterialImage(item.bomMaterial)"
                       :preview-src-list="[getMaterialImage(item.bomMaterial)]">
                <div class="el-image__error" slot="error">该材料暂无示意图</div>
              </e-image>
              </Col>
            </Row>
            <!-- <Table border :loading="loading" :data="zhanbanData" :columns="[
        { title: '物料名称', key: 'bom',slot: 'bom',align:'center'},
        {
          title: '长度(mm)',
          key: 'length',
          align:'center',
          slot: 'length',
        },
        {
          title: '宽度(mm)',
          key: 'width',
          align:'center',
          slot: 'width',
        },
        {
          title: '高度(mm)',
          key: 'height',
          align:'center',
          slot: 'height',
        },
        {
          title: '数量',
          key: 'quantity',
           align:'center',
          slot: 'quantity',
        },{
          title: '材料',
          key: 'bomMaterial',
           align:'center',
          slot: 'bomMaterial',
          width:200
        },
         {
          title: '操作',
          key: 'action',
           align:'center',
          slot: 'action',
        }
      ]" style="width: 100%">
              <template slot-scope="{ row }" slot="bom">
                <span v-if="row.bomId">{{row.bomName}}</span>
                <Input v-else v-model="zhanbanData[row._index].bomName" placeholder="输入名称"></Input>
              </template>
              <template slot-scope="{ row }" slot="length">
                <span v-if="row.bomId">{{row.bomLong}}</span>
                <Input v-else type="number" v-model.number="zhanbanData[row._index].bomLong" :readonly="row.bomId"
                       placeholder="输入尺寸"></Input>
              </template>
              <template slot-scope="{ row }" slot="width">
                <span v-if="row.bomId">{{row.bomWidth}}</span>
                <Input v-else type="number" v-model.number="zhanbanData[row._index].bomWidth" :readonly="row.bomId"
                       placeholder="输入尺寸"></Input>
              </template>
              <template slot-scope="{ row }" slot="height">
                <span v-if="row.bomId">{{row.bomHeight}}</span>
                <Input v-else type="number" v-model.number="zhanbanData[row._index].bomHeight" :readonly="row.bomId"
                       placeholder="输入尺寸"></Input>
              </template>
              <template slot-scope="{ row }" slot="quantity">
                <span v-if="row.bomId">{{row.bomAmount}}</span>
                <Input v-else type="number" v-model.number="zhanbanData[row._index].bomAmount" :readonly="row.bomId"
                       placeholder="输入数量"></Input>
              </template>
              <template slot-scope="{ row }" slot="bomMaterial">
                <span v-if="row.bomId">
                  <template v-if="row.bomType === 0">
                    <span v-if="row.bomMaterial === 1">桃花芯胶合板</span>
                    <span v-if="row.bomMaterial === 2">原木</span>
                  </template>
                  <template v-if="row.bomType === 1">
                    <span>{{getName(row.bomMaterial)}}</span>
                  </template>
                </span>
                <Select v-else v-model="zhanbanData[row._index].bomMaterial" placeholder="请选择物料材料" transfer
                        :disabled="row.bomId" filterable>
                  <Option v-for="item in customMaterialList" :key="item.materialId" :value="item.materialId">
                    {{item.materialName}}
                  </Option>
                </Select>
              </template>
              <template slot-scope="{ row }" slot="action">
                <template v-if="!row.bomId">
                  <a href="javascript:;" style="margin-right: 10px;" @click="saveBom(row)">保存</a>
                  <Poptip confirm title="确认移除吗?" @on-ok="removeRow(row._index)" transfer>
                    <a href="javascript:;">移除</a>
                  </Poptip>
                </template>
                <Poptip v-else confirm title="确认移除吗?" @on-ok="removeBom(row)" transfer>
                  <a href="javascript:;">移除</a>
                </Poptip>
              </template>
            </Table> -->
          </div>
        </Panel>
      </Collapse>
    </template>
  </div>
</template>

<script>
import { listPalletBomInfo, getCustomMaterial } from '@/api/packing'

import { PackageInfo } from '@/utils/mixins'
export default {
  mixins: [PackageInfo],
  data () {
    return {
      loading: false,
      customMaterialList: [],
      zhanbanData: [
      ]
    }
  },
  computed: {
    packageType () {
      return this.$get(this.packageInfo, 'packageinfo.packageType', '') || 2
    }
  },
  async created () {
    await this.getCustomMaterial()
    this.listPalletBomInfo()
  },
  methods: {
    async getCustomMaterial () {
      return getCustomMaterial().then(res => {
        if (res.code === 0) {
          this.customMaterialList = res.rows || []
        }
      })
    },
    getName (bomMaterial) {
      const obj = this.customMaterialList.find(e => e.materialId === bomMaterial)
      return obj && obj.materialName
    },
    getMaterialImage (id) {
      if (!id) return ''
      const obj = this.customMaterialList.find(e => e.materialId === id)
      return obj && obj.materialImage
    },
    getZhanbanMaterialLabel (val) {
      switch (true) {
        case val === 1:
          return '桃花芯胶合板'
        case val === 2:
        default:
          return '原木'
      }
    },
    listPalletBomInfo () {
      this.loading = true
      listPalletBomInfo({ packageInfoId: this.packageInfo.packageinfoId, bomType: 2 }).then(res => {
        if (res.code === 0) {
          this.loading = false
          this.zhanbanData = res.data
        }
      })
    }
    // removeBom (row) {
    //   const { packageInfoId, bomId } = row
    //   delPalletBomInfo({ packageInfoId, bomId }).then(res => {
    //     if (res.code === 0) {
    //       this.listPalletBomInfo()
    //       this.refreshSolution(this.packageInfo.packageinfoId)
    //     }
    //   })
    // },
    // addBom () {
    //   this.zhanbanData.push({
    //     bomName: '',
    //     bomLong: '',
    //     bomWidth: '',
    //     bomHeight: '',
    //     bomAmount: '',
    //     bomType: 2
    //   })
    // },
    // removeRow (index) {
    //   this.zhanbanData.splice(index, 1)
    // },
    // saveBom (data) {
    //   addPalletBomInfo({ palletBomInfo: [{ ...data, packageInfoId: this.packageInfo.packageinfoId }] }).then(res => {
    //     if (res.code === 0) {
    //       this.listPalletBomInfo()
    //       this.refreshSolution(this.packageInfo.packageinfoId)
    //     }
    //   })
    // },
    // refreshSolution (packageInfoId) {
    //   getUserPackageHistoryRecordsAndPage({ packageInfoId, pageIndex: 1, pageSize: 10, packageType: this.packageType, status: this.$route.query.status }).then(res => {
    //     if (res.isSuccess) {
    //       // this.listData = res.data[0]
    //       if (res.data.length) {
    //         this.$store.commit('SetPackageInfo', res.data[0])
    //       }
    //     } else {
    //       this.$Message.error(res.msg)
    //     }
    //   })
    // },
    // updateData () {
    //   this.assignZhanbanData()
    //   this.listPalletBomInfo()
    // }
  }

}
</script>

<style lang="scss" scoped>
/deep/ .ivu-input {
    border: none;
    text-align: center;
}
</style>
