<template>
  <div>
    <Row>
      <Col :span="18">
      <table class="params-table">
        <tr v-for="item in duanDaokaData" :key="item.key">
          <td class="label">{{item.key}}：</td>
          <td>{{item.value}}</td>
        </tr>
      </table>
      </Col>
      <Col :span="6" class="text-right">
      <e-image fit="contain" style="height: 80px;" src="static/daoka/duanDaoka.png"
               :preview-src-list="['static/daoka/duanDaoka.png']" />
      </Col>
    </Row>
    <!-- <Table :data="duanDaokaData" :columns="[
        { title: 'key', key: 'key', width: 200 },
        {
          title: 'value',
          key: 'value',
          render: (h, params) => {
            return tableFmt(h, params, 'value');
          }
        }
      ]" style="width: 100%" :show-header="false">
    </Table> -->
  </div>
</template>

<script>
import { PackageInfo } from '@/utils/mixins'
export default {
  mixins: [PackageInfo],
  inject: {
    zhixiangData: {
      default: () => ({})
    }
  },
  data () {
    return {
      duanDaokaData: [
        {
          key: '品名',
          value: '短刀卡'
        },
        {
          key: '规格',
          value: ''
        },
        {
          key: '数量',
          value: ''
        },
        {
          key: '材料',
          value: ''
        },
        {
          key: '楞型',
          value: ''
        },
        {
          key: '材料定量',
          value: ''
        },
        {
          key: '耐破强度',
          value: ''
        },
        {
          key: '边压强度',
          value: ''
        }
      ]
    }
  },
  created () {
    this.assignDaokaData(this.packageInfo)
  },
  methods: {
    assignDaokaData (info) {
      const daokaInfo = info.packageinfo.auxiliary || {}
      const heavyDaokaMaterial = info.packageinfo.auxiliary.heavyDaokaMaterial || {}
      // 短刀卡
      this.duanDaokaData[1].value = `${daokaInfo.shortDaokaLong ||
        '---'} * ${daokaInfo.shortDaokaWidth || '---'} mm`
      this.duanDaokaData[2].value = daokaInfo.shortDaokaAmount
      this.duanDaokaData[3].value = heavyDaokaMaterial.materialtypeName
      this.duanDaokaData[4].value = heavyDaokaMaterial.lengxing && heavyDaokaMaterial.lengxing + '楞'
      this.duanDaokaData[5].value = heavyDaokaMaterial.cailiaodingliang && heavyDaokaMaterial.cailiaodingliang + 'g/m²'
      this.duanDaokaData[6].value = heavyDaokaMaterial.naipoqiangdu && heavyDaokaMaterial.naipoqiangdu + 'kpa'
      this.duanDaokaData[7].value = heavyDaokaMaterial.bianyaqiangdu && heavyDaokaMaterial.bianyaqiangdu + 'N/m'
      // // 长刀卡
      // this.changDaokaData[1].value = `${daokaInfo.longDaokaLong ||
      //   '---'} * ${daokaInfo.longDaokaWidth || '---'} mm`
      // this.changDaokaData[2].value = daokaInfo.longDaokaAmount
      // this.changDaokaData[3].value = heavyDaokaMaterial.materialtypeName
      // this.changDaokaData[4].value = heavyDaokaMaterial.lengxing + '楞'
      // this.changDaokaData[5].value = heavyDaokaMaterial.cailiaodingliang + 'g/m²'
      // this.changDaokaData[6].value = heavyDaokaMaterial.naipoqiangdu + 'kpa'
      // this.changDaokaData[7].value = heavyDaokaMaterial.bianyaqiangdu + 'N/m'
      // // 平卡
      // this.pingkaData[1].value = `${daokaInfo.pingkaLong ||
      //   '---'} * ${daokaInfo.pingkaWidth || '---'} mm`
      // this.pingkaData[2].value = daokaInfo.pingkaAmount
      // this.pingkaData[3].value = heavyDaokaMaterial.materialtypeName
      // this.pingkaData[4].value = heavyDaokaMaterial.lengxing + '楞'
      // this.pingkaData[5].value = heavyDaokaMaterial.cailiaodingliang + 'g/m²'
      // this.pingkaData[6].value = heavyDaokaMaterial.naipoqiangdu + 'kpa'
      // this.pingkaData[7].value = heavyDaokaMaterial.bianyaqiangdu + 'N/m'
    },
    tableFmt (h, params, key) {
      if (typeof params.row[key] === 'number') {
        return h('span', params.row[key])
      }
      return h('span', params.row[key] || '---')
    },
    updateData () {
      this.assignDaokaData(this.packageInfo)
    }
  }

}
</script>

<style lang="scss" scoped></style>
