<template>
  <Form :model="form" ref="Form" :rules="rules" label-position="top">
    <template v-if="isRegularPlace !== 2">
      <Divider class="primary">以下为所有方案通用物料</Divider>
      <Row :gutter="50">
        <Col :span="8">
        <FormItem label="刀卡信息" prop="needDaoka" ref="needDaoka" style="margin-bottom:40px;" :rules="[
          {
            required: true,
            message: '请选择'
          }
        ]">
          <template #label>
            刀卡信息
            <Tooltip placement="top">
              <div href="" class="ico-tips">
                <Icon type="ios-alert" style="color:#67c5ec" />
              </div>
              <div slot="content">
                <p>刀卡，用于将多个产品隔开的</p>
                <p>纸质或其他材料的隔档</p>
              </div>
            </Tooltip>
          </template>
          <RadioGroup v-model="form.needDaoka">
            <Radio :label="1">需要</Radio>
            <Radio :label="0">不需要</Radio>
          </RadioGroup>
        </FormItem>
        </Col>
        <Col :span="8">
        <!-- <FormItem v-if="form.needDaoka === 1" label="刀卡/平卡材料" prop="daokaCailiao">
          <Select v-model="form.daokaCailiao" placeholder="请选择">
            <Option value="2C500">2C500G</Option>
            <Option value="2C850">2C850</Option>
            <Option value="3C1100">3C1100</Option>
            <Option value="3C1200">3C1200</Option>
            <Option value="3C1400">3C1400</Option>
            <Option value="3A1300加强">3A1300加强</Option>
            <Option value="6C2000">6C2000</Option>
          </Select>
        </FormItem> -->
        </Col>
        <Col :span="8">
        </Col>
        <Col :span="8">
        <FormItem label="包装袋信息" prop="isBag" ref="isBag"
                  :rules="[{required:true,message:'请选择',validator:(rule, value) => !isNaN(value)}]" style="margin-bottom:40px;">
          <template #label>
            包装袋信息
            <Tooltip placement="top">
              <div href="" class="ico-tips">
                <Icon type="ios-alert" style="color:#67c5ec" />
              </div>
              <div slot="content">
                <p>用于保护产品，防潮防尘，防锈等。</p>
              </div>
            </Tooltip>
          </template>
          <RadioGroup v-model="form.isBag">
            <Radio label="1">需要</Radio>
            <Radio label="0">不需要</Radio>
          </RadioGroup>
        </FormItem>
        </Col>
        <Col :span="8">
        <FormItem v-if="form.isBag === '1'" label="包装袋种类" prop="bagType" ref="bagType"
                  :rules="[{required:true,message:'请选择包装袋种类'}]" style="margin-bottom:40px;">
          <!-- <select-dict v-model="form.bagType" :default-value="form.bagType" placeholder="选择包装袋种类" type="bag_type"></select-dict> -->
          <Select v-model="form.bagType" :default-value="form.bagType" type="bag_type" placeholder="选择包装袋种类">
            <Option :value="1">平口袋<img src="@/assets/images/flat_pocket.png" width="100" alt=""></Option>
            <Option :value="2">方口袋<img src="@/assets/images/side_pocket.png" width="100" alt=""></Option>
            <Option :value="3">自封袋<img src="@/assets/images/valve_bag.png" width="100" alt=""></Option>
            <Option :value="0">不使用袋子</Option>
          </Select>
        </FormItem>
        </Col>
        <Col :span="8">
        </Col>
        <template v-if="form.isBag === '1'">
          <!-- <Col :span="8">
        <FormItem label="包装袋功能" prop="bagFunction" ref="bagFunction" :rules="[{required:true,message:'请选择包装袋功能'}]">
          <select-dict v-model="form.bagFunction" :default-value="form.bagFunction" placeholder="选择包装袋功能" type="bag_material">
          </select-dict>
        </FormItem>
        </Col> -->
          <Col :span="8">
          <FormItem label="包装袋材料" prop="bagFunction" ref="bagFunction" :rules="[{required:true,message:'请选择包装袋材料'}]">
            <!-- <Input v-model="form.bagMaterial" placeholder="" disabled></Input> -->
            <select-dict v-model="form.bagFunction" placeholder="选择包装袋材料" type="bag_material"></select-dict>
          </FormItem>
          </Col>
          <Col :span="8">
          <FormItem label="材料厚度（丝）" prop="bagThickness" ref="bagThickness" :rules="[{required:true,message:'请选择材料厚度'}]">
            <Select v-model="form.bagThickness" placeholder="请选择厚度">
              <Option value="4">4丝</Option>
              <Option value="5">5丝</Option>
              <Option value="6">6丝</Option>
              <Option value="7">7丝</Option>
              <Option value="8">8丝</Option>
              <Option value="10">10丝</Option>
              <Option value="12">12丝</Option>
            </Select>
          </FormItem>
          </Col>
        </template>
      </Row>
      <template v-for="(item,index) in huanchongTypes">
        <div class="panel-primary" style="margin-top:20px;" :key="index">
          <Collapse :value="'index'">
            <Panel :name="'index'">
              <span>
                {{item.name + '缓冲保护信息'}}
              </span>
              <p slot="content">
                <Row :key="item.value" :gutter="50">
                  <Col :span="8">
                  <FormItem>
                    <select-dict v-model="form[item.value + 'ProtectionMethod']"
                                 :default-value="form[item.value + 'ProtectionMethod']" placeholder="请选择保护方式"
                                 :type="item.value + '_protection_method'" @change="value => handleChange(item.value,value)">
                    </select-dict>
                  </FormItem>
                  </Col>
                  <Col :span="8">
                  <FormItem label="">
                    <div style="position: relative;overflow:hidden"
                         v-if="form[item.value + 'ProtectionMethod'] && form[item.value + 'ProtectionMethod'] !== '0'">
                      <Button type="primary" ghost
                              @click="preivewImg(form[item.value + 'ProtectionMethod'], item.value)">查看保护结构示意图</Button>
                      <div style="position:absolute;">
                        <e-image :ref="`Form${item.value}${form[item.value + 'ProtectionMethod']}`"
                                 style="width: 100px; height: 100px;"
                                 :src="getProtectImg(form[item.value + 'ProtectionMethod'], item.value)"
                                 :preview-src-list="[getProtectImg(form[item.value + 'ProtectionMethod'], item.value)]">
                        </e-image>
                      </div>
                    </div>
                  </FormItem>
                  </Col>
                  <Col :span="8">
                  </Col>
                  <template v-if="form[item.value + 'ProtectionMethod'] && form[item.value + 'ProtectionMethod'] !== '0'">
                    <Col :span="8">
                    <FormItem v-if="form[item.value + 'ProtectionMethod']">
                      <select-dict v-model="form[item.value + 'Material']" :default-value="form[item.value + 'Material']"
                                   placeholder="请选择材料"
                                   :type="item.value === 'jubu' && form[item.value + 'ProtectionMethod'] === '2' ? 'protection_material':'protection_material_jubu'">
                      </select-dict>
                    </FormItem>
                    </Col>

                    <Col :span="8">
                    <FormItem class="label-right">
                      <int-input v-model="form[item.value + 'Amount']" :default-value="form[item.value + 'Amount']"
                                 placeholder="请输入数量" unit="个">
                      </int-input>
                    </FormItem>
                    </Col>
                    <Col :span="8">
                    <FormItem v-if="item.value !== 'jubu'" class="label-right">
                      <int-input v-model="form[item.value + 'Thickness']" :default-value="form[item.value + 'Thickness']"
                                 placeholder="请输入厚度" unit="厘米"></int-input>
                    </FormItem>
                    </Col>
                    <Col :span="8" v-if="item.value === 'jubu'">
                    <FormItem>
                      <div class="flex">
                        <int-input v-model.number="form.jubuC" :default-value="form.jubuC" placeholder="长度"></int-input>
                        <int-input v-model.number="form.jubuK" :default-value="form.jubuK" placeholder="宽度"
                                   style="margin-left:12px">
                        </int-input>
                        <int-input v-model.number="form.jubuG" :default-value="form.jubuG" placeholder="高度"
                                   style="margin-left:12px">
                        </int-input>
                      </div>
                    </FormItem>
                    </Col>
                  </template>
                </Row>
              </p>
            </Panel>
          </Collapse>
        </div>
      </template>
    </template>
    <Divider class="primary" style="margin: 40px auto;">以下信息为其他自定义物料</Divider>
    <div class="panel-primary" style="margin-bottom:20px;" v-for="(scheme, index) in form.palletBomInfo" :key="index">
      <Collapse :value="'index'">
        <Panel :name="'index'">
          <span>
            自定义物料{{ index + 1 }}
            <span style="float:right;margin-right:20px;">
              <Button shape="circle" size="small" style="width:100px;" @click="removeMaterial(index)"><span
                      style="font-size:12px!important;color:#67C5EC">删除物料</span></Button>
            </span>
          </span>
          <p slot="content">
            <Row :gutter="50">
              <Col :span="8">
              <FormItem label="物料名称" prop="bomName">
                <Input v-model="scheme.bomName" placeholder="请输入物料名称"></Input>
              </FormItem>
              <FormItem label="物料数量（个）" prop="bomAmount">
                <Input v-model="scheme.bomAmount" placeholder="请输入数量"></Input>
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem label="物料尺寸（mm）">
                <div class="flex">
                  <int-input v-model.number="scheme.bomLong" :default-value="scheme.bomLong" placeholder="长度">
                  </int-input>
                  <int-input v-model.number="scheme.bomWidth" :default-value="scheme.bomWidth" placeholder="宽度"
                             style="margin-left:12px">
                  </int-input>
                  <int-input v-model.number="scheme.bomHeight" :default-value="scheme.bomHeight" placeholder="高度"
                             style="margin-left:12px">
                  </int-input>
                </div>
              </FormItem>
              <FormItem label="物料材料" prop="bomMaterial">
                <select-dict v-model="scheme.firstType" :default-value="scheme.firstType" type="custom_material_first"
                             placeholder="一级分类" style="display:inline-block;width:120px;">
                </select-dict>
                <Select v-model="scheme.bomMaterial" placeholder="请选择物料材料" filterable
                        style="display:inline-block;width:calc(100% - 140px);margin-left: 20px;">
                  <Option v-for="item in customMaterialList.filter(e => e.materialType === scheme.firstType)"
                          :key="item.materialId" :value="item.materialId">
                    {{item.materialName}}</Option>
                </Select>
                <!-- <select-dict v-model="form.otherMaterial" :default-value="form.otherMaterial" type="other_material">
        </select-dict> -->
              </FormItem>
              </Col>
              <Col :span="8">
              <FormItem label="物料材料示意图">
                <e-image fit="contain" style="width: 100%; height: 140px; " :src="getMaterialImage(scheme.bomMaterial)"
                         :preview-src-list="[getMaterialImage(scheme.bomMaterial)]">
                  <div class="el-image__error" slot="error">该材料暂无示意图</div>
                </e-image>
              </FormItem>
              </Col>

            </Row>
          </p>
        </Panel>
      </Collapse>
    </div>
    <div class="text-center">
      <Button type="primary" ghost @click="addMaterial">
        <Icon type="ios-add" /> 添加自定义物料
      </Button>
    </div>

    <CartonTypeModal ref="FormCartonTypeModal" @select="handleSelect"></CartonTypeModal>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import { Package } from '@/utils/mixins'
import { getBoxTypeList, getCustomMaterial } from '@/api/packing'
import CartonTypeModal from '../components/CartonTypeModal.vue'
export default {
  components: {
    CartonTypeModal
  },
  mixins: [Package],
  data () {
    const huanchongTypes = [
      {
        value: 'dingbu',
        name: '顶部'
      },
      {
        value: 'sibian',
        name: '侧楞'
      },
      {
        value: 'dibu',
        name: '底部'
      },
      {
        value: 'jubu',
        name: '局部'
      }
    ]
    return {
      huanchongTypes,
      boxTypeList: [],
      form: {
        palletBomInfo: [
          {
            bomName: '',
            bomLong: '',
            bomWidth: '',
            bomHeight: '',
            bomAmount: '',
            bomMaterial: '',
            bomType: 2
          }
        ]
      },
      first: true,
      first2: true,
      customMaterialList: [],
      rules: {
      }
    }
  },
  computed: {
    ...mapState({
      OrdinaryPackage: state => state.OrdinaryPackage
    }),
    isRegularPlace () {
      const { packagingRequirements } = this.OrdinaryPackage
      return packagingRequirements && packagingRequirements.isRegularPlace
    }
  },
  destination () {
    const { logisticsInformation } = this.OrdinaryPackage || {}
    return this.$get(logisticsInformation, 'destination')
  },
  watch: {
    destination: function (newval, oldval) {
      if (this.form.needPallet === 1) {
        if (newval === 1) {
          this.form.palletMaterial = '2'
        } else {
          this.form.palletMaterial = '1'
          this.form.forkDirection = '1'
        }
      }
    },
    'form.isBag': function (newval) {
      if (newval === '0') {
        this.form.bagFunction = ''
        this.form.bagMaterial = ''
        this.form.bagType = ''
        this.form.bagThickness = ''
      }
    },
    'form.bagFunction': function (newval) {
      if (newval === '1') {
        this.form.bagMaterial = 'PE袋，LLDPE'
        this.form.bagThickness = '8'
      }
      if (newval === '2') {
        this.form.bagMaterial = 'VCI气象防锈袋'
        this.form.bagThickness = '8'
      }
      if (newval === '') {
        this.form.bagMaterial = ''
        this.form.bagThickness = ''
      }
    },
    'form.palletMaterial': function (newval) {
      if (newval === '1') {
        this.form.forkDirection = '1'
      } else {
        // this.form.forkDirection = ''
      }
    },
    'form.lengxingId': function (newval) {
      console.log(this.form.lengxingId)
    },
    'form.needPallet': function (newval) {
      if (newval === 0) {
        this.form.forkDirection = ''
        this.form.palletMaterial = ''
      } else {
        const { logisticsInformation } = this.OrdinaryPackage
        if (logisticsInformation && logisticsInformation.destination === 1) {
          this.form.palletMaterial = '2'
        } else {
          this.form.palletMaterial = '1'
        }
      }
    },
    form: {
      deep: true,
      handler: function () {
        this.$store.commit('SetOrdinaryPackage', { ...this.OrdinaryPackage, packagingRequirements2: this.form })
        this.computedCount()
      }
    }
  },
  created () {
    // this.first = true
    this.getCustomMaterial()
  },
  async mounted () {
    await this.getBoxTypeList()
    if (this.OrdinaryPackage && this.OrdinaryPackage.packagingRequirements2) {
      this.form = Object.assign({}, this.form, this.OrdinaryPackage.packagingRequirements2)
      const palletBomInfo = this.form.palletBomInfo.filter(e => e.bomType === 2)
      palletBomInfo.map(e => {
        e.firstType = this.customMaterialList.find(item => item.materialId === e.bomMaterial).materialType
        return e
      })
      this.form.palletBomInfo = palletBomInfo
      this.$nextTick(() => {
        this.computedCount()
      })
    }
  },
  methods: {
    getCustomMaterial () {
      getCustomMaterial().then(res => {
        if (res.code === 0) {
          this.customMaterialList = res.rows || []
        }
      })
    },
    addMaterial () {
      const data = {
        bomName: '',
        bomLong: '',
        bomWidth: '',
        bomHeight: '',
        bomAmount: '',
        bomMaterial: '',
        bomType: 2,
        firstTye: ''
      }
      this.form.palletBomInfo.push(data)
    },
    removeMaterial (index) {
      this.form.palletBomInfo.splice(index, 1)
    },
    async getBoxTypeList () {
      return getBoxTypeList({ pageNum: 1, pageSize: 10, isRecommend: 0 }).then(res => {
        if (res.isSuccess) {
          this.boxTypeList = res.rows
        }
      })
    },
    validate () {
      return new Promise((resolve, reject) => {
        // if (this.form.palletBomInfo.length) {
        //   if (this.form.palletBomInfo.some(e => {
        //     return !e.bomName || !e.bomLong || !e.bomWidth || !e.bomHeight || !e.bomAmount || !e.bomMaterial
        //   })) {
        //     throw (new Error('自定义物料信息不能为空或0'))
        //   }
        // }
        this.$refs.Form.validate(valid => {
          if (valid) {
            resolve(valid)
          }
          reject(valid)
        })
      })
    },
    getProtectImg (type, pos = 'dingbu') {
      if (!type) {
        return ''
      }
      // 不保护 - 没图片
      if (type + '' === '0') {
        return ''
      }
      return `static/huanchong/${pos}_${type}.png`
    },
    preivewImg (type, pos = 'dingbu') {
      this.$refs[`Form${pos}${type}`][0].clickHandler()
    },
    handleSelect (item) {
      if (!this.boxTypeList.find(e => e.id === item.id)) {
        this.boxTypeList.push(item)
      }
      this.form.cartonType = item.id
    },
    handleChange (key, value) {
      // 顶部
      if (key === 'dingbu') {
        if (value === '0') { // 不保护
          this.form[key + 'Material'] = ''
          this.form[key + 'Amount'] = ''
          this.form[key + 'Thickness'] = ''
        }
        if (value === '1') { // 全保护
          this.form[key + 'Material'] = '1'
          this.form[key + 'Amount'] = 1
          this.form[key + 'Thickness'] = 40
        }
        if (value === '2') { // 角落
          this.form[key + 'Material'] = '1'
          this.form[key + 'Amount'] = 4
          this.form[key + 'Thickness'] = 40
        }
        if (value === '3') { // 顶边
          this.form[key + 'Material'] = '1'
          this.form[key + 'Amount'] = 2
          this.form[key + 'Thickness'] = 40
        }
      }
      // 四边
      if (key === 'sibian') {
        if (value === '0') { // 不保护
          this.form[key + 'Material'] = ''
          this.form[key + 'Amount'] = ''
          this.form[key + 'Thickness'] = ''
        }
        if (value === '1') { // 四楞
          this.form[key + 'Material'] = '1'
          this.form[key + 'Amount'] = 4
          this.form[key + 'Thickness'] = 40
        }
      }
      // 底部
      if (key === 'dibu') {
        if (value === '0') { // 不保护
          this.form[key + 'Material'] = ''
          this.form[key + 'Amount'] = ''
          this.form[key + 'Thickness'] = ''
        }
        if (value === '1') { // 全保护
          this.form[key + 'Material'] = '1'
          this.form[key + 'Amount'] = 1
          this.form[key + 'Thickness'] = 40
        }
        if (value === '2') { // 角落
          this.form[key + 'Material'] = '1'
          this.form[key + 'Amount'] = 4
          this.form[key + 'Thickness'] = 40
        }
        if (value === '3') { // 底边
          this.form[key + 'Material'] = '1'
          this.form[key + 'Amount'] = 2
          this.form[key + 'Thickness'] = 40
        }
      }
      // 局部
      if (key === 'jubu') {
        if (value === '0') { // 不保护
          this.form[key + 'Material'] = ''
          this.form[key + 'Amount'] = ''
          this.form[key + 'Thickness'] = ''
        }
        if (value === '1') { // 面保护
          this.form[key + 'Material'] = '1'
          this.form[key + 'Amount'] = ''
          this.form[key + 'Thickness'] = 40
        }
        if (value === '2') { // 突出
          this.form[key + 'Material'] = '1'
          this.form[key + 'Amount'] = ''
          this.form[key + 'Thickness'] = ''
        }
      }
    },
    getMaterialImage (id) {
      if (!id) return ''
      const obj = this.customMaterialList.find(e => e.materialId === id)
      return obj && obj.materialImage
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-checkbox-wrapper {
    margin-right: 40px;
}
/deep/ .ivu-radio-wrapper {
    margin-right: 40px;
}

.ivu-divider.primary {
    color: #67c5ec;
    font-size: 14px;
    &:after,
    &:before {
        border-top: 1px solid #67c5ec;
    }
}
</style>
