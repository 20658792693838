<template>
  <div>
    <Divider class="primary" style="margin: 4px auto 20px;">以下信息为参数</Divider>
    <SchemeTable></SchemeTable>
    <Collapse v-model="collapseValue">
      <Panel name="1">
        纸箱
        <div slot="content">
          <div style="text-align:center">
            <e-image fit="contain" style="max-width: 100%; margin: 25px auto" v-if="picpath" :src="picpath"
                     :preview-src-list="[picpath]" />
          </div>
          <Row>
            <Col :span="20">
            <table class="params-table">
              <tr v-for="item in zhixiangDataTop" :key="item.key">
                <td class="label">{{item.key}}：</td>
                <td>{{item.value}}</td>
              </tr>
              <tr>
                <td class="label">配纸方案：</td>
                <td>
                  {{get(zhixiangData,'[0].pinpai')}}
                  <span style="margin:0 10px;">
                    {{zhixiangData.filter(e => isNumber(e.kezhong) && e.peizhi!=='合计').map(e => e.kezhong).join('/')}}
                  </span>
                  <span style="margin:0 10px;">{{peizhilengxing}}</span>
                  <span style="margin:0 10px;">边压{{get(packageInfo, 'packageinfo.packageMaterialPlan.px', '')}}N/m</span>
                  <span style="margin:0 10px;">纸箱抗压{{get(packageInfo, 'resistance', '')}}Kg</span>
                </td>
              </tr>
            </table>
            <!-- <Table class="no-border" :data="zhixiangDataTop" :columns="zhixiangDataTopColumns" style="width: 100%"
                   :show-header="false">
            </Table> -->
            </Col>
            <Col :span="4">
            <Button v-if="cartonType === '10'" ghost type="primary" style="margin-top: 10px;"
                    @click="viewCad">查看纸箱CAD图纸</Button>
            <Button ghost type="primary" style="margin-top: 10px;" @click="showPeizhi=true">查看配纸详细列表</Button>
            </Col>
          </Row>
          <Modal v-model="showPeizhi" title="配纸详细列表" width="740px">
            楞型：{{peizhilengxing}}
            <Table border :data="zhixiangData" :columns="zhixiangDataColumns" style="width: 100%;margin-top:10px"></Table>
            <div slot="footer">
              <Button ghost type="primary" @click="showPeizhi=false">关闭</Button>
            </div>
          </Modal>
        </div>
      </Panel>
    </Collapse>
    <Collapse value="2" class="mt20" v-if="get(packageInfo, 'packageinfo.auxiliary.shortDaokaAmount')">
      <Panel name="2">
        短刀卡
        <div slot="content">
          <DuanDaokaDataLight></DuanDaokaDataLight>
        </div>
      </Panel>
    </Collapse>
    <Collapse value="3" class="mt20" v-if="get(packageInfo, 'packageinfo.auxiliary.longDaokaAmount')">
      <Panel name="3">
        长刀卡
        <div slot="content">
          <ChangDaokaDataLight></ChangDaokaDataLight>
        </div>
      </Panel>
    </Collapse>
    <Collapse value="4" class="mt20" v-if="get(packageInfo, 'packageinfo.auxiliary.pingkaAmount')">
      <Panel name="4">
        平卡
        <div slot="content">
          <PingkaDataLight></PingkaDataLight>
        </div>
      </Panel>
    </Collapse>
    <Collapse value="5" class="mt20" v-if="get(packageInfo, 'packageinfo.auxiliary.dingbuProtectionMethod')">
      <Panel name="5">
        顶部缓冲
        <div slot="content">
          <HuanchongIdLight pos="dingbu"></HuanchongIdLight>
        </div>
      </Panel>
    </Collapse>
    <Collapse value="6" class="mt20" v-if="get(packageInfo, 'packageinfo.auxiliary.sibianProtectionMethod')">
      <Panel name="6">
        高度四边
        <div slot="content">
          <HuanchongIdLight pos="sibian"></HuanchongIdLight>
        </div>
      </Panel>
    </Collapse>
    <Collapse value="7" class="mt20" v-if="get(packageInfo, 'packageinfo.auxiliary.dibuProtectionMethod')">
      <Panel name="7">
        底部缓冲
        <div slot="content">
          <HuanchongIdLight pos="dibu"></HuanchongIdLight>
        </div>
      </Panel>
    </Collapse>
    <Collapse value="8" class="mt20" v-if="get(packageInfo, 'packageinfo.auxiliary.jubuProtectionMethod')">
      <Panel name="8">
        局部缓冲
        <div slot="content">
          <HuanchongIdLight pos="jubu"></HuanchongIdLight>
        </div>
      </Panel>
    </Collapse>
    <Collapse value="9" class="mt20" v-if="get(packageInfo, 'packageinfo.auxiliary.bagType')">
      <Panel name="9">
        内袋/包装袋
        <div slot="content">
          <NeidaiDataLight></NeidaiDataLight>
        </div>
      </Panel>
    </Collapse>
    <Collapse value="10" class="mt20" v-if="get(packageInfo, 'packageinfo.needPallet')">
      <Panel name="10">
        栈板
        <div slot="content">
          <ZhanbanDataLight></ZhanbanDataLight>
        </div>
      </Panel>
    </Collapse>
    <PalletBom></PalletBom>

    <Collapse value="11" class="mt20">
      <Panel name="11">
        报价表
        <div slot="content">
          <PriceListLight></PriceListLight>
        </div>
      </Panel>
    </Collapse>
    <Collapse value="1" class="mt20">
      <Panel name="1">
        3D 装箱展示
        <div slot="content">
          <packing-view type="zhanban" v-bind="guiziViewProps" style="width:100%;" />
        </div>
      </Panel>
    </Collapse>
    <ViewCad ref="ViewCad"></ViewCad>
  </div>
</template>

<script>

import PackingView from './components/PackingView'
import DuanDaokaDataLight from './components/DuanDaokaDataLight'
import ChangDaokaDataLight from './components/ChangDaokaDataLight'
import PingkaDataLight from './components/PingkaDataLight'
import HuanchongIdLight from './components/HuanchongIdLight'
import NeidaiDataLight from './components/NeidaiDataLight'
import ZhanbanDataLight from './components/ZhanbanDataLight'
import PriceListLight from './components/PriceListLight'
import SchemeTable from './components/SchemeTable'
import ViewCad from './components/ViewCad'
import PalletBom from './components/PalletBom'
import { mapState } from 'vuex'
import { isNumber } from 'loadsh'
export default {
  provide () {
    return {
      packageInfo: this.packageInfo,
      zhixiangData: this.zhixiangData,
      attributeMap: this.attributeMap
    }
  },
  components: {
    PackingView,
    DuanDaokaDataLight,
    ChangDaokaDataLight,
    PingkaDataLight,
    HuanchongIdLight,
    NeidaiDataLight,
    ZhanbanDataLight,
    PriceListLight,
    SchemeTable,
    ViewCad,
    PalletBom
  },
  data () {
    return {
      collapseValue: '1',
      showPeizhi: false,
      isNumber,
      guiziViewProps: {
        itemLong: 0,
        itemWidth: 0,
        itemHeight: 0,
        containerLong: 0,
        containerWidth: 0,
        containerHeight: 0,
        layer: undefined,
        packingAmout: 0
      },
      picpath: '',
      peizhilengxing: '',
      zhixiangData: [
        {
          peizhi: '面纸',
          pinpai: '',
          kezhong: '',
          huanya: ''
        },
        {
          peizhi: '芯纸',
          pinpai: '',
          kezhong: '',
          huanya: ''
        }
        // {
        //   peizhi: '中纸1',
        //   pinpai: '',
        //   kezhong: '',
        //   huanya: '',
        // },
        // {
        //   peizhi: '芯纸',
        //   pinpai: '',
        //   kezhong: '',
        //   huanya: '',
        // },
        // {
        //   peizhi: '里纸',
        //   pinpai: '',
        //   kezhong: '',
        //   huanya: '',
        // },
      ],
      zhixiangDataColumns: [
        {
          title: ' ',
          key: 'peizhi',
          align: 'center'
        },
        {
          title: '品牌',
          key: 'pinpai',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.pinpai || '---')
          }
        },
        {
          title: '克重',
          key: 'kezhong',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.kezhong || '---')
          }
        },
        {
          title: '边压',
          key: 'huanya',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.huanya || '---')
          }
        }
      ],
      zhixiangDataTop: [
        {
          key: '纸箱箱型',
          value: ''
        },
        {
          key: '纸箱制造尺寸(mm)',
          value: ''
        }
        // {
        //   key: '配纸方案',
        //   value: ''
        // }
        // {
        //   key: '',
        //   value: ''
        // }
        // {
        //   key: '纸箱材料',
        //   value: '',
        // },
        // {
        //   key: '货柜尺寸',
        //   value: '',
        // },
      ],
      // zhixiangDataTopColumns: [
      //   {
      //     title: '',
      //     key: 'key',
      //     width: 200,
      //     render: (h, params) => {
      //       return h('span', params.row.key + '：')
      //     }
      //   },
      //   {
      //     title: '品牌',
      //     key: 'value',
      //     render: (h, params) => {
      //       if (typeof params.row.value === 'number') {
      //         return h('span', params.row.value)
      //       }
      //       return h('span', params.row.value || '---')
      //     }
      //   }
      // ],
      shouldShowZhixiangData3: false,
      shouldShowNeidai: true
    }
  },
  computed: {
    ...mapState({
      attributeMap: state => state.PackageAttribute,
      packageInfo: state => state.packageInfo
    }),
    cartonType () {
      const { cartonTypeValue } = this.packageInfo
      return cartonTypeValue
    }
  },
  watch: {
    packageInfo: {
      deep: true,
      handler: function () {
        this.showThisCard(this.packageInfo)
        this.assignGuiziViewProps(this.packageInfo)
      }
    }
  },
  async created () {
    this.showThisCard(this.packageInfo)
    this.assignGuiziViewProps(this.packageInfo)
  },
  methods: {
    showThisCard (info) {
      this.cartonSize = info.cartonSize
      this.cartonMaterial = info.cartonMaterial // new
      this.containerSize = info.containerSize // new
      this.productSize = info.productSize // new

      this.cartonType = info.cartonTypeValue
      this.productId = info.packageinfoId
      this.productName = info.productName
      this.productAmount = info.orderAmout
      this.productValue = info.productValue
      this.productType = info.productType
      this.productTime = info.createTime
      this.productWeight = info.productWeight + 'kg'
      // alert(pictype)..
      // alert()
      // alert(pic1)
      // switch(pictype){
      //   case 1:picpath=pic1;break;
      //   case 2:
      //   picpath=pic1;
      //   alert(pic1);
      //    break;
      // }

      this.shouldShowZhixiangData3 = info.boxType.boxCode.includes('0310')
      // const tmpList = ['0300', '0301', '0302', '0303', '0304', '0306', '0308', '0309', '0312', '0313', '0320', '0321', '0325', '0404', '0405', '0510', '0512', '0601', '0602', '0605', '0607', '0608', '0610']
      // for (let i = 0; i < tmpList.length; i++) {
      //   if (tmpList[i] === info.boxType.boxCode.toString()) { this.shouldShowZhixiangData3 = true }
      // }

      this.picpath = info.boxType.boxImageurl
      // this.downloadUrl =
      //   "https://obs-easypacking.obs.cn-east-2.myhuaweicloud.com:443/cps%2Fimage%2Fpackages%2F85820200115083557.zip";
      this.detail_show = true
      this.cards_show = false

      // this.productName=info.productName;

      // v2
      console.log('card info', info)

      // v2.1
      this.currentPackageInfo = info
      this.packageType = info.packageType

      if (this.packageType !== '重型包装') {
        // this.assignDaokaDataLight(info)
        // this.assignHuanChongDataLight(info)
        // this.assignNeiDaiDataLight(info)
        // this.assignZhanbanDataLight(info)
        // this.assignPriceListLight(info)

        this.assignGuiziViewProps(info)
        // this.assignZhanbanViewPropsLight(info)
        this.assignZhixiangData(info)
      } else {
        // this.assignDaokaData(info)
        // this.assignHuanChongDataLight(info)
        // this.assignNeiDaiDataLight(info)
        // this.assignZhanbanData(info)

        // this.assignGuiziViewProps(info)
        // this.assignZhanbanViewProps(info)
        // this.assignZhixiangData2(info)
        // this.assignPriceListLight(info)
      }

      this.assignZhixiangDataTop(info)
    },
    assignGuiziViewProps (info) {
      const pkgInfo = info.packageinfo || {}
      const palletInfo = pkgInfo.pallet || {}
      const cartonSizeSummary = pkgInfo.cartonSizeSummary || {}
      this.guiziViewProps = {
        itemLong: cartonSizeSummary.outerLong,
        itemWidth: cartonSizeSummary.outerWidth,
        itemHeight: cartonSizeSummary.outerHeight,
        containerLong: pkgInfo.containerLong,
        containerWidth: pkgInfo.containerWidth,
        containerHeight: pkgInfo.containerHeight,
        layer: pkgInfo.stackNumHeight,
        packingAmout: pkgInfo.packingAmout
      }
      console.log('pkginfo', palletInfo)
      console.log('pkginfo', this.guiziViewProps)
    },
    assignZhixiangDataTop (info) {
      // 纸箱箱型
      this.zhixiangDataTop[0].value = info.boxType.boxName
      // 纸箱制造尺寸
      this.zhixiangDataTop[1].value = info.cartonSize
      // this.zhixiangDataTop[2].key = '制造尺寸'
      // this.zhixiangDataTop[2].value = info.productSize
      // this.zhixiangDataTop[3].key = '外尺寸'
      // this.zhixiangDataTop[3].value = `${this.guiziViewProps.itemLong}*${this.guiziViewProps.itemWidth}*${this.guiziViewProps.itemHeight}`
      const tmpList = ['0404', '0405', '0510', '0512']
      for (let i = 0; i < tmpList.length; i++) {
        if (tmpList[i] === info.boxType.boxCode.toString()) { this.zhixiangDataTop[1].key = '纸箱制造尺寸(mm)' }
      }
      // 纸箱材料
      // this.zhixiangDataTop[2].value = info.cartonMaterial;
      // 货柜尺寸
      // this.zhixiangDataTop[2].value = info.containerSize
    },
    assignZhixiangData (info) {
      let { zhongzhi1, zhongzhi2, mianzhi, lizhi, xinzhi } =
        info.packMaterialPlan || {}
      zhongzhi1 = zhongzhi1 || {}
      zhongzhi2 = zhongzhi2 || {}
      mianzhi = mianzhi || {}
      lizhi = lizhi || {}
      xinzhi = xinzhi || {}
      // 面纸
      this.zhixiangData[0].pinpai = mianzhi.brand
      this.zhixiangData[0].kezhong = mianzhi.quality
      this.zhixiangData[0].huanya = mianzhi.pressureIntensity
      // 芯纸
      this.zhixiangData[1].pinpai = xinzhi.brand
      this.zhixiangData[1].kezhong = xinzhi.quality
      this.zhixiangData[1].huanya = xinzhi.pressureIntensity
      // // 中纸1
      // this.zhixiangData[2].pinpai = zhongzhi1.brand;
      // this.zhixiangData[2].kezhong = zhongzhi1.quality;
      // this.zhixiangData[2].huanya = zhongzhi1.pressureIntensity;
      // // 中纸2
      // this.zhixiangData[3].pinpai = zhongzhi2.brand;
      // this.zhixiangData[3].kezhong = zhongzhi2.quality;
      // this.zhixiangData[3].huanya = zhongzhi2.pressureIntensity;
      // // 里纸
      // this.zhixiangData[4].pinpai = lizhi.brand;
      // this.zhixiangData[4].kezhong = lizhi.quality;
      // this.zhixiangData[4].huanya = lizhi.pressureIntensity;

      // 获取楞型
      const lengxingSet = this.attributeMap.lengxing
      const lengxingId = info.packageinfo.lengxingId
      const lengxing = lengxingSet.find(
        function (x) { return x.attributeValue === lengxingId + '' })
        .attributeLabel

      this.peizhilengxing = lengxing

      // 根据楞型字符串长度排列配纸方案
      const lengxinglength = this.getLengxingLength(info)
      let totalKezhong = 0
      // const totalHuanya = 0
      if (lengxinglength === 1) {
        this.zhixiangData.length = 4
        this.zhixiangData[2] = {
          peizhi: '里纸',
          pinpai: lizhi.brand,
          kezhong: lizhi.quality,
          huanya: lizhi.pressureIntensity
        }
        totalKezhong = 0
        // totalHuanya = 0
        for (let i = 0; i < this.zhixiangData.length - 1; i++) {
          totalKezhong += this.zhixiangData[i].kezhong
        };
        // for (let i = 0; i < this.zhixiangData.length - 1; i++) {
        //   totalHuanya += this.zhixiangData[i].huanya
        // }
        this.zhixiangData[3] = {
          peizhi: '合计',
          kezhong: totalKezhong,
          huanya: this.$get(info, 'packageinfo.packageMaterialPlan.px', '') || '' + 'N/m'
        }

        // this.zhixiangData[4] = {
        //   peizhi: ' ',
        //   pinpai: ' ',
        //   kezhong: ' ',
        //   huanya: ' '
        // }
        // this.zhixiangData[5] = {
        //   peizhi: '楞型',
        //   pinpai: ' ',
        //   kezhong: lengxing,
        //   huanya: ' '
        // }
      } else if (lengxinglength === 2) {
        this.zhixiangData.length = 6
        this.zhixiangData[2] = {
          peizhi: '中纸1',
          pinpai: zhongzhi1.brand,
          kezhong: zhongzhi1.quality,
          huanya: zhongzhi1.pressureIntensity
        }
        this.zhixiangData[3] = {
          peizhi: '芯纸',
          pinpai: xinzhi.brand,
          kezhong: xinzhi.quality,
          huanya: xinzhi.pressureIntensity
        }
        this.zhixiangData[4] = {
          peizhi: '里纸',
          pinpai: lizhi.brand,
          kezhong: lizhi.quality,
          huanya: lizhi.pressureIntensity
        }
        totalKezhong = 0
        // totalHuanya = 0
        for (let i = 0; i < this.zhixiangData.length - 1; i++) {
          totalKezhong += this.zhixiangData[i].kezhong
        };
        // for (let i = 0; i < this.zhixiangData.length - 1; i++) {
        //   totalHuanya += this.zhixiangData[i].huanya
        // }
        this.zhixiangData[5] = {
          peizhi: '合计',
          // kezhong: totalKezhong,
          huanya: this.$get(info, 'packageinfo.packageMaterialPlan.px', '') || '' + 'N/m'
        }
        this.zhixiangData[6] = {
          peizhi: ' ',
          pinpai: ' ',
          kezhong: ' ',
          huanya: ' '
        }
        this.zhixiangData[7] = {
          peizhi: '楞型',
          pinpai: ' ',
          kezhong: lengxing,
          huanya: ' '
        }
      } else {
        this.zhixiangData.length = 8
        this.zhixiangData[2] = {
          peizhi: '中纸1',
          pinpai: zhongzhi1.brand,
          kezhong: zhongzhi1.quality,
          huanya: zhongzhi1.pressureIntensity
        }
        this.zhixiangData[3] = {
          peizhi: '芯纸',
          pinpai: xinzhi.brand,
          kezhong: xinzhi.quality,
          huanya: xinzhi.pressureIntensity
        }
        this.zhixiangData[4] = {
          peizhi: '中纸2',
          pinpai: zhongzhi2.brand,
          kezhong: zhongzhi2.quality,
          huanya: zhongzhi2.pressureIntensity
        }
        this.zhixiangData[5] = {
          peizhi: '芯纸',
          pinpai: xinzhi.brand,
          kezhong: xinzhi.quality,
          huanya: xinzhi.pressureIntensity
        }
        this.zhixiangData[6] = {
          peizhi: '里纸',
          pinpai: lizhi.brand,
          kezhong: lizhi.quality,
          huanya: lizhi.pressureIntensity
        }
        totalKezhong = 0
        // totalHuanya = 0
        for (let i = 0; i < this.zhixiangData.length - 1; i++) {
          totalKezhong += this.zhixiangData[i].kezhong
        };
        // for (let i = 0; i < this.zhixiangData.length - 1; i++) {
        //   totalHuanya += this.zhixiangData[i].huanya
        // }
        this.zhixiangData[7] = {
          peizhi: '合计',
          // kezhong: 'totalKezhong',
          huanya: this.$get(info, 'packageinfo.packageMaterialPlan.px', '') || '' + 'N/m'
        }
        this.zhixiangData[8] = {
          peizhi: ' ',
          pinpai: ' ',
          kezhong: ' ',
          huanya: ' '
        }
        this.zhixiangData[9] = {
          peizhi: '楞型',
          pinpai: ' ',
          kezhong: lengxing,
          huanya: ' '
        }
      }

      // 不显示单层原纸环压
      for (let i = 0; i < this.zhixiangData.length - 3; i++) {
        this.zhixiangData[i].huanya = ''
      };
    },
    // 获取楞型字符串长度
    getLengxingLength (info) {
      const lengxingSet = this.attributeMap.lengxing
      const lengxingId = info.packageinfo.lengxingId
      const lengxingString = lengxingSet.find(
        function (x) { return x.attributeValue === lengxingId + '' })
        .attributeLabel
      console.log(lengxingId, lengxingString)
      const lengxingLength = lengxingString.length - 1
      return lengxingLength
    },
    viewCad () {
      this.$nextTick(() => {
        this.$refs.ViewCad.show()
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.title {
    border-left: 4px solid #67c5ec;
    padding-left: 10px;
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 10px;
}
/deep/ .title {
    border-left: 4px solid #67c5ec;
    padding-left: 10px;
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 10px;
}

.ivu-divider.primary {
    color: #67c5ec;
    font-size: 14px;
    &:after,
    &:before {
        border-top: 1px solid #67c5ec;
    }
}
</style>
