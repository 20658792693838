<template>
  <Modal v-model="modal1" title="选择工具" width="800px" @on-ok="ok" @on-cancel="cancel">
    <Row :gutter="40">
      <Col :span="6" v-for="(item, index) in toolpicNum" :key="item.name">
      <div class="text-center" style="margin: 10px auto;padding:20px;">
        <img :src="item.bg" alt="" style="max-width: 100%;">
        <Checkbox v-model="toolSelect[index]">
          <span>{{item.name}}</span>
        </Checkbox>
      </div>
      </Col>
    </Row>
  </Modal>
</template>
<script>
import pic1 from '@/assets/packinggo/toolPic1.png'
import pic2 from '@/assets/packinggo/toolPic2.png'
import pic3 from '@/assets/packinggo/toolPic3.png'
import pic4 from '@/assets/packinggo/toolPic4.png'
import pic5 from '@/assets/packinggo/toolPic5.png'
import pic6 from '@/assets/packinggo/toolPic6.png'
import pic7 from '@/assets/packinggo/toolPic7.png'
import pic8 from '@/assets/packinggo/toolPic8.png'
export default {
  inject: {
    packageInfo: {
      default: () => ({})
    }
  },
  data () {
    return {
      modal1: false,
      toolpicNum: [
        { bg: pic1, name: '打包工具' }, // this.pic1 不可}
        { bg: pic2, name: '热熔枪热熔胶' },
        { bg: pic3, name: '剪刀' },
        { bg: pic4, name: '介刀' },
        { bg: pic5, name: '起子' },
        { bg: pic6, name: '热打包工具' },
        { bg: pic7, name: '钉锤' },
        { bg: pic8, name: '自动螺丝枪' }
      ],
      toolSelect: [false, false, false, false, false, false, false, false]
    }
  },
  methods: {
    show () {
      this.modal1 = true
    },
    ok () {
      var selectedArr = []
      for (var i = 0; i < this.toolSelect.length; i++) {
        if (this.toolSelect[i] === true) {
          selectedArr.push(i + 1)
        }
      }
      // console.log(selectedArr)
      if (selectedArr.length === 0) {
        selectedArr = [0]
      }
      const params = {
        toolString: selectedArr.join(','),
        packageinfoId: this.packageInfo.packageinfoId
      }
      console.log(params)
      const url = 'dist/updateInstructionTool' // 要斜线
      this.$http.post(url, params).then((res) => { })
    },
    cancel () {
      this.toolSelect = this.toolSelect.map(e => {
        e = false
        return e
      })
      this.modal1 = false
    }
  }
}
</script>
